import { useEffect, useRef, useState } from "react";
import {
  ModalCloseBtn,
  Hearticon,
  ShareIcons,
  StreetView,
  DirectionsIcon,
} from "../../assets/svg/svgIcons.jsx";
import { navigateIcon2, detailsMakanilogo } from "../../assets/index.js";
import { useTranslation } from "react-i18next";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";
import "../../scss/common.scss";
import { SHARE_URL_BASE } from "../../helper/api/constants.ts";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";
import { useMapControl } from "../../context/MapControllContext.js";

const FavoriteLocationPopup = ({
  language,
  info,
  loading,
  handleClick,
  handleNavigate,
  handleStreetView,
  localInfo,
  setShare,
  handelMakaniSearch,
  measurementUnit,
}) => {
  const [isLocation, setIsLocation] = useState(null);
  const [t, il8n] = useTranslation();
  const popupRef = useRef(null); // Create a ref for the popup
  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    checkLocation();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClick(); // Close the popup when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClick]);

  return (
    <div className="makanicard-details-popup" ref={popupRef}>
      <div className="m-card-top">
        <button className="btn-close" onClick={handleClick}>
          <ModalCloseBtn />
        </button>
        <div className="title-md">
          {loading
            ? t("loading")
            : (language === "ar" ? info.BldgNameAr : info.BldgNameEn) ||
              localInfo?.FavName}
        </div>
        <p>
          {t("entrance")} |{" "}
          {loading
            ? t("loading")
            : language === "ar"
            ? info.EntNameA
            : info.EntNameE}
        </p>
      </div>

      <div className="button-group">
        <p className="MakaniCardContentDesinence">
          <span className="directions">
            <DirectionsIcon />
          </span>
          {loading
            ? t("loading")
            : !isLocation
            ? "--"
            : parseDistanceWithUnit(
                measurementUnit,
                info.distance?.toFixed(2)
              )}{" "}
          <span> {measurementUnit == "kilometers" ? t("km") : t("miles")}</span>
        </p>

        <button
          className="md-btn"
          onClick={() => {
            setShare({
              value: `${SHARE_URL_BASE}?l=${
                il8n.language === "ar" ? "A" : "E"
              }&m=${localInfo.FavValue.replace(/\s+/g, "")}`,
              isShare: true,
            });
          }}
        >
          <ShareIcons />
        </button>
        <button className="md-btn">
          <Hearticon />
        </button>
        <button className="md-btn" onClick={() => handleStreetView(info)}>
          <StreetView />
        </button>
        <button
          className="md-btn logo-btn"
          onClick={() => {
            handelMakaniSearch(info);
          }}
        >
          <img
            src={detailsMakanilogo}
            alt="makani-logo"
            className="logo-in-card"
          />
        </button>

        <button
          className="md-btn navg-btn"
          onClick={() => handleNavigate(info, localInfo?.FavName)}
        >
          <img src={navigateIcon2} alt="navigate" />
          {t("navigate")}
        </button>
      </div>
      <div>
        <p className="makaniCardDetailsTitle">{t("details")}</p>
        <div className="makaniCardDetailsAddress">
          <div className="makaniCardDetailsSubAddressWrapper">
            <p className="makaniCardDetailsAddressTitle">{t("address")}</p>
            <p className="makaniCardDetailsSubAddress">
              {t("street")}: {loading ? t("loading") : info.street}
            </p>
            <p className="makaniCardDetailsSubAddress">
              {t("community")}:{" "}
              {loading
                ? t("loading")
                : language === "ar"
                ? info.CommAr
                : info.CommEn}{" "}
            </p>
            <p className="makaniCardDetailsSubAddress">
              {t("emirate")}:{" "}
              {loading
                ? t("loading")
                : language === "ar"
                ? info.EmirateA
                : info.EmirateE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteLocationPopup;

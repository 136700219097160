import { useTranslation } from "react-i18next";
import {
  copy,
  detailsMakanilogo,
  navigateIcon2,
  logo,
  checked,
} from "../../assets/index";
import {
  LoveIcon,
  StreetView,
  Hearticon,
  MakaniCardIcon,
  DownLoadIcon,
  DownloadCardIcon,
  ShareCardIcon,
} from "../../assets/svg/svgIcons";

// import { SHARE_URL_BASE } from "../../helper/api/constants";
import AddFavoritesMapModel from "../models/addFavoritesMapModel/index";
import { DirectionsIcon } from "../../assets/svg/svgIcons";
import "../MakaniCard/style.css";
import useClipboard from "react-use-clipboard";
import isLocationEnabled from "../../helper/utils/userLocationStatus";
import { useState, useEffect } from "react";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance";

const MakaniCardViewOnMap = ({
  item,
  style,
  theme,
  setStreetLevel,
  navigate,
  setStartDirection,
  onFavoriteClick,
  favoriteToggle,
  index,
  onFavoriteCancelClick,
  handleAddToFavorite,
  addToFavrite,
  handleDownload,
  fromHome,
  setShare,
  measurementUnit,
}) => {
  const [t, il8n] = useTranslation();
  const [isCopied, setCopied] = useClipboard(item.Makani, {
    successDuration: 1000,
  });
  const [isLocation, setIsLocation] = useState(null);

  const SHARE_URL_BASE = `${window.location.origin}/map`;

  // NOTE: Distance on user location
  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };
    checkLocation();
  }, []);

  const handleCopy = () => {
    setCopied();
  };

  function reverseAndConcat(input) {
    return input.replace(/ /g, "\u00A0");
  }

  return (
    <div
      style={{
        // cursor: 'pointer',
        left: 0,
        ...style,
        width: "362.52px",
      }}
      className={`deatilsMakaniCard deatilsMakaniCardActive singleMakaniCard`}
    >
      {favoriteToggle.isFavorite && favoriteToggle.index === index ? (
        <AddFavoritesMapModel
          fromMap={true}
          item={item}
          onFavoriteCancelClick={onFavoriteCancelClick}
          handleAddToFavorite={handleAddToFavorite}
          index={index}
        />
      ) : (
        <>
          <div className="MakaniCardHeader">
            <div>
              <img
                src={theme === "dark" ? logo : detailsMakanilogo}
                alt="makani-logo"
                className="logo-in-card"
              />
            </div>
            <div className="copyWrapper" onClick={handleCopy}>
              <p
                className="makaniCardNumber"
                style={{
                  marginBottom: "0",
                }}
              >
                {il8n.language === "ar"
                  ? reverseAndConcat(item.Makani)
                  : item.Makani}
              </p>
              {isCopied ? (
                <span className="coped-align">
                  <span class="copied">{t("copied")}</span>{" "}
                  <img
                    className="copy__btn copiedbtn"
                    src={checked}
                    alt="copy"
                  />
                </span>
              ) : (
                <img
                  width="14"
                  height="14"
                  className="copy__btn"
                  src={copy}
                  alt="copy"
                />
              )}
            </div>
            <div
              className="d-flex-cstm"
              onClick={() => {
                if (!item.isFavorite) {
                  onFavoriteClick();
                }
              }}
            >
              {item.isFavorite ||
              (addToFavrite.index === index && addToFavrite.add) ? (
                <Hearticon alt="love" className="love" />
              ) : (
                <LoveIcon alt="love" className="love" />
              )}
            </div>
          </div>
          <div className="MakaniCardContent">
            <div
              className="MakaniCardContentText"
              style={{
                padding: il8n.language === "ar" && "5px 15px",
              }}
            >
              <p>
                {(il8n.language === "ar"
                  ? item.BuildingNameA
                  : item.BuildingNameE) || item.FavName}
              </p>
              <p className="MakaniCardContentDesinence">
                <span className="directions">
                  <DirectionsIcon />
                </span>
                {/* NOTE: Distance on user location*/}
                {!isLocation
                  ? "--"
                  : parseDistanceWithUnit(measurementUnit, item.distance)}{" "}
                <span>
                  {measurementUnit == "kilometers" ? t("km") : t("miles")}
                </span>
              </p>
            </div>
            <div className="DetailsMakaniCardBtns">
              {fromHome === true ? (
                <div
                  className="DetailsMakaniCardBtn"
                  onClick={() => {
                    setShare({
                      value: `${SHARE_URL_BASE}?l=${
                        il8n.language === "ar" ? "A" : "E"
                      }&m=${item.Makani.replace(/\s+/g, "")}`,
                      isShare: true,
                    });
                  }}
                >
                  <ShareCardIcon />
                </div>
              ) : (
                <div
                  className="DetailsMakaniCardBtn"
                  onClick={() => {
                    handleDownload({ makaniId: item.Makani, value: true });
                  }}
                >
                  <DownloadCardIcon />
                </div>
              )}

              <div
                className="DetailsMakaniCardBtn"
                onClick={() => {
                  setStreetLevel({
                    lat: Number(item.SHAPE.split(",")[0]),
                    lng: Number(item.SHAPE.split(",")[1]),
                  });
                }}
              >
                <StreetView />
              </div>

              <div className="DetailsMakaniCardBtn disabled-btn">
                <MakaniCardIcon />
              </div>

              <div
                className="navigateWarper"
                onClick={() => {
                  setStartDirection(true);
                  navigate("/direction", {
                    state: {
                      BuildingNameE: item.BuildingNameE || item.FavName,
                      BuildingNameA: item.BuildingNameA || item.FavName,
                      position: {
                        lat: Number(item.SHAPE.split(",")[0]),
                        lng: Number(item.SHAPE.split(",")[1]),
                      },
                    },
                  });
                }}
              >
                <img src={navigateIcon2} alt="navigate" />
                <p className="navigateTitle">{t("navigate")}</p>
              </div>
            </div>
          </div>
          <div></div>
        </>
      )}
    </div>
  );
};
export default MakaniCardViewOnMap;

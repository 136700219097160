import React, { createContext, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { updateTranslations } from "../indexedDB";
import { useAuth } from "./auth.js";
import { useTour } from "./tourContext.js";
import {
  GetInitialParams,
  fetchTranslationsFromServer,
} from "../helper/api/requests.ts";

const LanguageContext = createContext();
export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const { token, setInitialToken } = useAuth();
  const { startTour, stopTour, tourSkipped, setTourSkipped, run } = useTour();

  const loadSetting = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? defaultValue : defaultValue;
  };
  const { t, i18n } = useTranslation();
  const [localizationDate, setLocalizationDate] = useState(
    loadSetting("localizationDate", "")
  );

  const [langToggle, setLangToggle] = useState(() => {
    return localStorage.getItem("langToggle") || "ltr";
  });

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("language") || "en";
  });

  const switchLanguage = async (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLangChange = (direction, lang) => {
    if (run) {
      stopTour();
      setLangToggle(direction);
      setLanguage(lang);
      switchLanguage(lang);
      setTimeout(() => {
        startTour();
      }, 50);
    } else {
      setLangToggle(direction);
      setLanguage(lang);
      switchLanguage(lang);
    }
  };

  useEffect(() => {
    document.documentElement.dir = langToggle;
    localStorage.setItem("langToggle", langToggle);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  }, [langToggle, language, i18n]);

  const updateTranslationsToDB = async (lng) => {
    const translations = await fetchTranslationsFromServer(lng);
    await updateTranslations(translations, lng);
  };

  useEffect(() => {
    const getData = async (token) => {
      try {
        let localizationDateFromApi = await GetInitialParams(t, token);

        if (
          !(localizationDateFromApi.LocalizationUpdateTime === localizationDate)
        ) {
          localStorage.setItem(
            "localizationDate",
            JSON.stringify(localizationDateFromApi)
          );
          setLocalizationDate(localizationDateFromApi);
          updateTranslationsToDB(language);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (token) getData(token);
  }, [token, setInitialToken, language]);

  return (
    <LanguageContext.Provider value={{ handleLangChange, language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

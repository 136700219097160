import {
  GET_BUILDING_INFO,
  SMART_SEARCH,
  SMART_SEARCH_RESULT,
  GET_ALL_COMMUNITIES,
  GET_STREETS_FOR_COMMUNITY,
  GET_BUILDINGS_FOR_STREET,
  GET_BUILDING_ADDRESS,
  GET_ALL_POI_CATEGORIES,
  GET_ALL_POIS_OF_CATEGORIES,
  GET_FAVORITES,
  GET_PARCEL_OUTLINE,
  Get_BUILDING_OUTLINE_ENTRANCE_POINTS,
  Get_VIRTUAL_PLATE,
  SYNC_FAVORITES,
  GET_NEAREST_MAKANI,
  GET_ZONING_REGULATION,
  NOTIFY_UAEPASS_USER_LOGIN,
  SHARE_LOCATION,
  REPORT_MAKANI,
  GET_LOCATION,
  GET_FLOORS,
  GET_NODE_LIST,
  GET_FLOORS_PLANS,
  GET_NODES_BY_NAME,
  GET_ROUTE_BY_TYPE,
  GET_TRAVEL_MODES,
  GET_INITIAL_PARAMS,
  GET_TOKEN,
  LANGUAGE_FILE_URL,
  SEARCH_GOOGLE_URL,
} from "./constants.ts";

import { get, post, del, put } from "./methods.ts";

import { BuildingInfoI } from "../../interface/buildingInfo.ts";
import { handleError } from "../errorhelper.js";
import { useTranslation } from "react-i18next";

// const getBrowserInfo = () => {
//   const browserInfo = {
//     appName: window.navigator.appName,
//     appVersion: window.navigator.appVersion,
//     userAgent: window.navigator.userAgent,
//     platform: window.navigator.platform,
//     language: window.navigator.language,
//     online: window.navigator.onLine,
//   };

//   // const infoString = `App Name:${browserInfo.appName};App Version:${
//   //   browserInfo.appVersion
//   // };User Agent:${browserInfo.userAgent};Platform:${
//   //   browserInfo.platform
//   // };Language:${browserInfo.language};Online:${
//   //   browserInfo.online ? "Yes" : "No"
//   // };`;

//   const infoString = `App Version:${browserInfo.userAgent};`;
//   const cleanedUserAgent = infoString.replace(
//     /AppleWebKit\/[^\s]+ \(KHTML, like Gecko\)/g,
//     ""
//   );
//   const cleanedString = cleanedUserAgent.replace(/[^a-zA-Z0-9.-]/g, "");
//   const rmMozillaCleanedString = cleanedString.replace(/^(.{10})(.{10})/, "$1"); // remove second 10 characters (Mozilla5.0)
//   const finalCleanedString = rmMozillaCleanedString.replace(/.{12}$/, ""); // remove safari appearing at the end of the string

//   return finalCleanedString;
// };

const getBrowserInfo = () => {
  const userAgent = window.navigator.userAgent;

  let deviceName = "UnknownDevice";
  let os = "UnknownOS";
  let osVersion = "UnknownVersion";
  let browser = "UnknownBrowser";
  let browserVersion = "UnknownBrowserVersion";

  // OS detection patterns with dynamic version matching
  const osPatterns = [
    {
      regex: /Linux.*Ubuntu\/(\d+\.\d+)/,
      name: "Ubuntu",
      versionRegex: /Ubuntu\/(\d+\.\d+)/,
    },
    {
      regex: /Linux.*Fedora\/(\d+\.\d+)/,
      name: "Fedora",
      versionRegex: /Fedora\/(\d+\.\d+)/,
    },
    {
      regex: /Linux/,
      name: "Linux",
      versionRegex: null, // Generic Linux case with no version
    },
    {
      regex: /Android (\d+\.?\d*)/,
      name: "Android",
      versionRegex: /Android (\d+\.?\d*)/,
    },
    {
      regex: /Windows NT (\d+\.\d+)/,
      name: "Windows",
      versionRegex: /Windows NT (\d+\.\d+)/,
    },
    {
      regex: /Mac OS X (\d+_\d+)/,
      name: "MacOS",
      versionRegex: /Mac OS X (\d+_\d+)/,
    },
    {
      regex: /iPhone OS (\d+_\d+)/,
      name: "iOS",
      versionRegex: /iPhone OS (\d+_\d+)/,
    },
  ];

  // Browser detection patterns
  const browserPatterns = [
    {
      regex: /Chrome\/(\d+\.\d+)/,
      name: "Chrome",
      versionRegex: /Chrome\/(\d+\.\d+)/,
    },
    {
      regex: /Firefox\/(\d+\.\d+)/,
      name: "Firefox",
      versionRegex: /Firefox\/(\d+\.\d+)/,
    },
    {
      regex: /Safari\/(\d+\.\d+)/,
      name: "Safari",
      versionRegex: /Version\/(\d+\.\d+)/,
      exclude: /Chrome/,
    },
    {
      regex: /MSIE (\d+\.\d+);/,
      name: "Internet Explorer",
      versionRegex: /MSIE (\d+\.\d+);/,
    },
    { regex: /Edg\/(\d+\.\d+)/, name: "Edge", versionRegex: /Edg\/(\d+\.\d+)/ },
    {
      regex: /OPR\/(\d+\.\d+)/,
      name: "Opera",
      versionRegex: /OPR\/(\d+\.\d+)/,
    },
  ];

  // Detect OS and version first to prioritize Android
  osPatterns.forEach((pattern) => {
    const match = userAgent.match(pattern.regex);
    if (match) {
      os = pattern.name;
      osVersion = pattern.versionRegex
        ? match[1].replace("_", ".")
        : "UnknownVersion";

      // If Android is detected, set device name to "Mobile"
      if (os === "Android") {
        deviceName = "Mobile";
        return; // Exit after the first match
      } else if (os === "iOS") {
        deviceName = "Mobile"; // iOS devices also considered mobile
      }
    }
  });

  // Detect device type if not already determined by OS detection
  if (deviceName === "UnknownDevice") {
    if (/iPad/i.test(userAgent)) {
      deviceName = "Tablet";
    } else if (/Macintosh|Windows|Linux/i.test(userAgent)) {
      deviceName = "PC";
    }
  }

  // Detect browser and version
  browserPatterns.forEach((pattern) => {
    if (pattern.exclude && pattern.exclude.test(userAgent)) return;
    const match = userAgent.match(pattern.regex);
    if (match) {
      browser = pattern.name;
      browserVersion = match[1]; // Extracts version dynamically
    }
  });

  return `${deviceName}-${os}-${osVersion}-${browser}-${browserVersion}`;
};

const GetBuildingInfo = async (token, makaninumber): Promise<BuildingInfoI> => {
  try {
    const response = await post(GET_BUILDING_INFO, {
      Makaninumber: makaninumber,
      Token: token,
      Remarks: getBrowserInfo(),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const GetSmartSearch = async (value, token, currentLoction, language) => {
  try {
    let response = await post(SMART_SEARCH, {
      InputJson: {
        searchtext: value,
        lang: language === "en" ? "E" : "A",

        currentlocation: currentLoction,
        distancetype: "KM",
        level: "1",
        userid: "",
        sessionid: "",
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });

    let data = await response.json();

    if (!data.IsExp) {
      return {
        data: data.LEVEL1.AUTOFILL,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (error) {
    throw error;
  }
};

const GetSmartSearchResult = async (featureclassId, dgis, token) => {
  try {
    let response = await post(SMART_SEARCH_RESULT, {
      InputJson: {
        featureclass_id: featureclassId,
        dgis_id: dgis,
        userid: "",
        sessionid: "",
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        makani: data.MAKANI || [],
        buildings: data.BUILDING || data.BUILDINGS || [],
        poi: data.POI || [],
        parcel: data.PARCEL || [],
        community: data.COMMUNITY || [],
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetAllCommunities = async (token) => {
  try {
    let response = await post(GET_ALL_COMMUNITIES, {
      CommName: "",
      Lang: "E",
      Token: token,
      Remarks: getBrowserInfo(),
    });

    let data = await response.json();
    if (!data.IsExp) {
      return data.Community;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};
const GetAllStreetForCommunity = async (community, token, lng) => {
  try {
    let response = await post(GET_STREETS_FOR_COMMUNITY, {
      CommunityName: community,
      StreetName: "",
      Lang: lng === "ar" ? "A" : "E",
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return data.Community;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetBuildingsForStreet = async (community, street, token, lng) => {
  try {
    let response = await post(GET_BUILDINGS_FOR_STREET, {
      CommunityName: community,
      StreetName: street,
      Lang: lng === "ar" ? "A" : "E",
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return data.Building;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetBuildingsAddress = async (community, street, building, token, lng) => {
  try {
    let response = await post(GET_BUILDING_ADDRESS, {
      CommunityName: community,
      StreetName: street,
      BuildingName: building,
      Lang: lng === "ar" ? "A" : "E",
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return data.Building;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};
const GetAllPOICategories = async (token) => {
  try {
    let response = await post(GET_ALL_POI_CATEGORIES, {
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();

    if (!data.IsExp) {
      return {
        data: data.Categories,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};
const GetAllPOIsOfCategoriesAPI = async (
  CategorieIds,
  makaniPosition,
  token
) => {
  try {
    let response = await post(GET_ALL_POIS_OF_CATEGORIES, {
      CategorieIds: CategorieIds,
      LatLng: makaniPosition,
      WithInDistance: "1000",
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        data: data.Pois,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
          isExp: false,
          data: [],
        };
      }
    }
  } catch (e) {
    throw e;
  }
};
const GetFavorites = async (userId, sesstionId, token) => {
  try {
    let response = await post(GET_FAVORITES, {
      InputJson: { UserId: userId, SessionId: sesstionId },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return data.Favorites;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const SyncFavorites = async (favorites, token, userId, sessionId) => {
  try {
    let response = await post(SYNC_FAVORITES, {
      InputJson: {
        Favorites: favorites,
        UserId: userId,
        SessionId: sessionId,
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();

    if (!data.IsExp) {
      return data.Favorites;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetParcelOutline = async (parcelNumber) => {
  try {
    let response = await post(GET_PARCEL_OUTLINE, {
      ParcelId: parcelNumber,
      Token: "l4f76613re4junvvbbd4ufki1j!=+=o6JGpy02dppzxKfVibRYfQ==",
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();

    if (!data.IsExp) {
      return {
        parcelInfo: data.ParcelInfo,
        buildings: data.Buildings,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetNearestMakani = async (currentLocation, token) => {
  try {
    let response = await post(GET_NEAREST_MAKANI, {
      InputJson: {
        lngLat: currentLocation,
      },
      Remarks: getBrowserInfo(),
      Token: token,
    });
    let data = await response.json();

    if (!data.IsExp) {
      return data.MakaniInfo[0];
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetZoningRegulation = async (t, parcelNumber, token) => {
  try {
    let response = await post(GET_ZONING_REGULATION, {
      InputJson: {
        Landno: parcelNumber,
        SessionId: "",
        UserId: 0,
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });

    let data = await response.json();
    if (!data.IsExp) {
      return {
        Zoningregulations: data.Zoningregulations,
        Coastaldetails: data.Coastaldetails,
        Exceptiondetails: data.Exceptiondetails,
        Message: data.Message,
      };
    } else {
      return {
        // error: data.Data,
        error: handleError(t, data.Code),
      };
    }
  } catch (e) {
    throw e;
  }
};

const GetBuildingOutLineEntrancePoints = async (t, token, { lat, lng }) => {
  try {
    let response = await post(Get_BUILDING_OUTLINE_ENTRANCE_POINTS, {
      Lat: lat,
      Lng: lng,
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        Enterances: data.BuildingInfo.Enterance,
        Buildings: data.EntranceInfo.Building,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetVirtualPlate = async (token, { lat, lng }) => {
  try {
    let response = await post(Get_VIRTUAL_PLATE, {
      InputJson: {
        searchtext: "",
        currentlocation: `${lat},${lng}`,
        distancetype: "KM",
        level: "1",
        distance: "100",
        featureclass_id: "(2)",
        userid: "",
        sessionid: "",
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const ShareLocation = async (token, { lat, lng }) => {
  try {
    let response = await post(SHARE_LOCATION, {
      InputJson: {
        Latitude: lat,
        Longitude: lng,
        Name: "queryy",
        SessionId: "",
        UserId: "",
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        DbSeqNo: data.DbSeqNo,
        CommunityE: data.CommunityE,
        CommunityA: data.CommunityA,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: data.Data,
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const postData = async (data, token) => {
  try {
    let response = await post(NOTIFY_UAEPASS_USER_LOGIN, {
      InputJson: {
        ...data,
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    } else {
      const responseBody = await response.json();
      return responseBody;
    }
  } catch (e) {
    throw e;
  }
};

const ReportMakaniApi = async (t, makaniInfo, token) => {
  try {
    let response = await post(REPORT_MAKANI, {
      InputJson: {
        emailId: makaniInfo.email,
        mobileNo: makaniInfo.mobile,
        makani: makaniInfo.makaniNumber,
        location: makaniInfo.location,
        imageBase64: makaniInfo.imageBase64,
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const getLocation = async (t, DbSeqNo, token) => {
  try {
    const response = await post("/GetLocation", {
      InputJson: {
        DbSeqNo: DbSeqNo,
        UserId: "",
        SessionId: "",
      },
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        lat: data.Latitude,
        lng: data.Longitude,
        CommunityE: data.CommunityE,
        CommunityA: data.CommunityA,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    console.error("Error in getLocation:", e);
  }
};

const GetFloorPlans = async (t, token, buildingId, floorRef) => {
  try {
    const response = await post(GET_FLOORS_PLANS, {
      InputJson: {
        buildingId: buildingId,
        floorRef: floorRef || "",
        htmlHeight: "1000",
        htmlWidth: "1000",
      },
      Remarks: getBrowserInfo(),

      Token: token,
    });

    let data = await response.json();
    if (!data.IsExp) {
      return {
        floor: data.Data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetNodeList = async (t, token, buildingId) => {
  try {
    const response = await post(GET_NODE_LIST, {
      InputJson: {
        buildingId: buildingId,
      },
      Remarks: getBrowserInfo(),
      Token: token,
    });

    let data = await response.json();
    if (!data.IsExp) {
      return {
        IsExp: data.IsExp,
        data: data.Data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
          data: null,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetNodesByName = async (t, token, value, buildingid) => {
  try {
    const response = await post(GET_NODES_BY_NAME, {
      InputJson: {
        buildingId: buildingid,
        nodeName: value,
      },
      Remarks: getBrowserInfo(),
      Token: token,
    });

    let data = await response.json();
    if (!data.IsExp) {
      return {
        nodes: data.Data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetRouteByType = async (
  t,
  origin,
  destination,
  travelMode,
  token,
  buildingid
) => {
  try {
    if (!buildingid) {
      return null;
    }

    const response = await post(GET_ROUTE_BY_TYPE, {
      InputJson: {
        buildingId: buildingid,
        routeType: "ID",
        source: origin,
        destination: destination,
        travelMode: travelMode,
        htmlWidth: "1000",
        htmlHeight: "1000",
      },
      Remarks: getBrowserInfo(),
      Token: token,
    });

    let data = await response.json();

    if (!data.IsExp) {
      return {
        numberofsteps: data.Data.numberofsteps,
        steps: data.Data.steps,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetTravelModes = async (t, token, buildingid) => {
  try {
    const response = await post(GET_TRAVEL_MODES, {
      InputJson: {
        buildingId: buildingid,
      },
      Remarks: getBrowserInfo(),
      Token: token,
    });
    let data = await response.json();
    if (!data.IsExp) {
      return {
        data: data.Data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetFloors = async (t, token, bldid) => {
  try {
    const response = await post(GET_FLOORS, {
      InputJson: {
        buildingId: bldid,
      },
      Remarks: getBrowserInfo(),

      Token: token,
    });

    let data = await response.json();
    if (!data.IsExp) {
      return {
        data,
      };
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const GetInitialParams = async (t, token) => {
  try {
    let response = await post(GET_INITIAL_PARAMS, {
      Token: token,
      Remarks: getBrowserInfo(),
    });
    let data = await response.json();
    if (!data.IsExp) {
      return data;
    } else {
      if (
        (data.IsExp && data.ExpMessage === "Authentication Failed") ||
        (data.IsExp && data.ExpMessage === "Invalid Token")
      ) {
        return {
          isExp: true,
        };
      } else {
        return {
          error: handleError(t, data.Code),
        };
      }
    }
  } catch (e) {
    throw e;
  }
};

const SearchFromGoogle = async (searchText, language) => {
  const hereMapPlaceUrl =
    SEARCH_GOOGLE_URL + `text=${searchText}&ln=${language}`;

  try {
    let response = await fetch(hereMapPlaceUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    let data = await response.json();
    // let data = JSON.parse(rawData)
    return data;
  } catch (error) {
    throw error;
  }
};

const getToken = async () => {
  try {
    let response = await post(GET_TOKEN, {
      InputJson: {
        input:
          "Nkt2dk5KbW1CQ3VsRXNPaG5oQldaeVhRU01uanBYcFV3cnFxcmtPWWJzNGo2TDl4TmVEbHlQY0pZVDhkb3R4K3NNbkQ2cWhnNUVZPWFRTURNZjRoVmxtN0xMelVFRGd0T09qWUxOZWpTY1pGTG53b2ZhVWUyMHJMbnNkTW1xR1M4UUxqd2tBMEk3NVA",
      },
      Remarks: "rem",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.Data) {
      return data.Data;
    } else {
      return "";
    }
  } catch (error) {
    console.error(`  ${error.message}`);
  }
};

const fetchTranslationsFromServer = async (lng) => {
  try {
    const response = await fetch(
      `${LANGUAGE_FILE_URL}/localization/web/${lng}.json?timestamp=${new Date().getTime()}`
    );
    if (!response.ok) {
      const text = await response.text();
      return text;
      // console.error("Failed to fetch data:", text);
      // throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error(`Error fetching data ${error.message}`);
  }
};

export {
  GetBuildingInfo,
  GetSmartSearch,
  GetSmartSearchResult,
  GetAllCommunities,
  GetAllStreetForCommunity,
  GetBuildingsForStreet,
  GetBuildingsAddress,
  GetAllPOICategories,
  GetAllPOIsOfCategoriesAPI,
  GetFavorites,
  GetParcelOutline,
  GetBuildingOutLineEntrancePoints,
  GetVirtualPlate,
  SyncFavorites,
  GetNearestMakani,
  GetZoningRegulation,
  postData,
  ShareLocation,
  ReportMakaniApi,
  getLocation,
  GetFloorPlans,
  GetNodeList,
  GetNodesByName,
  GetRouteByType,
  GetTravelModes,
  SearchFromGoogle,
  GetFloors,
  GetInitialParams,
  getToken,
  fetchTranslationsFromServer,
};

//15:47

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "./style.css";
import { useTranslation } from "react-i18next";
import { SendIcon, ShareIcon } from "../../assets/svg/svgIcons";
import {
  UploadIcon,
  FindMyBldg,
  FindMyLand,
  Rectangle,
  ArrowRight,
  FavoriteIcon,
  InfoIcon,
  SetingsIcon,
  AboutIcon,
  SmallRectangle,
  SmallArrow,
  SecurityIcon,
} from "../../assets/svg/svgIcons";

import card from "../../assets/images/card.svg";
import { cardLogoDark, cardLogo, UAEPASS_Logo } from "../../assets/index";
import { useFavorites } from "../../context/FavouritesContext";
import HomeMakaniCard from "./HomeMakaniCard/index";
import { usePOICategories } from "../../context/POICategoriesContext";
import { useAppearance } from "../../context/AppearanceContext";
import { useAuth } from "../../context/auth.js";
import { useMapControl } from "../../context/MapControllContext";
import AccessibleButton from "../Accessibility/accessibility";
import { useToggle } from "../../context/ToggleModel.js";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";
import i18next from "i18next";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";

const MainNavBar = ({ setShare, setSearch, handleSmartSearch }) => {
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    myMakani,
    locations,
    loading,
    setSharedURL,
    fetchSingleFavoritePlace,
    setLocations,
    setFavoritesMarker,
  } = useFavorites();

  const { theme } = useAppearance();
  const { sessionId, userId, setUrl, authUrl } = useAuth();

  const {
    setHomeFavroiteSelect,
    setCenter,
    setHomeSelectedMakani,
    measurementUnit,
  } = useMapControl();
  const { setIsRequireLogin } = useToggle();
  const { POICategories, loading: POICategoriesLoading } = usePOICategories();
  const [seeMorePOI, setSeeMorePOI] = useState(false);
  const [isLocation, setIsLocation] = useState(null);

  const handleLogin = async () => {
    window.location.href = authUrl;
    setUrl(window.location.origin);
  };

  // This function is to move OTHERS POI to the last of the POICategories array
  const moveOthersPOIToLast = (POICategories) => {
    const categoriesCopy = [...POICategories];
    const othersPOI = categoriesCopy.splice(18, 1)[0];
    categoriesCopy.push(othersPOI);
    return categoriesCopy;
  };
  const updatedPOICategories = moveOthersPOIToLast(POICategories);
  //

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };
    setHomeSelectedMakani(-1);
    setHomeFavroiteSelect(-1);
    checkLocation();
  }, []);

  const handelSearchPOI = (item) => {
    setSearch(item);
  };
  useEffect(() => {
    const sortedLocations = locations.sort(
      (a, b) => b.LastUpdated - a.LastUpdated
    );
    setLocations([...sortedLocations]);
    setFavoritesMarker(
      sortedLocations.map((item) => ({
        lat: Number(item.Location.split(",")[0]),
        lng: Number(item.Location.split(",")[1]),
      }))
    );
  }, []);

  // revert to this code if any issues with the updatedPOICategories
  // let newPOICategories = seeMorePOI
  //   ? [...POICategories]
  //   : [...POICategories].splice(0, 5);

  let newPOICategories = seeMorePOI
    ? [...updatedPOICategories]
    : [...updatedPOICategories].splice(0, 4);

  function reverseAndConcat(input) {
    return input.split(" ").reverse().join(" ");
  }

  return (
    <>
      <div className="searchCards">
        <AccessibleButton
          className="searchCard scan_qr_desc"
          onClick={() => navigate("/uploadQR")}
          ariaLabel="Click here to upload QR"
        >
          <div className="searchCard">
            <p className="searchCardTitle">{t("uploadQR")}</p>
            <div className="searchCardFooter">
              <UploadIcon className="searchCardFooterLogo" />

              <div className="rectangle">
                <Rectangle />

                <ArrowRight className="transform: rotate(180deg)" />
              </div>
            </div>
          </div>
        </AccessibleButton>
        <AccessibleButton
          className="searchCard"
          onClick={() => navigate("/searchByBuildingAddress")}
          ariaLabel={t("Click here to find my building")}
        >
          <div className="searchCard search_by_community_desc">
            <p className="searchCardTitle">{t("findMyBuilding")}</p>
            <div className="searchCardFooter">
              <FindMyBldg />

              <div className="rectangle">
                <Rectangle />
                <ArrowRight />
              </div>
            </div>
          </div>
        </AccessibleButton>
        <AccessibleButton
          className="searchCard"
          onClick={() => navigate("/searchByLandNumber")}
          ariaLabel={t("Click here to find my land number")}
        >
          <div className="searchCard Enter_land_number_desc">
            <p className="searchCardTitle">{t("findMyLandNumber")}</p>
            <div className="searchCardFooter ">
              <FindMyLand />

              <div className="rectangle">
                <Rectangle />
                <ArrowRight />
              </div>
            </div>
          </div>
        </AccessibleButton>
      </div>
      <AccessibleButton
        className="pointOfInternetContainer"
        ariaLabel={t("Here are the points of interest being shown")}
      >
        <div className="pointOfInternetContainer">
          <div className="pointOfInternetHeader ">
            <p className="headerTitle">{t("pointOfInterest")}</p>
            <AccessibleButton
              className="seeMoreTitle "
              ariaLabel={t("click here to see more")}
              onClick={() => setSeeMorePOI(!seeMorePOI)}
            >
              {" "}
              {seeMorePOI ? t("seeLess") : t("seeMore")}
            </AccessibleButton>
          </div>

          {POICategoriesLoading && (
            <div className="pointOfInternetLoading">
              {[...Array(4)].map((_, index) => (
                <div>
                  <div className="pointOfInternetIconLoading">
                    <Skeleton circle={true} height={50} width={50} />
                  </div>
                  <p className="pointOfInternetTitle">
                    <Skeleton width={45} />
                  </p>
                </div>
              ))}
            </div>
          )}

          {!POICategoriesLoading && (
            <div className="pointOfInternetContent ">
              {newPOICategories.map((item, index) => {
                const firstLetter = item?.CategoryE.charAt(0);

                const firstLetterCap = firstLetter?.toUpperCase();

                const remainingLetters = item?.CategoryE.slice(1).toLowerCase();

                const capitalizedWord = firstLetterCap + remainingLetters;
                return (
                  <div
                    className={`pointOfInternetItem search_poi_desc`}
                    style={{
                      marginBottom: seeMorePOI && "15px",
                    }}
                    onClick={() => {
                      setSearch(
                        il8n.language === "ar"
                          ? item.CategoryA
                          : item?.CategoryE
                      );
                    }}
                  >
                    <div className="pointOfInternetIcon">
                      <img
                        width="42"
                        height="42"
                        src={item?.Symbol}
                        alt={item?.CategoryE}
                      />
                    </div>
                    <p
                      className="pointOfInternetTitle"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        il8n.language === "ar"
                          ? item.CategoryA
                          : capitalizedWord
                      }
                    >
                      {il8n.language === "ar"
                        ? item.CategoryA
                        : capitalizedWord}
                    </p>
                    {/* <Tooltip
                      id="my-tooltip"
                      className="custom-tooltip"
                      style={{
                        background: "#333c4f",
                      }}
                    /> */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </AccessibleButton>

      <>
        {sessionId && userId ? (
          <>
            <AccessibleButton
              className="makaniCards"
              ariaLabel={t("Here are the makani cards being shown")}
            >
              <div className="makaniCardsHeader">
                <p className="headerTitle">{t("myMakaniCard")}</p>
                <AccessibleButton
                  className="seeMoreTitle"
                  onClick={() => {
                    if (sessionId && userId) {
                      navigate("/profile/myMakaniCards", {
                        state: {
                          from: "/",
                        },
                      });
                    } else {
                      setIsRequireLogin(true);
                    }
                  }}
                  ariaLabel={t("Click here to edit")}
                >
                  {t("edit")}
                </AccessibleButton>
              </div>
              <div className="makaniCardsContent">
                {loading &&
                  [...Array(2)].map((_, index) => (
                    <div className="MakaniCard">
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardLogoAr"
                            : "MakaniCardLogo"
                        }
                      >
                        <Skeleton circle={true} height={50} width={50} />
                      </div>
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardInfoAr"
                            : "MakaniCardInfo"
                        }
                      >
                        <p className="makaniCardTitle">
                          <Skeleton width={100} />
                        </p>
                        <p className="makaniCardNumber">
                          {" "}
                          <Skeleton width={100} />
                        </p>
                        <p className="makaniCardDistance">
                          <span>
                            {" "}
                            <Skeleton width={100} />
                          </span>
                        </p>
                      </div>
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardBtnsAr"
                            : "MakaniCardBtns"
                        }
                      >
                        <div
                          className="MakaniCardBtn"
                          style={{ background: "unset" }}
                        >
                          <Skeleton circle={true} height={50} width={50} />
                        </div>
                        <div
                          className="MakaniCardBtn"
                          style={{ background: "unset" }}
                        >
                          <Skeleton circle={true} height={50} width={50} />
                        </div>
                      </div>
                    </div>
                  ))}

                {!loading &&
                  myMakani
                    .slice(0, 2)
                    .map((item, index) => (
                      <HomeMakaniCard
                        item={item}
                        setShare={setShare}
                        index={index}
                      />
                    ))}
                {!loading && myMakani?.length === 0 && (
                  <>
                    <div className="MakaniCard no-data">
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardLogoAr"
                            : "MakaniCardLogo"
                        }
                      >
                        {theme === "dark" ? (
                          <img
                            src={cardLogoDark}
                            alt="makani logo"
                            width="87"
                            height="34"
                          />
                        ) : (
                          <img
                            src={cardLogo}
                            alt="makani logo"
                            width="87"
                            height="34"
                          />
                        )}
                      </div>
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardInfoAr"
                            : "MakaniCardInfo"
                        }
                      >
                        <p className="makaniCardTitle">Home</p>
                        <p className="makaniCardNumber">18518 79408</p>
                        <p className="makaniCardDistance">
                          <span>{!isLocation ? "--" : 22}</span> {t("")}
                        </p>
                      </div>
                      <div
                        className={
                          il8n.language === "ar"
                            ? "MakaniCardBtnsAr"
                            : "MakaniCardBtns"
                        }
                      >
                        <div className="MakaniCardBtn">
                          <SendIcon />
                        </div>
                        <div className="MakaniCardBtn">
                          <ShareIcon />
                        </div>
                      </div>
                    </div>
                    <p className="no-makani-found">
                      {" "}
                      {t("YouHaventAddAny")}{" "}
                      <img
                        src={card}
                        width="19"
                        height="14"
                        alt="card"
                        style={{
                          marginBottom: "-3px",
                        }}
                      />{" "}
                      {t("makaniCardYet")}
                    </p>
                  </>
                )}
              </div>
            </AccessibleButton>

            <AccessibleButton
              className="favorites"
              ariaLabel={t("here are your favorites")}
            >
              <div className="favoritesHeader">
                <p className="headerTitle">{t("favourites")}</p>
                <p
                  className="seeMoreTitle"
                  onClick={() => {
                    if (userId && sessionId) {
                      navigate("/favorites", {
                        state: {
                          from: "/",
                        },
                      });
                    } else {
                      setIsRequireLogin(true);
                    }
                  }}
                >
                  {" "}
                  {t("seeMore")}
                </p>
              </div>
              <div
                className="favoritesList"
                style={{
                  display: !loading && locations?.length === 0 && "none",
                }}
              >
                {loading &&
                  [...Array(2)].map((_, index) => (
                    <div className="favoritesItemWrapper" key={index}>
                      <div
                        className={`favoritesItem ${
                          index === 0
                            ? "firstFavoritesItem"
                            : index === 2
                            ? "lastFavoritesItem"
                            : ""
                        }`}
                      >
                        <div className="favoritesItemLeft">
                          <div className="favoriteItemLogo">
                            <Skeleton circle={true} height={50} width={50} />
                          </div>
                          <div>
                            <p className="favoriteTitle">
                              <Skeleton width={100} />
                            </p>
                            <p className="favoritePOI">
                              <Skeleton width={150} />
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="favoriteItemMakaniNumber">
                            <Skeleton width={80} />
                          </p>
                          <p className="favoriteItemMakaniDistance">
                            <Skeleton width={50} />
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                {!loading &&
                  locations.slice(0, 3).map((item, index) => (
                    <div
                      className="favoritesItemWrapper"
                      onClick={() => {
                        setHomeFavroiteSelect(index);
                        setHomeSelectedMakani(-1);
                        fetchSingleFavoritePlace(
                          2,
                          item.FavValue.replace(/\s+/g, "")
                        );
                        setCenter({
                          lat: parseFloat(
                            locations[index].Location.split(",")[0]
                          ),
                          lng: parseFloat(
                            locations[index].Location.split(",")[1]
                          ),
                        });
                      }}
                    >
                      <div
                        className={`favoritesItem ${
                          index === 0
                            ? "firstFavoritesItem"
                            : index === 2
                            ? "lastFavoritesItem"
                            : ""
                        }`}
                      >
                        <div className="favoritesItemLeft">
                          <div className="favoriteItemLogo">
                            <FavoriteIcon />
                          </div>
                          <div>
                            <p
                              className="favoriteTitle favoriteTitleEllipsis"
                              data-tooltip-id="favorite-title-tooltip"
                              data-tooltip-content={item.FavName}
                            >
                              {item.FavName}
                            </p>
                            <Tooltip
                              className="custom-tooltip"
                              id="favorite-title-tooltip"
                              style={{
                                background: "#333c4f",
                              }}
                            />
                            <p
                              className="favoritePOI favoriteTitleEllipsis"
                              data-tooltip-id="favorite-POI-tooltip"
                              data-tooltip-content={
                                il8n.language === "ar"
                                  ? item.BuildingNameA.split("/")[0]
                                  : item.BuildingNameE.split("/")[0]
                              }
                            >
                              {il8n.language === "ar"
                                ? item.BuildingNameA.split("/")[0]
                                : item.BuildingNameE.split("/")[0]}
                            </p>
                            <Tooltip
                              id="favorite-POI-tooltip"
                              className="custom-tooltip"
                              style={{
                                background: "#333c4f",
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <p className="favoriteItemMakaniNumber">
                            {il8n.language === "ar"
                              ? reverseAndConcat(item.FavValue)
                              : item.FavValue}
                          </p>
                          <p className="favoriteItemMakaniDistance">
                            <span>
                              {!isLocation
                                ? "--"
                                : parseDistanceWithUnit(
                                    measurementUnit,
                                    item.distance?.toFixed(2)
                                  )}{" "}
                            </span>
                            {measurementUnit == "kilometers"
                              ? t("km")
                              : t("miles")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {!loading && locations?.length === 0 && (
                <>
                  <div
                    className="favoritesList no-data"
                    style={{
                      padding: "0 15px",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="favoritesIsessionIdtemWrapper">
                      <div
                        className={`favoritesItem`}
                        style={{
                          border: "none",
                        }}
                      >
                        <div className="favoritesItemLeft">
                          <div className="favoriteItemLogo">
                            <FavoriteIcon />
                          </div>
                          <div>
                            <p className="favoriteTitle">Ahmed Home</p>
                            <p className="favoritePOI">St. 1 Rashidiya</p>
                          </div>
                        </div>

                        <div>
                          <p className="favoriteItemMakaniNumber">
                            18518 79408
                          </p>
                          <p className="favoriteItemMakaniDistance">
                            <span>{!isLocation ? "--" : 22}</span>{" "}
                            {measurementUnit == "kilometers"
                              ? t("km")
                              : t("miles")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="no-makani-found">
                    {" "}
                    {t("YouHaventAddAny")}{" "}
                    <img
                      src={card}
                      width="19"
                      height="14"
                      alt="card"
                      style={{
                        marginBottom: "-3px",
                      }}
                    />{" "}
                    {t("makaniCardYet")}
                  </p>
                </>
              )}
            </AccessibleButton>
          </>
        ) : (
          <div
            style={{
              marginBottom: "25px",
            }}
          >
            <div>
              <p className="noAccess">{t("accessFeature")}</p>
              <div
                className="signupWarperHome"
                style={{
                  border: theme === "dark" ? "4px solid #4E5972" : "",
                }}
              >
                <img src={UAEPASS_Logo} alt="point" width="29" height="29" />
                <AccessibleButton
                  ariaLabel={t("Click here to sign up using UAE Pass")}
                  onClick={handleLogin}
                >
                  <p className="signUpTitle" onClick={() => handleLogin()}>
                    {t("signUp")}
                  </p>
                </AccessibleButton>
              </div>
            </div>

            <div className="MakaniCard no-data">
              <div
                className={
                  il8n.language === "ar" ? "MakaniCardLogoAr" : "MakaniCardLogo"
                }
              >
                {theme === "dark" ? (
                  <img
                    src={cardLogoDark}
                    alt="makani logo"
                    width="87"
                    height="34"
                  />
                ) : (
                  <img
                    src={cardLogo}
                    alt="makani logo"
                    width="87"
                    height="34"
                  />
                )}
              </div>
              <div
                className={
                  il8n.language === "ar" ? "MakaniCardInfoAr" : "MakaniCardInfo"
                }
              >
                <p className="makaniCardTitle">Home</p>
                <p className="makaniCardNumber">18518 79408</p>
                <p className="makaniCardDistance">
                  <span>{!isLocation ? "--" : 22}</span> {}
                </p>
              </div>
              <div
                className={
                  il8n.language === "ar" ? "MakaniCardBtnsAr" : "MakaniCardBtns"
                }
              >
                <div className="MakaniCardBtn">
                  <SendIcon />
                </div>
                <div className="MakaniCardBtn">
                  <ShareIcon />
                </div>
              </div>
            </div>

            <div
              className="favoritesList no-data"
              style={{
                padding: "0 20px",
                marginBottom: "30px !important",
              }}
            >
              <div className="favoritesIsessionIdtemWrapper">
                <div
                  className={`favoritesItem`}
                  style={{
                    border: "none",
                  }}
                >
                  <div className="favoritesItemLeft">
                    <div className="favoriteItemLogo">
                      <FavoriteIcon />
                    </div>
                    <div>
                      <p className="favoriteTitle">Ahmed Home</p>
                      <p className="favoritePOI">St. 1 Rashidiya</p>
                    </div>
                  </div>

                  <div>
                    <p className="favoriteItemMakaniNumber">18518 79408</p>
                    <p className="favoriteItemMakaniDistance">
                      <span>{!isLocation ? "--" : 22}</span>{" "}
                      {measurementUnit == "kilometers" ? t("km") : t("miles")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      <div className="infoCards">
        <AccessibleButton
          onClick={() => navigate("/report-makani-plate-damage")}
          className="ReportCard report_damage"
          style={{
            paddingTop: il8n.language === "ar" && "0",
          }}
          ariaLabel={t("Click here to report makani plate damage")}
        >
          <p
            style={{
              paddingRight: il8n.language === "ar" && "10px",
            }}
          >
            {t("reportMakaniPlateDamage")}
          </p>
          <div
            className="ReportCardFooter"
            style={{
              flexDirection: il8n.language === "ar" && "row-reverse",
            }}
          >
            <InfoIcon />
            <div className="rectangle">
              <Rectangle />
              <ArrowRight />
            </div>
          </div>
        </AccessibleButton>
        <div className="settingAboutCards ">
          <AccessibleButton
            className="settingCard setting_desc"
            onClick={() => navigate("/settings")}
            style={{
              flexDirection: il8n.language === "ar" && "row-reverse",
            }}
            ariaLabel={t("Click here for settings page")}
          >
            <SetingsIcon />

            <p>{t("settings")}</p>

            <div className="rectangle2">
              <SmallRectangle />
              <SmallArrow />
            </div>
          </AccessibleButton>
          <AccessibleButton
            className="aboutCard about_desc"
            onClick={() => navigate("/about")}
            style={{
              flexDirection: il8n.language === "ar" && "row-reverse",
            }}
            ariaLabel={t("Click here to know about us")}
          >
            <AboutIcon />
            <p>{t("about")}</p>
            <div className="rectangle2">
              <SmallRectangle />
              <SmallArrow />
            </div>
          </AccessibleButton>
        </div>
      </div>
    </>
  );
};
export default MainNavBar;

import React, { createContext, useState, useContext } from "react";
const ToggleContext = createContext();
export const useToggle = () => useContext(ToggleContext);

export const ToggleProvider = ({ children }) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [mapLayerToggle, setMapLayerToggle] = useState(false);
  const [isRequireLogin, setIsRequireLogin] = useState(false);
  const [collapsible, setCollapsibe] = useState(-1);
  const [allowAccessLocation, setAllowAccessLocation] = useState(false);

  const handleMenuIcon = () => {
    setMenuToggle(!menuToggle);
  };
  return (
    <ToggleContext.Provider
      value={{
        handleMenuIcon,
        menuToggle,
        setMenuToggle,
        setMapLayerToggle,
        mapLayerToggle,
        isRequireLogin,
        setIsRequireLogin,
        setCollapsibe,
        collapsible,
        setAllowAccessLocation,
        allowAccessLocation,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};
export default ToggleProvider;

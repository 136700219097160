import { makaniCloseIcon } from "../../../assets/index";
import { useToggle } from "../../../context/ToggleModel.js";
import { useAuth } from "../../../context/auth.js";
import { useTranslation } from "react-i18next";
import { useRef, useEffect } from "react";

const LoginModel = () => {
  const { setIsRequireLogin } = useToggle();
  const { authUrl } = useAuth();
  const [t, il8n] = useTranslation();
  const modalRef = useRef(null); // Create a ref for the modal

  const handleLogin = async () => {
    window.location.href = authUrl;
  };

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsRequireLogin(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsRequireLogin]);

  return (
    <div className="delete-m-card loginPopUp" ref={modalRef}>
      <div className="d-head">
        <span
          style={{
            marginRight: il8n.language === "ar" && "auto",
            marginLeft: il8n.language === "ar" && "unset",
          }}
        >
          <strong>{t("loginRequired")}</strong>
        </span>
        <button
          style={{
            marginRight: il8n.language === "ar" && "auto",
            marginLeft: il8n.language === "ar" && "unset",
          }}
          className="close_btn"
          onClick={() => setIsRequireLogin(false)}
        >
          <img src={makaniCloseIcon} alt="close button" />
        </button>
      </div>
      <div className="body-content">
        <p>{t("accessToThisFeatureRequiresYouToBeLoggedIn")}</p>
        <div
          className="btn__brp"
          style={{
            direction: "ltr",
          }}
        >
          <button
            className="btn-m btn-cancel"
            onClick={() => setIsRequireLogin(false)}
          >
            {t("cancel")}
          </button>
          <button className="btn-m btn-delete" onClick={handleLogin}>
            {t("logIn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModel;

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  copy,
  detailsMakanilogo,
  navigateIcon2,
  logo,
  selectedCard,
  checked,
} from "../../assets/index";
import {
  LoveIcon,
  ShareIcons,
  StreetView,
  AddContact,
  Medical,
  Hearticon,
  MakaniCardIcon,
} from "../../assets/svg/svgIcons";
import { toast } from "react-toastify";

import useClipboard from "react-use-clipboard";
import withTour from "../../withTour";

import { DirectionsIcon } from "../../assets/svg/svgIcons";
import "./style.css";
import { useNavigate } from "react-router-dom";
import AddFavoritesModel from "../../component/models/AddFavoritesModel/index";
import { useFavorites } from "../../context/FavouritesContext.js";
import { useAppearance } from "../../context/AppearanceContext.js";
import { useMapControl } from "../../context/MapControllContext.js";
import { useAuth } from "../../context/auth.js";
import { useToggle } from "../../context/ToggleModel.js";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";
import { SHARE_URL_BASE } from "../../helper/api/constants.ts";
import ShareModel from "../models/shareModel/index.jsx";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";
const MakaniCard = ({
  slideIndex,
  index,
  item,
  style,
  width,
  gap,
  withArrow,
  allowAddMakani,
}) => {
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();
  const { measurementUnit } = useMapControl();
  const { setFavoritesToggle, favoritesToggle, setSharedURL } = useFavorites();
  const { sessionId, userId } = useAuth();
  const { isRequireLogin, setIsRequireLogin } = useToggle();
  const [isCopied, setCopied] = useClipboard(item.Makani, {
    successDuration: 1000,
  });

  const { setStartDirection } = useMapControl();
  const { theme } = useAppearance();
  const { setStreetLevel } = useMapControl();
  const [isFavoriteToggle, setIsFavoriteToggle] = useState(false);
  const [isMakaniToggle, setIsMakaniToggle] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isMakani, setIsMakani] = useState(false);
  const [itemToAdd, setItemToAdd] = useState({});
  const [share, setShare] = useState(false);
  const [isLocation, setIsLocation] = useState(null);

  useEffect(() => {
    // NOTE: Distance on user location
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    setFavoritesToggle({
      item: {},
      isFavoritesToggle: false,
      index: -1,
    });
    checkLocation();
  }, []);

  // function reverseAndConcat(input) {
  //   const splitArray = input.split(" ");
  //   const reversedArray = splitArray.reverse();

  //   const result = reversedArray.join(" ");

  //   return result;
  // }

  function reverseAndConcat(input) {
    return input.replace(/ /g, "\u00A0");
  }

  const handleCopy = () => {
    setCopied();
  };
  return (
    <div
      style={{
        // cursor: 'pointer',
        left: 0,
        ...style,
      }}
      className={` ${
        slideIndex === index || slideIndex + 1 === index
          ? "deatilsMakaniCardActive singleMakaniCard"
          : ""
      }
      ${withArrow ? "deatilsMakaniCardWithArrow" : "deatilsMakaniCard"}`}
    >
      {isFavoriteToggle || isMakaniToggle ? (
        <AddFavoritesModel
          setIsFavoriteToggle={setIsFavoriteToggle}
          setIsMakaniToggle={setIsMakaniToggle}
          isMakaniToggle={isMakaniToggle}
          isFavoriteToggle={isFavoriteToggle}
          item={itemToAdd}
          setIsFavorite={setIsFavorite}
          setIsMakani={setIsMakani}
        />
      ) : (
        <>
          {share ? (
            <ShareModel
              url={`${SHARE_URL_BASE}?l=${
                il8n.language === "ar" ? "A" : "E"
              }&m=${item.Makani.replace(/\s+/g, "")}`}
              setShare={setShare}
              ForMakani={true}
            />
          ) : (
            <>
              <div className="MakaniCardHeader">
                <div>
                  <img
                    src={theme === "dark" ? logo : detailsMakanilogo}
                    alt="makani-logo"
                    className="logo-in-card"
                  />
                </div>
                <div className="copyWrapper" onClick={handleCopy}>
                  <p
                    className="makaniCardNumber"
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    {il8n.language === "ar"
                      ? reverseAndConcat(item.Makani)
                      : item.Makani}
                  </p>
                  {isCopied ? (
                    <span className="coped-align">
                      <span class="copied">{t("copied")}</span>{" "}
                      <img
                        width="14"
                        height="14"
                        className="copy__btn copiedbtn"
                        src={checked}
                        alt="copy"
                      />
                    </span>
                  ) : (
                    <img
                      width="14"
                      height="14"
                      className="copy__btn"
                      src={copy}
                      alt="copy"
                    />
                  )}
                </div>
                <div
                  className="d-flex-cstm add_favorite_desc"
                  onClick={() => {
                    if (!sessionId || !userId) {
                      setIsRequireLogin(true);
                    } else {
                      if (!item.isFavoriteLocation && !isFavorite) {
                        setIsFavoriteToggle(true);
                        let transformed;
                        if (!item.SHAPE) {
                          transformed = item.LatLng;
                        } else {
                          let parts = item.SHAPE.split(",") || item;
                          transformed = parts.reverse().join(",");
                        }

                        setItemToAdd({
                          IsMyMakani: false,
                          FavName: "",
                          FavType: "LOCATION",
                          FavValue: item.Makani,
                          UserId: userId,
                          BuildingNameE:
                            item.BldgNameEn.trim() || item.searchText,
                          BuildingNameA:
                            item.BldgNameAr.trim() || item.searchText,
                          Location: transformed,
                          LastUpdated: 1711532703252,
                        });
                      } else {
                        setIsFavoriteToggle(false);
                      }
                    }
                  }}
                >
                  {item.isFavoriteLocation || isFavorite ? (
                    <Hearticon />
                  ) : (
                    <LoveIcon alt="love" className="love" />
                  )}
                </div>
              </div>
              <div className="MakaniCardContent">
                <div
                  className="MakaniCardContentText "
                  style={{
                    padding: il8n.language === "ar" && "5px 15px",
                  }}
                >
                  <p>
                    {il8n.language === "ar" ? item.EntNameA : item.EntNameE}
                  </p>
                  <p className={` MakaniCardContentDesinence `}>
                    <span className="directions ">
                      <DirectionsIcon />
                    </span>
                    {/* NOTE: Distance on user location*/}
                    {!isLocation
                      ? "--"
                      : parseDistanceWithUnit(
                          measurementUnit,
                          item.distance?.toFixed(2)
                        )}{" "}
                    <span>
                      {measurementUnit == "kilometers" ? t("km") : t("miles")}
                    </span>
                  </p>
                </div>
                <div className="DetailsMakaniCardBtns ">
                  <div
                    className={` DetailsMakaniCardBtn ${
                      index === 0 && "share_location_desc"
                    }`}
                    onClick={() => {
                      setShare(true);
                    }}
                  >
                    <ShareIcons />
                  </div>
                  <div
                    className={` DetailsMakaniCardBtn ${
                      index === 0 && "street-view-desc"
                    }`}
                    onClick={() =>
                      setStreetLevel({
                        lat: Number(item.SHAPE.split(",")[1]),
                        lng: Number(item.SHAPE.split(",")[0]),
                      })
                    }
                  >
                    <StreetView />
                  </div>

                  {/* <div className='DetailsMakaniCardBtn'>
            <AddContact alt='add to contact' />
          </div> */}
                  <div
                    className="DetailsMakaniCardBtn add_makani_desc"
                    onClick={() => {
                      if (!userId || !sessionId) {
                        setIsRequireLogin(true);
                      } else {
                        if (!allowAddMakani) {
                          toast.error(
                            t("you_are_not_allowed_to_add_makani_cards"),
                            {
                              position:
                                il8n.language === "ar"
                                  ? "top-left"
                                  : "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              progress: undefined,
                              style: {
                                textAlign:
                                  il8n.language === "ar" ? "right" : "left",
                              },
                            }
                          );
                        } else if (!item.isFavoriteMakani && !isMakani) {
                          setIsMakaniToggle(true);
                          let transformed;
                          if (!item.SHAPE) {
                            transformed = item.LatLng;
                          } else {
                            let parts = item.SHAPE.split(",") || item;
                            transformed = parts.reverse().join(",");
                          }

                          setItemToAdd({
                            IsMyMakani: true,
                            FavName: "",
                            FavType: "MAKANI",
                            FavValue: item.Makani,
                            UserId: 1060538,
                            BuildingNameE:
                              item.BldgNameEn.trim() || item.searchText,
                            BuildingNameA:
                              item.BldgNameAr.trim() || item.searchText,
                            Location: transformed,
                            LastUpdated: 1711532703252,
                          });
                        }
                      }
                    }}
                  >
                    {isMakani || item.isFavoriteMakani ? (
                      <MakaniCardIcon />
                    ) : (
                      <Medical />
                    )}
                  </div>

                  <div
                    className={`navigateWarper ${
                      index === 0 && "direction_desc"
                    }`}
                    onClick={() => {
                      setStartDirection(true);
                      navigate("/direction", {
                        state: {
                          BuildingNameE:
                            item.BldgNameEn.trim() ||
                            item.searchText ||
                            (item.CommEn &&
                              item.StreetE &&
                              `${
                                item.CommEn +
                                " , " +
                                item.StreetE +
                                " , " +
                                item.AddressE
                              }`) ||
                            item.Makani,
                          BuildingNameA:
                            item.BldgNameAr.trim() ||
                            item.searchText ||
                            (item.CommAr &&
                              item.StreetA &&
                              `${
                                item.CommAr +
                                " , " +
                                item.StreetA +
                                " , " +
                                item.AddressA
                              }`) ||
                            item.Makani,
                          position: {
                            lat: Number(item.SHAPE.split(",")[1]),
                            lng: Number(item.SHAPE.split(",")[0]),
                          },
                        },
                      });
                    }}
                    style={{
                      width: width,
                      gap: gap,
                    }}
                  >
                    <img src={navigateIcon2} alt="navigate" />
                    <p className="navigateTitle">{t("navigate")}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MakaniCard;

import React, { createContext, useContext, useState } from "react";

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

const TourProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [tourSkipped, setTourSkipped] = useState(false);
  const [tourStarted, setTourStarted] = useState(false);

  const startTour = () => setRun(true);
  const stopTour = () => setRun(false);

  const value = {
    run,
    stepIndex,
    setStepIndex,
    startTour,
    stopTour,
    tourSkipped,
    setTourSkipped,
    tourStarted,
    setTourStarted,
  };

  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};

export default TourProvider;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  SortDropDown,
  EditFavoritePlaceModel,
  ShareModel,
  DeleteMakaniCard,
} from "../../component/index";
import {
  useFavorites,
  useRecentSearch,
  useAppearance,
  useMapControl,
} from "../../context/index.js";
import { formatTimestamp, isLocationEnabled } from "../../helper/index.js";
import { SHARE_URL_BASE } from "../../helper/api/constants.ts";
import {
  favouritePlaceSetting,
  favoriteFlag,
  favoritePlaceArrow,
  favoritePlaceFlagDark,
} from "../../assets/index";
import "./style.css";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";
const FavoritesPlaces = () => {
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();
  const { recentSearch, setRecentSearch } = useRecentSearch();
  const { theme } = useAppearance();
  const [sortToggle, setSortToggle] = useState(false);
  const { setCenter, setHomeFavroiteSelect } = useMapControl();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLocation, setIsLocation] = useState(null);
  const [value, setValue] = useState("");

  const {
    setFavoritesMarker,
    locations,
    setLocations,
    fetchSyncFavorites,
    loading,
    setSelectedFavoriteMarker,
    selectedFavoriteMarker,
    editLocationToggle,
    setEditLocationToggle,
    setShareLocationToggle,
    ShareLocationToggle,
    setFavoritePalceInfo,
    fetchSingleFavoritePlace,
    loading: fLoading,
    setRefetch,
  } = useFavorites();

  const { measurementUnit } = useMapControl();
  useEffect(() => {
    setEditLocationToggle({
      item: {},
      isEditLocationToggle: false,
      index: "",
    });
    setRefetch((prev) => !prev);
  }, []);

  const [DeleteFavoritesToggle, setDeleteFavoritesToggle] = useState({
    item: {},
    isDeleteMakani: false,
    index: -1,
  });
  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };
    setSelectedFavoriteMarker(-1);
    setFavoritePalceInfo({});
    checkLocation();
  }, []);

  useEffect(() => {
    const sortedLocations = locations.sort(
      (a, b) => b.LastUpdated - a.LastUpdated
    );
    setLocations([...sortedLocations]);
    setFavoritesMarker(
      sortedLocations.map((item) => ({
        lat: Number(item.Location.split(",")[0]),
        lng: Number(item.Location.split(",")[1]),
      }))
    );
  }, []);
  const handleSort = (value) => {
    if (value === "date") {
      const sortedLocations = locations.sort(
        (a, b) => b.LastUpdated - a.LastUpdated
      );
      setLocations([...sortedLocations]);
      setFavoritesMarker(
        sortedLocations.map((item) => ({
          lat: Number(item.Location.split(",")[0]),
          lng: Number(item.Location.split(",")[1]),
        }))
      );
    } else if (value === "alphabetically") {
      setLocations(
        locations.sort((a, b) => a.FavName.localeCompare(b.FavName))
      );
      setFavoritesMarker(
        locations
          .sort((a, b) => a.FavName.localeCompare(b.FavName))
          .map((item) => {
            return {
              lat: Number(item.Location.split(",")[0]),
              lng: Number(item.Location.split(",")[1]),
            };
          })
      );
    } else if (value === "distance") {
      locations.sort((a, b) => b.distance - a.distance);
    }
    setSortToggle(false);
  };

  const handllClearLocalStorge = () => {
    localStorage.removeItem("recentSearch");
    setRecentSearch([]);
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("recentSearch"));

    if (savedData) {
      setRecentSearch(savedData);
    }
  }, []);

  const [editIndexs, setEditIndexs] = useState([]);

  const toggleEditMode = (index) => {
    if (editIndexs.includes(index)) {
      setEditIndexs(editIndexs.filter((item) => item !== index));
    } else {
      setEditIndexs([...editIndexs, index]);
    }
  };

  let arr = [t("dateAdded"), t("distance"), t("alphabetically")];

  function reverseAndConcat(input) {
    return input.split(" ").reverse().join(" ");
  }

  return (
    <>
      <div className="sortWarper">
        <p>{t("sortby")}</p>
        <p className="selectedValue" onClick={() => setSortToggle(!sortToggle)}>
          {arr[selectedIndex]}
        </p>
        <SortDropDown
          setSortToggle={setSortToggle}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          sortToggle={sortToggle}
          handleSort={handleSort}
        />
      </div>
      <div className="favouritePlaces">
        {loading &&
          [...Array(3)].map((_, index) => (
            <div className="favouritePlace">
              <div className="favouritePlaceLeft">
                <div className="favouritePlaceLogo">
                  <Skeleton circle={true} height={45} width={45} />
                </div>
                <div className="favouritePlaceDetails">
                  <p className="favouritePlaceTitle">
                    {" "}
                    <Skeleton width={150} />{" "}
                  </p>
                  <p className="favouritePlaceSubTitle">
                    <Skeleton width={90} />
                  </p>
                </div>
              </div>
              <div className="favouritePlaceRight">
                <div>
                  <p className="favouritePlaceMakainNumber">
                    <Skeleton width={90} />
                  </p>
                  <p
                    className="favouritePlaceMakainDistance"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Skeleton width={60} />
                  </p>
                </div>
                <div className="favouritePlaceSettingLogo">
                  <Skeleton height={50} width={8} />
                </div>
              </div>
            </div>
          ))}

        {!loading &&
          locations.map((item, index) =>
            editIndexs.includes(index) ? (
              DeleteFavoritesToggle.index === index &&
              DeleteFavoritesToggle.isDeleteMakani ? (
                // deleteFavoriteLocation
                <div className="deleteFavoriteLocation">
                  <DeleteMakaniCard
                    title={t("Delete_Place")}
                    description={t("Delete_Place_Description")}
                    setDeleteMakaniToggle={setDeleteFavoritesToggle}
                    item={{
                      FavValue: item.FavValue,
                      FavType: item.FavType,
                      FavName: item.FavName,
                    }}
                    setEditIndexs={setEditIndexs}
                  />
                </div>
              ) : (
                <div
                  className={
                    il8n.language === "ar"
                      ? "editableFavoritePlacesAr"
                      : "editableFavoritePlaces"
                  }
                >
                  <div className="placeInfo">
                    <div className="place_pWraper">
                      <p
                        className="place_p"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.FavName}
                      </p>
                    </div>
                    <div>
                      <p className="makaniNumber">
                        {il8n.language === "ar"
                          ? reverseAndConcat(item.FavValue)
                          : item.FavValue}
                      </p>
                      <p className="distance">
                        {!isLocation
                          ? "--"
                          : parseDistanceWithUnit(
                              measurementUnit,
                              item?.distance?.toFixed(2)
                            )}{" "}
                        {measurementUnit == "kilometers" ? t("km") : t("miles")}
                      </p>
                    </div>

                    <div
                      className="arrowWarper"
                      onClick={() => {
                        toggleEditMode(index);
                      }}
                    >
                      <img src={favoritePlaceArrow} alt="arrow" />
                    </div>
                  </div>
                  <div className="favoriteAction">
                    <div
                      className="deleteFavorite"
                      onClick={() => {
                        setDeleteFavoritesToggle({
                          item: {
                            FavValue: item.FavValue,
                            FavType: item.FavType,
                            FavName: item.FavName,
                          },
                          index,
                          isDeleteMakani: true,
                        });
                      }}
                    >
                      <p>{t("delete")}</p>
                    </div>
                    <div
                      className="editFavorite"
                      onClick={() => {
                        setValue(item.FavName);
                        setEditLocationToggle({
                          isEditLocationToggle: true,
                          item,
                          index,
                        });
                        setEditIndexs(
                          editIndexs.filter((item) => item !== index)
                        );
                      }}
                    >
                      <p>
                        {t("edit")}
                        {/* <br />
                        {il8n.language === "ar" ? "الاسم" : "Name"} */}
                      </p>
                    </div>

                    <div
                      className={
                        il8n.language === "ar"
                          ? "sharedFavoriteAr"
                          : "sharedFavorite"
                      }
                      onClick={() => {
                        setShareLocationToggle({
                          isShareLocationToggle: true,
                          makani: item.FavValue,
                          index,
                        });
                        setEditIndexs(
                          editIndexs.filter((item) => item !== index)
                        );
                      }}
                    >
                      <p>{t("share")}</p>
                    </div>
                  </div>
                </div>
              )
            ) : editLocationToggle.isEditLocationToggle &&
              editLocationToggle.index === index ? (
              <EditFavoritePlaceModel
                item={item}
                index={index}
                isLocation={isLocation}
                value={value}
                setValue={setValue}
              />
            ) : ShareLocationToggle.isShareLocationToggle &&
              ShareLocationToggle.index === index ? (
              <div className="favouritePlace">
                <ShareModel
                  url={`${SHARE_URL_BASE}?l=${
                    il8n.language === "ar" ? "A" : "E"
                  }&m=${item.FavValue.replace(/\s+/g, "")}`}
                  FormFavorite={true}
                  handlePopClose={() => {
                    setShareLocationToggle({
                      isShareLocationToggle: false,
                      makani: "",
                      index: -1,
                    });
                    setEditIndexs(editIndexs.filter((item) => item !== index));
                  }}
                />
              </div>
            ) : (
              <div
                className={`${
                  selectedFavoriteMarker === index
                    ? "favouritePlaceSelected"
                    : "favouritePlace"
                }`}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedFavoriteMarker(index);
                  setHomeFavroiteSelect(index);
                  let makani = item.FavValue.replace(/\s+/g, "");

                  setCenter({
                    lat: Number(item.Location.split(",")[0]),
                    lng: Number(item.Location.split(",")[1]),
                  });

                  fetchSingleFavoritePlace(2, makani);
                  setFavoritePalceInfo({});
                }}
              >
                <div className="favouritePlaceLeft">
                  <div className="favouritePlaceLogo">
                    <img
                      src={
                        theme === "dark" ? favoritePlaceFlagDark : favoriteFlag
                      }
                      style={{
                        paddingRight: il8n.language === "ar" ? "25%" : 0,
                        paddingLeft: il8n.language === "en" ? "37%" : "0",
                      }}
                      alt="favorite Flag"
                    />
                  </div>
                  <div className="favouritePlaceDetails">
                    <p
                      className="favouritePlaceTitle"
                      style={{
                        color: selectedFavoriteMarker === index && "#FFFFFF",
                      }}
                    >
                      {item.FavName}
                    </p>
                    <p
                      className="favouritePlaceSubTitle"
                      style={{
                        color: selectedFavoriteMarker === index && "#FFFFFF94",
                      }}
                    >
                      {" "}
                      {formatTimestamp(item.LastUpdated)}
                    </p>
                  </div>
                </div>
                <div className="favouritePlaceRight">
                  <div>
                    <p
                      className="favouritePlaceMakainNumber"
                      style={{
                        textAlign: il8n.language === "ar" ? "left" : "right",
                        color: selectedFavoriteMarker === index && "#FFFFFF",
                      }}
                    >
                      {il8n.language === "ar"
                        ? reverseAndConcat(item.FavValue)
                        : item.FavValue}
                    </p>
                    <p
                      className="favouritePlaceMakainDistance"
                      style={{
                        textAlign: il8n.language === "ar" ? "left" : "right",
                      }}
                    >
                      <span>
                        {!isLocation
                          ? "--"
                          : parseDistanceWithUnit(
                              measurementUnit,
                              item?.distance?.toFixed(2)
                            )}{" "}
                      </span>
                      {measurementUnit == "kilometers" ? t("km") : t("miles")}
                    </p>
                  </div>
                  <div
                    className="favouritePlaceSettingLogo"
                    onClick={() => {
                      toggleEditMode(index);
                    }}
                  >
                    <img
                      src={favouritePlaceSetting}
                      alt="favourite place setting"
                    />
                  </div>
                </div>
              </div>
            )
          )}
        {!loading && locations.map?.length === 0 && t("noResult")}
      </div>
      <div className="favouritesProfileRecentSearches">
        <div className="favouritesProfileRecentSearchHeader">
          <p className="headerTitle">{t("recentsearches")}</p>
          <p className="seeMoreTitle" onClick={() => handllClearLocalStorge()}>
            {" "}
            {t("clearSearch")}
          </p>
        </div>
        <div className="favouritesProfileRecentSearchesWarper">
          {recentSearch?.length > 0 &&
            [...recentSearch]
              .reverse()
              .splice(0, 3)
              .map((item, index) => {
                return (
                  <div
                    className="favouritesProfileRecentSearchWarper"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/searchResult", {
                        state: {
                          dgis: item.dgis_id,
                          featureclassId: item.featureclass_id,
                          searchValue: item.text,
                        },
                      });
                    }}
                  >
                    <div className="favouritesProfileRecentSearch">
                      <p className="favouritesProfileRecentSearchTitle">
                        {item.text}
                      </p>
                      <p className="favouritesProfileRecentSearchAddress">
                        {item.category}
                      </p>
                    </div>
                    {/*<div>
              <img src={addToFavourites} className='plus-btn' alt='plus' />
            </div>*/}
                  </div>
                );
              })}

          {recentSearch?.length === 0 && t("noResult")}
        </div>
      </div>
    </>
  );
};

export default FavoritesPlaces;

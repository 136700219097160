const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp); // Convert timestamp to a Date object

  // Format the date to a readable string
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export default formatTimestamp;


const updateRecentSearch = (suggessions) => {
    if(!suggessions.fromGoogleApi) {
    let newSearch;
        
            newSearch = {
                category: suggessions.category,
                featureclass_id: suggessions.featureclass_id,
                emirate: suggessions.emirate,
                text: suggessions.text,
                featureclassId:suggessions.featureclassId,
                dgis_id:suggessions.dgis_id,
                ln:suggessions.ln
    
            };
        
        
    
    

    const recentSearch = JSON.parse(localStorage.getItem('recentSearch')) || [];

    const updatedRecentSearch = recentSearch.filter(search =>{
        
            return !(search.category === newSearch.category &&
                     search.featureclass_id === newSearch.featureclass_id &&
                     search.emirate === newSearch.emirate &&
                     search.text === newSearch.text &&
                     search.featureclassId === newSearch.featureclassId &&
                     search.dgis_id === newSearch.dgis_id);

    }
    );


        updatedRecentSearch.push(newSearch);

    localStorage.setItem('recentSearch', JSON.stringify(updatedRecentSearch));

    return updatedRecentSearch;
}
};



export default updateRecentSearch;

//11:33
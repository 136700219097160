import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import tourSteps from "./tourSteps";
import { useTranslation } from "react-i18next";
import { useToggle, useAppearance, useTour } from "./context/index.js";
import { useLocation } from "react-router-dom";

const withTour = (WrappedComponent, pageKey) => {
  return (props) => {
    const { walkThrough, setWalkThrough, theme, showPopup } = useAppearance();
    const {
      run,
      stepIndex,
      setStepIndex,
      startTour,
      stopTour,
      tourSkipped,
      setTourSkipped,
      tourStarted,
      setTourStarted,
    } = useTour();
    const location = useLocation();

    // const [tourStarted, setTourStarted] = useState(false);
    const [t, il8n] = useTranslation();

    const [screenSize, setScreenSize] = React.useState(false);

    React.useEffect(() => {
      if (window.innerWidth >= 770) {
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    }, [window.innerWidth]);

    const handleJoyrideCallback = (data) => {
      const { status, index, action, type } = data;

      if (status === "finished" || action === "close") {
        stopTour();
      } else if (status === "skipped") {
        setWalkThrough(false);
        // setWalkThrough(false)
        setTourSkipped(true);
      } else {
        setStepIndex(index);
      }
      // else if (type === 'error:target_not_found') {
      //     stopTour();
      //   setStepIndex(index);
      // }
    };

    useEffect(() => {
      if (pageKey === "app" && walkThrough && !showPopup) {
        setTourStarted(true);
        setTimeout(() => {
          startTour();
        }, 100);
      }

      if (tourStarted && walkThrough && !showPopup && pageKey !== "app") {
        setTimeout(() => {
          startTour();
        }, 100);
      }

      if (!walkThrough || showPopup) {
        stopTour();
        setTourStarted(false);
      }
    }, [pageKey, walkThrough, showPopup, tourStarted]);

    return (
      <div>
        <WrappedComponent {...props} />
        <Joyride
          className="joyride-div"
          steps={tourSteps()[pageKey]}
          run={run}
          continuous
          showProgress={false}
          showSkipButton
          // disableBeacon={true}

          debug={true}
          scrollToFirstStep
          callback={handleJoyrideCallback}
          locale={{
            back: t("back"),
            close: t("close"),
            last: t("last"),
            next: t("next"),
            skip: t("skip"),
          }}
          styles={{
            options: {
              arrowColor: theme === "dark" ? "#4e5972" : "#fff",
              backgroundColor: theme === "dark" ? "#4e5972" : "#fff",
              textColor: theme === "dark" ? "#fff" : "#009D91",
            },
            buttonNext: {
              color: "#fff",
              backgroundColor: theme === "dark" ? "#1e222b" : "#333c4f",
              "&:hover": {
                backgroundColor: "#009d91",
              },
            },
            buttonBack: {
              color: "#fff",
              backgroundColor: theme === "dark" ? "#1e222b" : "#333c4f",
              "&:hover": {
                backgroundColor: "#009d91",
              },
            },
            buttonSkip: {
              color: "#fff",
              backgroundColor: theme === "dark" ? "#1e222b" : "#333c4f",
              "&:hover": {
                backgroundColor: "#009d91",
              },
            },
            buttonClose: {
              display: "none",
            },
          }}
        />
      </div>
    );
  };
};

export default withTour;

import { useTranslation } from "react-i18next";
import "./style.css";
import { ArrowLink } from "../../assets/svg/svgIcons.jsx";
const MakaniDetails = ({
  makani,
  poi,
  parcelInfo = {},
  style = {},
  featureclassId,
  searchValue,
}) => {
  const [t, il8n] = useTranslation();

  return (
    <div className="makaniCardDetails" style={style}>
      <p className="makaniCardDetailsTitle">{t("details")}</p>
      <div className="makaniCardDetailsAddress">
        <div className="makaniCardDetailsSubAddressWrapper">
          <p className="makaniCardDetailsAddressTitle">{t("address")}</p>
          {(il8n.language === "ar"
            ? makani?.[0]?.BldgNameAr
            : makani?.[0]?.BldgNameEn) && (
            <p className="makaniCardDetailsSubAddress">
              {t("buildingName")}:
              {il8n.language === "ar"
                ? makani?.[0]?.BldgNameAr
                : makani?.[0]?.BldgNameEn}
            </p>
          )}
          {(il8n.language === "ar"
            ? makani?.[0]?.AddressA?.split("-")[1]
            : makani?.[0]?.AddressE?.split("-")[1]) && (
            <p className="makaniCardDetailsSubAddress">
              {t("street")}:{" "}
              {il8n.language === "ar"
                ? makani?.[0]?.AddressA?.split("-")[1]
                : makani?.[0]?.AddressE?.split("-")[1]}
            </p>
          )}

          {(il8n.language === "ar"
            ? makani[0]?.RoadNameAr
            : makani[0]?.RoadNameEn) && (
            <p className="makaniCardDetailsSubAddress">
              {t("street")}:{" "}
              {il8n.language === "ar"
                ? makani[0].RoadNameAr
                : makani[0].RoadNameEn}
            </p>
          )}

          {(il8n.language === "ar"
            ? makani?.[0]?.CommAr
            : makani?.[0]?.CommEn) && (
            <p className="makaniCardDetailsSubAddress">
              {t("community")} :{" "}
              {il8n.language === "ar"
                ? makani?.[0]?.CommAr
                : makani?.[0]?.CommEn}
            </p>
          )}

          {/* {(il8n.language === "ar"
            ? poi?.[0]?.CommunityA
            : poi?.[0]?.CommunityE) && (
            <p className="makaniCardDetailsSubAddress">
              {t("community")} :{" "}
              {il8n.language === "ar"
                ? poi?.[0]?.CommunityA
                : poi?.[0]?.CommunityE}
            </p>
          )} */}
          {(il8n.language === "ar"
            ? makani?.[0]?.EmirateA
            : makani?.[0]?.EmirateE) && (
            <p className="makaniCardDetailsSubAddress">
              {t("emirate")}:{" "}
              {il8n.language === "ar"
                ? makani?.[0]?.EmirateA
                : makani?.[0]?.EmirateE}
            </p>
          )}
        </div>
        {Object.keys(parcelInfo)?.length &&
        featureclassId === "5" > 0 &&
        makani?.[0]?.ParcelId ? (
          <div className="parcelWrapper">
            <p
              className="parcelNumberLabel"
              style={{
                textAlign: il8n.language === "ar" && "right",
              }}
            >
              {t("parcelId")}
            </p>

            <p
              className="parcelNumberValue"
              style={{
                textAlign: il8n.language === "ar" && "right",
              }}
            >
              {" "}
              {makani?.[0]?.ParcelId}
            </p>
          </div>
        ) : (
          <></>
        )}

        {Object.keys(parcelInfo)?.length > 0 && (
          <div className="zoningRegulationsWrapper">
            <p className="title">{t("zoningRegulations")}</p>
            <div>
              {parcelInfo?.Zoningregulations?.Regulation?.ZoneCode && (
                <p className="contactDetailsLabel">
                  {t("zoneCode")}:{" "}
                  {parcelInfo?.Zoningregulations?.Regulation?.ZoneCode}
                </p>
              )}
            </div>

            <div>
              {(il8n.language === "ar"
                ? parcelInfo?.Zoningregulations?.Regulation?.HeightRegAr
                : parcelInfo?.Zoningregulations?.Regulation?.HeightRegEn) && (
                <p className="contactDetailsLabel">
                  {t("height")}:{" "}
                  {il8n.language === "ar"
                    ? parcelInfo?.Zoningregulations?.Regulation?.HeightRegAr
                    : parcelInfo?.Zoningregulations?.Regulation?.HeightRegEn}
                </p>
              )}
            </div>

            <div>
              {(il8n.language === "ar"
                ? parcelInfo?.Zoningregulations?.Regulation.LanduseRegAr
                : parcelInfo?.Zoningregulations?.Regulation?.LanduseRegEn) && (
                <p className="contactDetailsLabel">
                  {t("landuse")}:{" "}
                  {il8n.language === "ar"
                    ? parcelInfo?.Zoningregulations?.Regulation.LanduseRegAr
                    : parcelInfo?.Zoningregulations?.Regulation?.LanduseRegEn}
                </p>
              )}
            </div>

            <div>
              {(il8n.language === "ar"
                ? parcelInfo?.Zoningregulations?.Regulation?.ParkingRegAr
                : parcelInfo?.Zoningregulations?.Regulation?.ParkingRegEn) && (
                <p className="contactDetailsLabel">
                  {t("parking")}:{" "}
                  {il8n.language === "ar"
                    ? parcelInfo?.Zoningregulations?.Regulation?.ParkingRegAr
                    : parcelInfo?.Zoningregulations?.Regulation?.ParkingRegEn}
                </p>
              )}
            </div>

            <div>
              {(il8n.language === "ar"
                ? parcelInfo?.Zoningregulations?.Regulation?.RemarksRegAr
                : parcelInfo?.Zoningregulations?.Regulation?.RemarksRegEn) && (
                <p className="contactDetailsLabel">
                  {t("remark")}:{" "}
                  {il8n.language === "ar"
                    ? parcelInfo?.Zoningregulations?.Regulation?.RemarksRegAr
                    : parcelInfo?.Zoningregulations?.Regulation?.RemarksRegEn}
                </p>
              )}
            </div>

            <div>
              {(il8n.language === "ar"
                ? parcelInfo?.Zoningregulations?.Regulation?.SetbackRegAr
                : parcelInfo?.Zoningregulations?.Regulation?.SetbackRegEn) && (
                <p className="contactDetailsLabel">
                  {t("setback")}:{" "}
                  {il8n.language === "ar"
                    ? parcelInfo?.Zoningregulations?.Regulation?.SetbackRegAr
                    : parcelInfo?.Zoningregulations?.Regulation?.SetbackRegEn}
                </p>
              )}
            </div>
          </div>
        )}
        {/*<div
          className='contactDetailsWrapper'
          style={{
            textAlign: il8n.language === 'ar' && 'right',
          }}
        >
          <p
            style={{
              textAlign: il8n.language === 'ar' && 'right',
            }}
            
          >
            {' '}
            {t('contactDetails')}
            
          </p>
          <div>
            <p>
              <span
                className='contactDetailsLabel'
                style={{
                  textAlign: il8n.language === 'ar' && 'right',
                }}
              >
                {' '}
                Telephone:{'   '}   
              </span> <p>&nbsp;{'+971 800 6636255' }<span className='arrow-link'><ArrowLink/></span></p> 
            </p>
            <p>
              <span
                className='contactDetailsLabel'
                style={{
                  textAlign: il8n.language === 'ar' && 'right',
                }}
              >
                Website:{'  '}  
      
               <p>www.malloftheemirates.com <span className='arrow-link'><ArrowLink/></span></p>

        
              </span> 
            
              </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MakaniDetails;

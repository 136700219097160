import React from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { t } = this.props;

    if (hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "20px",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#ebebeb59",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "40px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <h1
              style={{ color: "#333", fontSize: "24px", marginBottom: "20px" }}
            >
              {t("error_head")}
            </h1>
            <p style={{ color: "#666", marginBottom: "30px" }}>
              {t("error_message")}
            </p>
            <button
              onClick={() => window.location.reload()}
              style={{
                backgroundColor: "#007BFF",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "16px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#007BFF")}
            >
              {t("reload_page")}
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Export the component wrapped with translation
export default withTranslation()(ErrorBoundary);

import React, { createContext, useState, useContext } from 'react';

const DraggableContext = createContext();

export const DraggableContextProvider = ({ children }) => {
const [distanceDragged, setDistanceDragged] = useState(0);
const [draggerExtraPaddingRequired, setDraggerExtraPaddingRequired] = useState(false);

  return (
    <DraggableContext.Provider value={{
      distanceDragged, 
      setDistanceDragged,
      draggerExtraPaddingRequired,
      setDraggerExtraPaddingRequired
    }}>
      {children}
    </DraggableContext.Provider>
  );
};

// Custom hook to use the context
export const useDraggableContext = () => useContext(DraggableContext);

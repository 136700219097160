import { useState } from "react";
import { GetFloorPlans, GetRouteByType } from "../helper/api/requests.ts";
import { useAuth, useIndoorNavigation } from "../context/index.js";
import { plotRoute } from "../helper/index.js";
import loadSvg from "../helper/utils/uploadSvg.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useGetRouteByType = () => {
  const { token, getToken, setToken } = useAuth();
  const [t, i18n] = useTranslation();
  const {
    setSvgEelement,
    svgContainerRef,
    setTotalSteps,
    stepsCounter,
    setCurrentSvgIndex,
    setSourcePath,
    setDestinationPath,
    setDistanceAndFloor,
    steps,
    setSteps,
    isPathLoading,
    setStepsCounter,
    setIsPathDrawing,
    setIsPathActive,
    setIsPathLoading,
  } = useIndoorNavigation();

  const fetchRouteByType = async (
    origin,
    destination,
    travelMode,
    buildingid,
    refetchPath = true
  ) => {
    try {
      setIsPathLoading(true);
      let fetchedSteps;
      let stepData;
      let result;

      if (refetchPath) {
        setSteps(null);
        result = await GetRouteByType(
          t,
          origin,
          destination,
          travelMode,
          token,
          buildingid
        );

        if (result.isExp) {
          let newToken = await getToken();
          localStorage.setItem("token", JSON.stringify(newToken));
          setToken(newToken);
          result = await GetRouteByType(
            t,
            origin,
            destination,
            travelMode,
            newToken,
            buildingid
          );
        }

        if (result.error) {
          setIsPathActive(false);
          setIsPathDrawing(false);
          toast.error(result.error, {
            autoClose: 2000,
            position: i18n.language === "ar" ? "top-left" : "top-right",
            style: {
              textAlign: i18n.language === "ar" ? "right" : "left",
            },
          });
          // return;
          // setRouteNotFound({
          //   isError: true,
          //   type: result.error,
          // });
          // setIsPathLoading(false);
          // return;
        }

        fetchedSteps = result.steps;
        setTotalSteps(result.numberofsteps);
        setSteps(result.steps);
        const parsedStepKey = `step${1}`;
        stepData = result.steps.find(
          (step) => Object.keys(step)[0] === parsedStepKey
        );
      } else {
        const parsedStepKey = `step${stepsCounter}`;
        stepData = steps?.find(
          (step) => Object.keys(step)[0] === parsedStepKey
        );
      }

      if (stepData) {
        const stepDetails = Object.values(stepData)[0].stepDetails;

        const distance = Object.values(stepData)[0].distance;
        const directingFloor =
          Number(stepDetails[0].floorNumber) == 800
            ? 0
            : Number(stepDetails[0].floorNumber);

        setSourcePath(
          stepDetails[0].nodeTypeName === "GENERAL ELEVATOR"
            ? t("general_elevator")
            : stepDetails[0].nodeTypeName === "STAIRCASE"
            ? t("stair_case")
            : i18n.language == "ar" && stepDetails[0].nodeNameArabic
            ? stepDetails[0].nodeNameArabic
            : stepDetails[0].nodeName
        );

        setDestinationPath(
          stepDetails[stepDetails?.length - 1].nodeTypeName ===
            "GENERAL ELEVATOR"
            ? t("general_elevator")
            : stepDetails[stepDetails?.length - 1].nodeTypeName === "STAIRCASE"
            ? t("stair_case")
            : i18n.language == "ar" &&
              stepDetails[stepDetails?.length - 1].nodeNameArabic
            ? stepDetails[stepDetails?.length - 1].nodeNameArabic
            : stepDetails[stepDetails?.length - 1].nodeName
        );

        setDistanceAndFloor({ distance, floor: stepDetails[0].floorNumber });

        const svgElement = await loadSvg(Object.values(stepData)[0].svgUrl);
        const container = svgContainerRef.current;

        container.innerHTML = "";
        container.appendChild(svgElement);
        svgElement.setAttribute("width", "100%");
        svgElement.setAttribute("height", "100%");
        svgElement.setAttribute("viewBox", "0 0 1000 1000");
        setSvgEelement(svgElement);

        plotRoute(
          refetchPath ? fetchedSteps : steps,
          svgElement,
          refetchPath ? 1 : stepsCounter,
          isPathLoading
        );
        setCurrentSvgIndex(directingFloor);
      }
      setIsPathLoading(false);
    } catch (error) {
      setIsPathLoading(false);
      console.error(error);
    }
  };

  return {
    fetchRouteByType,
  };
};

export default useGetRouteByType;

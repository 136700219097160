import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

import { menuIcon, LogoMain } from "../../../assets/index.js";
import "../../../scss/common.scss";
import "../../../scss/header.scss";
import { useToggle } from "../../../context/ToggleModel.js";
import Draggable from "react-draggable";
import { useDraggableContext } from "../../../context/DraggableContext.js";
import {
  MOBILE_VIEW_DRAGGABLE_HEIGHT_PERCENT,
  MOBILE_VIEW_DRAGGER_HEIGHT,
} from "../../../helper/appConstants.js";
import AccessibleButton from "../../Accessibility/accessibility.jsx";

const ChildrenLayout = ({ HighMarginValue, topValue, sidebarRef }) => {
  const { handleMenuIcon } = useToggle();
  const [isMobileView, setIsMobileView] = useState(false);
  const [dragBound, setDragBound] = useState({ top: 0, bottom: 170 });
  const { setDistanceDragged } = useDraggableContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [t, il8n] = useTranslation();

  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  function handleUpdatesOnDrag(_e, data) {
    setDistanceDragged(data.y);
  }

  useEffect(() => {
    setIsMobileView(window.innerWidth <= 767);
    if (window.innerWidth <= 767) {
      // limiting the sidebar drag ability to half the screen size
      const screenHeight = window.innerHeight;
      const draggable_height = MOBILE_VIEW_DRAGGABLE_HEIGHT_PERCENT / 100;
      const halfScreenHeight = screenHeight * draggable_height;
      const sideBarHeight = 250;
      const dragTop = sideBarHeight - halfScreenHeight;
      if (halfScreenHeight > sideBarHeight) {
        // Setting the drag up limit only if the bottom bar height is less than half of the screen height
        if (location.pathname.includes("login")) {
          setDragBound({ top: dragTop, bottom: 128 });
        } else if (location.pathname === "/about") {
          setDragBound({
            top: dragTop,
            bottom: 206 - MOBILE_VIEW_DRAGGER_HEIGHT,
          }); // added initial dragger height (26px) to 180(derived value for existing ui) and then subtract MOBILE_VIEW_DRAGGER_HEIGHT
        } else if (location.pathname === "/settings") {
          setDragBound({
            top: dragTop,
            bottom: 190 - MOBILE_VIEW_DRAGGER_HEIGHT,
          }); // added initial dragger height (26px) to 174(derived value for existing ui) and then subtract MOBILE_VIEW_DRAGGER_HEIGHT
        } else {
          setDragBound({
            top: dragTop,
            bottom: 196 - MOBILE_VIEW_DRAGGER_HEIGHT,
          }); // added initial dragger height (26px) to 170(derived value for existing ui) and then subtract MOBILE_VIEW_DRAGGER_HEIGHT
        }
      }

      setDistanceDragged(12);
    }
  }, []);

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  return (
    <Draggable
      axis="y"
      handle=".dragger"
      disabled={isMobileView ? false : true}
      bounds={{ top: dragBound.top, bottom: dragBound.bottom }}
      onDrag={handleUpdatesOnDrag}
    >
      <div className="sideBar" ref={sidebarRef}>
        <div
          className="dragger"
          style={{
            height: MOBILE_VIEW_DRAGGER_HEIGHT,
          }}
        ></div>
        <div
          className="sideBarHeader"
          style={{ height: screenHeight <= 350 ? 125 : 191 }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleMenuIcon()}
          >
            <img
              onClick={() => handleMenuIcon()}
              className="menu__btn   main_menu_desc"
              src={menuIcon}
              alt="menuIcon"
              width="28"
              height="20"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <AccessibleButton
            className="logo"
            ariaLabel={t("Click here to go back to the home page")}
          >
            <img
              src={LogoMain}
              alt="logo"
              width="164.98"
              height="63.61"
              onClick={() => navigate("/")}
              style={{
                cursor: "pointer",
              }}
            />

            <p class="header-title">{t("geographicAddressingSystem")}</p>
          </AccessibleButton>
        </div>
        <Outlet />
      </div>
    </Draggable>
  );
};

export default ChildrenLayout;

import {
  PageHeaderTitle,
  MakaniDetails as MakaniDetailsComponent,
  MakaniCard,
} from "../../component";
import "../../scss/common.scss";
import { useTranslation } from "react-i18next";
import React from "react";

import "../../scss/disclaimer.scss";
import AccessibleButton from "../../component/Accessibility/accessibility";
import { useLocation } from "react-router-dom";
import { UseRemoveMapShape } from "../../customHooks";
import { SpeechSynthesisButton } from "../../component/index";
const Disclaimer = () => {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const [t] = useTranslation();
  UseRemoveMapShape();
  const location = useLocation();
  const textToRead = [
    t("termsandconditions"),
    t("disclaimerdesc"),
    t("disclaimerpoints1"),
    t("disclaimerpoints2"),
    t("disclaimerpoints3"),
    t("disclaimerpoints4"),
    t("disclaimerpoints5"),
    t("disclaimerpoints6"),
    t("disclaimerpoints7"),
    t("disclaimerpoints8"),
    t("disclaimerwarranty"),
    t("warrantypts1"),
    t("warrantypts2"),
    t("warrantypts3"),
    t("disclaimerdamages"),
    t("disclaimerdamagespts1"),
    t("disclaimerdamagespts2"),
    t("disclaimerdamagespts3"),
    t("disclaimerdamagespts4"),
    t("disclaimerlinks"),
    t("disclaimerlinkspts1"),
    t("disclaimerammendement"),
    t("disclaimerammendementpts1"),
  ].join(" ");

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Event listener for window resize (triggers when resizing or changing orientation)
    window.addEventListener("resize", updatescreenHeight);

    // Optional: You can also add an event listener for 'orientationchange' if needed
    window.addEventListener("orientationchange", updatescreenHeight);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;
  return (
    <div
      className="sideBarContent inner-content inner-article swipe-line"
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <div className="PageHeaderTitleWarper">
        <PageHeaderTitle
          title={t("termsandconditions")}
          fromMenu={location?.state?.fromMenu}
        />
        <SpeechSynthesisButton text={textToRead} />
      </div>

      <div className="content-cover">
        <article>
          <AccessibleButton ariaLabel={t("disclaimerdesc")}>
            <p>{t("disclaimerdesc")}</p>
          </AccessibleButton>
          <ul>
            <div className="liststyle">
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints1")}>
                  <p className="parastyle">{t("disclaimerpoints1")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints2")}>
                  <p className="parastyle">{t("disclaimerpoints2")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints3")}>
                  <p className="parastyle">{t("disclaimerpoints3")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints4")}>
                  <p className="parastyle">{t("disclaimerpoints4")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints5")}>
                  <p className="parastyle">{t("disclaimerpoints5")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints6")}>
                  <p className="parastyle">{t("disclaimerpoints6")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints7")}>
                  <p className="parastyle">{t("disclaimerpoints7")}</p>
                </AccessibleButton>
              </li>

              <li>
                <AccessibleButton ariaLabel={t("disclaimerpoints8")}>
                  <p className="parastyle">{t("disclaimerpoints8")}</p>
                </AccessibleButton>
              </li>
            </div>
          </ul>
          <div className="subtitle-disclaimer"> {t("disclaimerwarranty")}</div>
          <ul>
            <div className="liststyle">
              <li>
                <AccessibleButton ariaLabel={t("warrantypts1")}>
                  <p className="parastyle">{t("warrantypts1")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("warrantypts2")}>
                  <p className="parastyle">{t("warrantypts2")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("warrantypts3")}>
                  <p className="parastyle">{t("warrantypts3")}</p>
                </AccessibleButton>
              </li>
            </div>
          </ul>

          <div className="subtitle-disclaimer">{t("disclaimerdamages")}</div>
          <ul>
            <div className="liststyle">
              <li>
                <AccessibleButton ariaLabel={t("disclaimerdamagespts1")}>
                  <p className="parastyle">{t("disclaimerdamagespts1")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerdamagespts2")}>
                  <p className="parastyle">{t("disclaimerdamagespts2")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerdamagespts3")}>
                  <p className="parastyle">{t("disclaimerdamagespts3")}</p>
                </AccessibleButton>
              </li>
              <li>
                <AccessibleButton ariaLabel={t("disclaimerdamagespts4")}>
                  <p className="parastyle">{t("disclaimerdamagespts4")}</p>
                </AccessibleButton>
              </li>
            </div>
          </ul>

          <div className="subtitle-disclaimer">{t("disclaimerlinks")}</div>
          <ul>
            <div className="liststyle">
              <li>
                <AccessibleButton ariaLabel={t("disclaimerlinkspts1")}>
                  <p className="parastyle">{t("disclaimerlinkspts1")}</p>
                </AccessibleButton>
              </li>
            </div>
          </ul>
          <div className="subtitle-disclaimer">
            {t("disclaimerammendement")}
          </div>
          <ul>
            <div className="liststyle">
              <li>
                <AccessibleButton ariaLabel={t("disclaimerammendementpts1")}>
                  <p className="parastyle">{t("disclaimerammendementpts1")}</p>
                </AccessibleButton>
              </li>
            </div>
          </ul>
        </article>
      </div>
      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};

export default Disclaimer;
//15:02

// Import statements at the top
import { LOCALES_FOLODER } from './helper/api/constants.ts';

const DB_NAME = 'translationsDB';
const STORE_NAME = 'translations';



export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, 1);

    request.onerror = (event) => {
      console.error('Failed to open IndexedDB:', event.target.error);
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(STORE_NAME, { keyPath: 'id' });
    };
  });
};

export const checkIndexedDBTranslations = async () => {
  const translations = await getTranslations('en');
  return !!translations; // Return true if translations exist, false otherwise
};

export const updateTranslations = async (translations, lng) => {
  try {
    const db = await initDB();
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    store.put({ id: lng, data: translations });

    return true; // Return success
  } catch (error) {
    console.error('Failed to update translations in IndexedDB:', error);
    return false; // Return failure
  }
};

export const getTranslations = async (lng) => {
  try {
    const db = await initDB();
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(lng);

    const translations = await new Promise((resolve, reject) => {
      request.onsuccess = (event) => {
        const translations = event.target.result;
        resolve(translations ? translations.data : null);
      };

      request.onerror = (event) => {
        console.error('Failed to retrieve translations from IndexedDB:', event.target.error);
        reject(event.target.error);
      };
    });

    if (translations) {
      return translations;
    } else {
      // If translations are not found in IndexedDB, fetch from local file
      const response = await fetch(`${LOCALES_FOLODER}/${lng}.json?timestamp=${new Date().getTime()}`);
      const localTranslations = await response.json();
      // Optionally, you can update the IndexedDB with these translations
      await updateTranslations(localTranslations, lng);
      return localTranslations;
    }
  } catch (error) {
    console.error('Failed to retrieve translations from IndexedDB:', error);

    // If there's an error with IndexedDB, fallback to fetching from local file
    try {
      const response = await fetch(`${LOCALES_FOLODER}/${lng}.json?timestamp=${new Date().getTime()}`);
      const localTranslations = await response.json();
      // Optionally, you can update the IndexedDB with these translations
      await updateTranslations(localTranslations, lng);
      return localTranslations;
    } catch (fetchError) {
      console.error('Failed to fetch translations from local file:', fetchError);
      return null;
    }
  }
};

import { useState, useEffect } from "react";
import { FontSelector, SettingToggle } from "../../component/index";
import { useTranslation } from "react-i18next";
import AccessibleButton from "../Accessibility/accessibility";
import { ArrowRight } from "../../assets/svg/svgIcons";
import { useMapControl } from "../../context";
import { useLocation } from "react-router-dom";

const SettingDetermination = () => {
  const [t] = useTranslation();
  const { setSettingAlert } = useMapControl();
  const location = useLocation();
  const loadSetting = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };

  const saveSetting = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const [prevGrayScale, setPrevGrayScale] = useState("disable");
  const [grayScale, setGrayScale] = useState(
    loadSetting("grayScale", "disable")
  );

  // Apply the grayscale effect initially based on the setting
  useEffect(() => {
    if (grayScale === "enable") {
      document.body.classList.add("greyscale-mode");
    } else {
      document.body.classList.remove("greyscale-mode");
    }
    if (location.pathname === "/settings" && prevGrayScale != grayScale) {
      const message =
        grayScale == "enable"
          ? t("grayscale_enabled")
          : t("grayscale_disabled");

      setSettingAlert({ message: message, value: true });
      setTimeout(() => {
        setSettingAlert({ message: null, value: false });
      }, 3000);
      if (prevGrayScale == "disable") {
        setPrevGrayScale("enable");
      } else {
        setPrevGrayScale("disable");
      }
    }

    saveSetting("grayScale", grayScale);
  }, [grayScale]);

  const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;

    if (/Windows Phone/i.test(userAgent)) return "Windows Phone";
    if (/Android/i.test(userAgent)) return "Android";
    if (/iPhone|iPad|iPod/i.test(userAgent)) return "iOS";
    if (userAgent.indexOf("Windows NT") !== -1) return "Windows";
    if (userAgent.indexOf("Mac OS X") !== -1) return "MacOS";
    if (userAgent.indexOf("Linux") !== -1 || userAgent.indexOf("X11") !== -1)
      return "Linux";
    return "Unknown";
  };

  const detectMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  const redirectToSettings = () => {
    const os = getOperatingSystem();
    const isMobile = detectMobile();

    if (isMobile) {
      if (os === "Android") {
        window.location.href =
          "intent://settings#Intent;scheme=android;package=com.android.settings;end";
      } else if (os === "iOS") {
        window.location.href = "app-settings:";
      } else {
        alert(
          "Unable to detect your mobile operating system. Please refer to your device's documentation."
        );
      }
      return;
    }

    switch (os) {
      case "Windows":
        window.location.href = "ms-settings:";
        break;
      case "MacOS":
        window.location.href = "x-apple.systempreferences:";
        break;
      case "Linux":
        redirectToLinuxSettings();
        break;
      default:
        alert(
          "Unable to detect your operating system. Please refer to your system's documentation."
        );
        break;
    }
  };

  const redirectToLinuxSettings = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("gnome")) {
      window.location.href = "gnome-control-center://";
    } else if (userAgent.includes("kde") || userAgent.includes("plasma")) {
      window.location.href = "settings://";
    } else {
      alert(
        "Linux settings are platform-specific. Please refer to your distribution's documentation for instructions on accessing system settings."
      );
    }
  };

  const toggleGreyscale = () => {
    const newGrayScale = grayScale === "enable" ? "disable" : "enable";
    setGrayScale(newGrayScale);
  };

  return (
    <div
      className="collapsibleMenuItems"
      style={{
        padding: "10px 0 25px 0",
      }}
    >
      <div
        className="collapsibleMenuItemWrapper MenuItemRow  Talkback_desc"
        onClick={() => redirectToSettings()}
      >
        <AccessibleButton ariaLabel={t("Click here for talk back")}>
          <p>{t("talkback")}</p>
        </AccessibleButton>
        <div className="arrowright">
          <ArrowRight />
        </div>
      </div>

      <div className="collapsibleMenuItemWrapper MenuItemRow GrayScale_desc ">
        <AccessibleButton ariaLabel={t("Click here for grayscale")}>
          <p>{t("grayscale")}</p>
        </AccessibleButton>
        <SettingToggle
          values={{
            disable: t("disable"),
            enable: t("enable"),
          }}
          value={grayScale}
          setState={toggleGreyscale}
        />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemCol  Font_size_desc"
        style={{
          height: "",
          padding: "20px 15px",
        }}
      >
        <div className="fontSizeItem">
          <AccessibleButton ariaLabel={t("Click here for font size")}>
            <p className="font-info-description">{t("fontsize")}</p>
          </AccessibleButton>
        </div>
        <FontSelector />
      </div>
    </div>
  );
};

export default SettingDetermination;

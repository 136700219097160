import { useState } from 'react';
import { GetBuildingsForStreet } from '../helper/api/requests.ts';
import { useAuth } from '../context/auth.js';
import { useTranslation } from "react-i18next";

const useGetBuildingsForStreetFetching = () => {
  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token ,getToken,setToken} = useAuth();
  const [t,i18n] = useTranslation();

  const fetchBuildingsForStreet = async (street, community) => {
    try {
      let result = await GetBuildingsForStreet(community, street, token,i18n.language);
      if(result.isExp){
        let newToken= await getToken();
        localStorage.setItem("token", JSON.stringify(newToken));
        setToken(newToken);
        result =  await GetBuildingsForStreet(community, street, newToken,i18n.language);
      }
      

      setBuildings(result);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fetchBuildingsForStreet,
    buildings,
    loading,
  };
};

export default useGetBuildingsForStreetFetching;

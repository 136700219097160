import { responsiveFavoritePageIcon } from "../../assets/index";
import React from "react";

import {
  PageHeaderTitle,
  FavoritesRoutes,
  FavoritesPlaces,
} from "../../component/index";
import "../../scss/common.scss";
import "../../scss/favorites.scss";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useAppearance } from "../../context/AppearanceContext";
import { UseRemoveMapShape } from "../../customHooks";
import { useFavorites } from "../../context/FavouritesContext.js";
import { useMapControl } from "../../context/MapControllContext.js";
import { useDraggableContext } from "../../context/DraggableContext.js";
import { useAuth } from "../../context/auth.js";
import { useLocation, useNavigate } from "react-router-dom";

const Favorites = () => {
  const { theme } = useAppearance();
  const { distanceDragged, setDistanceDragged } = useDraggableContext();
  const [t] = useTranslation();
  const { favoritesMarker, setDisplayMarker } = useFavorites();
  UseRemoveMapShape();
  const { setCenter } = useMapControl();
  const [selectedTab, setSelectedTab] = useState("places");
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [sortToggle, setSortToggle] = useState(false);
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { token, sessionId, userId } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
    setDisplayMarker(true);

    setCenter(favoritesMarker[0]);
    setDistanceDragged(20);
  }, []);

  const location = useLocation();
  useEffect(() => {
    const calculatedHeight = 45 - distanceDragged;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    if (!sessionId || !userId) {
      return navigate("/login");
    }
  }, [sessionId, userId]);

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Event listener for window resize (triggers when resizing or changing orientation)
    window.addEventListener("resize", updatescreenHeight);

    // Optional: You can also add an event listener for 'orientationchange' if needed
    window.addEventListener("orientationchange", updatescreenHeight);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent swipe-line top-spacing "
      style={{
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <PageHeaderTitle
        title={t("myFaviourites")}
        customStyle={{
          padding: "",
        }}
        parent={location?.state?.from}
        responsiveIcon={responsiveFavoritePageIcon}
      />
      <div
        style={
          {
            // position: "relative",
          }
        }
      >
        <div className="favouriteTab">
          <div
            className="favouritePlacesTab"
            style={{
              color:
                selectedTab === "places"
                  ? theme === "dark"
                    ? "#CFD5F2"
                    : "#333C4F"
                  : "#7A7F97",

              borderColor: selectedTab === "places" && "#009D91",
            }}
            onClick={() => setSelectedTab("places")}
          >
            <p>{t("Places")}</p>
          </div>
          <div
            className="favouriteRouteTab"
            onClick={() => setSelectedTab("routes")}
            style={{
              color:
                selectedTab === "routes"
                  ? theme === "dark"
                    ? "#CFD5F2"
                    : "#333C4F"
                  : "#7A7F97",
              borderColor: selectedTab === "routes" && "#009D91",
            }}
          >
            <p>{t("routes")}</p>
          </div>
        </div>
      </div>
      <div
        className="favoritesContent"
        style={{
          overflowY: "auto",
          height: "auto", // added scroll ability for mobile screen
        }}
      >
        {selectedTab === "places" ? (
          <FavoritesPlaces theme={theme} />
        ) : (
          <FavoritesRoutes theme={theme} />
        )}
      </div>
      <div
        class="copy_right "
        style={{
          paddingTop: "1.3rem",
        }}
      >
        {t("Copyright")}
      </div>
    </div>
  );
};

export default Favorites;

//fix here -- fix the copyright -- Saabith

const BASE_URL: string =
  "https://stgmakani.gis.gov.ae/UnifiedMakaniPhase3ProxyWebService/MakaniPhase3Proxy.svc";
const LANGUAGE_FILE_URL =
  "https://stgmakani.gis.gov.ae/UnifiedMakaniPhase3ProxyWebService";
const GET_BUILDING_INFO: string = "/GetBuildingInfo";
const SMART_SEARCH: string = "/SmartSearch";
const SMART_SEARCH_RESULT = "/SmartSearchResult";
const GET_BUILDING_ADDRESS = "/GetBuildingAddress";
const GET_ALL_COMMUNITIES = "/GetAllCommunities";
const GET_STREETS_FOR_COMMUNITY = "/GetStreetsFromCommunity";
const GET_BUILDINGS_FOR_STREET = "/GetBuildingsList";
const GET_ALL_POI_CATEGORIES = "/GetAllPOICategories";
const GET_ALL_POIS_OF_CATEGORIES = "/GetAllPOIsOfCategories";
const GET_FAVORITES = "/GetFavorites";
const GET_PARCEL_OUTLINE = "/GetParcelOutline_New";
const Get_BUILDING_OUTLINE_ENTRANCE_POINTS =
  "/GetBuildingOutLine_EntrancePoints";
const Get_VIRTUAL_PLATE = "/GetVirtualPlate";
const SYNC_FAVORITES = "/SyncFavourites";
const GET_NEAREST_MAKANI = "/GetNearestMakani";
const GET_ZONING_REGULATION = "/GetZoningRegulation";
const NOTIFY_UAEPASS_USER_LOGIN = "/NotifyUAEPASSUserLogin";
const SHARE_LOCATION = "/ShareLocation";
const REPORT_MAKANI = "/ReportMakani";
const GET_LOCATION = "/GetLocation";
const GET_FLOORS = "/GetFloors";
const GET_FLOORS_PLANS = "/GetFloorPlans";
const GET_NODE_LIST = "/GetNodeList";
const GET_NODES_BY_NAME = "/GetNodesByName";
const GET_ROUTE_BY_TYPE = "/GetRouteByType";
const GET_TRAVEL_MODES = "/GetTravelModes";
const GET_INITIAL_PARAMS = "/GetInitialParams";
const GET_TOKEN = "/GetToken";
//const AUTH_BASE_URL = "https://makani.dev.hashinclu.de/php";
const package1 = require('../../../package.json')
const AUTH_BASE_URL = "https://stgmakani.gis.gov.ae/dotnet/api/api";
const LOCALES_FOLODER = `${package1.homepage}/locales`;

const WEBSITE_URL = window.location.origin;

const REDIRECT_lOGIN_URL = `${WEBSITE_URL}${package1.homepage}/profile`;
const LOGIN_REQUEST_URL = `https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=${process.env.REACT_APP_UAE_PASS_KEY}&redirect_uri=${REDIRECT_lOGIN_URL}&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&acr_values=urn:safelayer:tws:policies:authentication:level:low`;
const SHARE_URL_BASE=`${WEBSITE_URL}${package1.homepage}/map`
// const SEARCH_GOOGLE_URL='https://makani.dev.hashinclu.de/php/place.php?'
const SEARCH_GOOGLE_URL = 'https://stgmakani.gis.gov.ae/dotnet/api/api/find-place?' //After this, search text and lang params (requests.ts)
export {
  GET_BUILDING_INFO,
  BASE_URL,
  SMART_SEARCH,
  SMART_SEARCH_RESULT,
  GET_BUILDING_ADDRESS,
  GET_ALL_COMMUNITIES,
  GET_STREETS_FOR_COMMUNITY,
  GET_BUILDINGS_FOR_STREET,
  GET_ALL_POI_CATEGORIES,
  GET_ALL_POIS_OF_CATEGORIES,
  GET_FAVORITES,
  GET_PARCEL_OUTLINE,
  Get_BUILDING_OUTLINE_ENTRANCE_POINTS,
  Get_VIRTUAL_PLATE,
  SYNC_FAVORITES,
  GET_NEAREST_MAKANI,
  GET_ZONING_REGULATION,
  NOTIFY_UAEPASS_USER_LOGIN,
  SHARE_LOCATION,
  REPORT_MAKANI,
  GET_LOCATION,
  GET_FLOORS,
  GET_FLOORS_PLANS,
  GET_NODE_LIST,
  GET_NODES_BY_NAME,
  GET_ROUTE_BY_TYPE,
  GET_TRAVEL_MODES,
  GET_INITIAL_PARAMS,
  GET_TOKEN,
  LANGUAGE_FILE_URL,
  AUTH_BASE_URL,
  LOCALES_FOLODER,
  REDIRECT_lOGIN_URL,
  LOGIN_REQUEST_URL,
  SHARE_URL_BASE,
  SEARCH_GOOGLE_URL
};

import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactLoading from "react-loading";
import {
  useMapControl,
  useToggle,
  useFavorites,
  useAuth,
} from "../../context/index.js";
import {
  PageHeaderTitle,
  SearchSuggestions,
  DeleteMakaniCard,
  AddRouteModel,
  DirectioFilter,
} from "../../component/index";
import { GetSmartSearchResult } from "../../helper/api/requests.ts";
import {
  useSmartSearchFetching,
  UseRemoveMapShape,
} from "../../customHooks/index.js";
import withTour from "../../withTour";
import {
  direction,
  plus,
  directionSettings,
  flagLocation,
  loctionClose,
  checked,
} from "../../assets/index";
import { DragArrows, MapIcon, ZoomIn } from "../../assets/svg/svgIcons";
import "../../scss/common.scss";
import React from "react";

import {
  DragLine,
  DarkHeart,
  ShareIconDark,
  CarIcon,
  OnFeet,
  Hearticon,
} from "../../assets/svg/svgIcons";
import { Tooltip } from "react-tooltip";
import { getLocationName, getUserLocation } from "../../helper/index.js";

const Direction = () => {
  const [t, il8n] = useTranslation();
  const location = useLocation();
  const inputRef = useRef(null);
  const isFirstDirectionSearch = useRef(true);
  const debounceTimeoutDirection = useRef(null);
  const [isFavoriteRoute, setIsFavroiteRoute] = useState(false);
  const [favoriteValue, setFavoriteValue] = useState("");
  const [value, setValue] = useState("");
  const { token, sessionId, userId } = useAuth();
  const [entrances, setEntrances] = useState([]);
  const [deleteRouteToggle, setDeleteRouteToggle] = useState({
    isDeleteMakani: false,
    item: {
      FavName: "",
      FavType: "",
    },
  });
  const [settingRoute, setSettingRoute] = useState(false);
  const [displayRecentSearch, setDisplayRecentSearch] = useState(false);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [disaplyListSuggestions, setDisaplyListSuggestions] = useState({});
  const [entranceLoading, setEntranceLoading] = useState(true);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [detailedDirections, setDetailedDirections] = useState([]);
  const [addRouteFavorite, setAddRouteFavroite] = useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const { setIsRequireLogin } = useToggle();
  const inputRef2 = useRef(null);
  const {
    durationOnCar,
    durationOnFeet,
    distanceOnCar,
    distanceFeet,
    setStartDirection,
    directions,
    setDirections,
    updateDirection,
    directionLoading,
    currentAvoidToll,
    setCurrentAvoidToll,
    avoidToll,
    waypoints,
    setSelectedRoute,
    selectedRoute,
    routeType,
    currentLocationDirection,
    setDirectionMode,
    setWayPoints,
    locations,
    setLocations,
    directionMode,
    setSelectedDirectionMap,
    selectedDirectionMap,
  } = useMapControl();
  const { setSelectedFavoriteMarker, setDisplayMarker, routeLocations } =
    useFavorites();

  const [clickedCurrentLocation, setClickedCurrentLocation] = useState(true);
  const [isGeolocationEnabled, setIsGeolocationEnabled] = useState(null);
  const suggestionsRef = useRef();

  useEffect(() => {
    const checkGeolocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        setIsGeolocationEnabled(permissionStatus.state === "granted");

        // Listen for changes in permission status
        permissionStatus.onchange = () => {
          setIsGeolocationEnabled(permissionStatus.state === "granted");
        };
      } catch (error) {
        console.error("Error checking geolocation permissions:", error);
        setIsGeolocationEnabled(false);
      }
    };

    checkGeolocationPermission();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setDisplaySuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkLastLocation = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });

        if (permissionStatus.state === "granted") {
          const currentLocation = await getUserLocation();

          if (
            locations[locations.length - 1].position.lat ==
              currentLocation.lat &&
            locations[locations.length - 1].position.lng == currentLocation.lng
          ) {
            setClickedCurrentLocation(true);
          } else {
            setClickedCurrentLocation(false);
          }
        }
      } catch (error) {
        console.error("Error checking geolocation permissions:", error);
        setIsGeolocationEnabled(false);
      }
    };

    checkLastLocation();
  }, [locations]);

  UseRemoveMapShape();
  const { handleSmartSearch, loading, suggestions } = useSmartSearchFetching(
    setDisplaySuggestions
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (directions?.routes[0]?.legs) {
      const allSteps = directions.routes[0].legs.flatMap((leg) => leg.steps);
      setDetailedDirections(allSteps);
    }
  }, [directions]);

  useEffect(() => {
    const checkGeolocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });

        if (permissionStatus.state === "granted") {
          const newLocations = [
            {
              value:
                il8n.language === "ar"
                  ? currentLocationDirection.BuildingNameAr
                  : currentLocationDirection.BuildingNameEn,
              position: {
                lat: currentLocationDirection.lat,
                lng: currentLocationDirection.lng,
              },
              BuildingNameEn: currentLocationDirection.BuildingNameEn,
              BuildingNameAr: currentLocationDirection.BuildingNameAr,
            },
          ];
          setLocations(newLocations);
        }
      } catch (error) {
        console.error("Error checking geolocation permission:", error);
      }
    };

    checkGeolocationPermission();
  }, [currentLocationDirection, il8n.language]);

  useEffect(() => {
    const updateLocationLocal = async () => {
      setCurrentAvoidToll(avoidToll);
      setSelectedRoute(routeType);
      setSelectedFavoriteMarker(-1);
      setDisplayMarker(false);
      if (
        (location.state && location.state.BldgNameEn) ||
        (location.state &&
          location.state.position &&
          location.state.position.lat &&
          location.state.position.lng)
      ) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        let newLocations;

        if (permissionStatus.state == "granted") {
          newLocations = [
            {
              value:
                il8n.language === "ar"
                  ? currentLocationDirection.BuildingNameAr
                  : currentLocationDirection.BuildingNameEn,
              position: {
                lat: currentLocationDirection.lat,
                lng: currentLocationDirection.lng,
              },
              BuildingNameEn: currentLocationDirection.BuildingNameEn,
              BuildingNameA: currentLocationDirection.BuildingNameAr,
            },
            {
              value:
                il8n.language === "ar"
                  ? location.state.BuildingNameA
                  : location.state.BuildingNameE,
              BuildingNameEn: location.state.BuildingNameE,
              BuildingNameA: location.state.BuildingNameA,
              position: {
                lat: location?.state?.position?.lat,
                lng: location?.state?.position?.lng,
              },
            },
          ];
        } else {
          newLocations = [
            {
              value:
                il8n.language === "ar"
                  ? location.state.BuildingNameA
                  : location.state.BuildingNameE,
              BuildingNameEn: location.state.BuildingNameE,
              BuildingNameA: location.state.BuildingNameA,
              position: {
                lat: location?.state?.position?.lat,
                lng: location?.state?.position?.lng,
              },
            },
          ];
        }

        updateDirection(newLocations);
        setLocations(newLocations);
      } else if (location.state && location.state.locations) {
        setLocations(location.state.locations);
        updateDirection(location.state.locations);
      }
    };

    updateLocationLocal();
  }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state.locations &&
      location.state.locations[0].favName &&
      routeLocations.some(
        (item) => item.name === location.state.locations[0].favName
      )
    ) {
      setFavoriteValue(location.state.locations[0].favName);
      setIsFavroiteRoute(true);
    } else {
      setIsFavroiteRoute(false);
      setFavoriteValue("");
    }
  }, []);

  const handleLocationChange = (index, e) => {
    setStartDirection(false);
    setDirections(null);
    let newObj = disaplyListSuggestions;
    Object.keys(newObj).forEach((key) => {
      newObj[key] = false;
    });

    setDisaplyListSuggestions({
      ...newObj,
      [index]: true,
    });

    handleSmartSearch(e.target.value, true, true);
    let newLocation = [...locations];
    newLocation[index].value = e.target.value;
    setLocations(newLocation);
  };

  const handleEditSuggestionSelect = async (featureclassId, dgisId, index) => {
    setDisplaySuggestions(false);
    setSelectedSuggestion(index);
    setEntranceLoading(true);

    try {
      let result = await GetSmartSearchResult(featureclassId, dgisId, token);

      let newMakaniCoords = result.makani.map((item) => {
        const [lng, lat] = item.SHAPE.split(",");
        return { ...item, lng: Number(lng), lat: Number(lat) };
      });

      setEntrances(newMakaniCoords);
    } catch (error) {
      console.error("Error fetching smart search results:", error);
    } finally {
      setEntranceLoading(false);
    }
  };

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
    updateDirection(locations);
  };

  const handleEditEntranceSelect = (
    { suggestionText, buildingNameAr, buildingNameEn },
    position,
    index
  ) => {
    setDisplaySuggestions(false);
    setStartDirection(true);
    setSelectedSuggestion((prev) => {
      return -1;
    });

    setDisaplyListSuggestions({});
    let newLocation = [...locations];
    newLocation[index].value = suggestionText || "";
    newLocation[index].BuildingNameEn = buildingNameEn;
    newLocation[index].BuildingNameA = buildingNameAr;
    newLocation[index].position = {
      lat: parseFloat(position.lat),
      lng: parseFloat(position.lng),
    };
    setLocations(newLocation);
    updateDirection(newLocation);
  };

  const handleEntranceSelect = (
    { suggestionText, buildingNameAr, buildingNameEn },
    position
  ) => {
    setDisplaySuggestions(false);
    setStartDirection(true);
    setSelectedSuggestion((prev) => {
      return -1;
    });
    let newLocation = [...locations];
    newLocation.push({
      value: suggestionText,
      BuildingNameA: buildingNameAr,
      BuildingNameEn: buildingNameEn,
      position: {
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng),
      },
    });
    setValue("");

    setLocations(newLocation);
    updateDirection(newLocation);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(locations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocations(items);
    updateDirection(items);
  };

  const formatDistance = (distanceText, language) => {
    const unit =
      distanceText.includes("km") || distanceText.includes("كم")
        ? "km" || "كم"
        : "mi";
    const value = distanceText.split(" ")[0];
    let unitText = t(unit);

    return `${value} ${unitText}`;
  };
  const formatDuration = (duration) => {
    const hoursMatch = duration.match(/(\d+)\s*hrs/);
    const minutesMatch = duration.match(/(\d+)\s*mins/);

    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

    return (
      <>
        {hours > 0 && (
          <>
            <strong>{hours}</strong>{" "}
            <span
              style={{
                padding: "0 2px",
              }}
            >
              {" "}
            </span>{" "}
            {t("hrs")}
          </>
        )}
        <span
          style={{
            padding: "0 2px",
          }}
        >
          {" "}
        </span>
        {minutes > 0 && (
          <>
            <strong>{minutes}</strong>{" "}
            <span
              style={{
                padding: "0 2px",
              }}
            >
              {" "}
            </span>
            {t("mins")}
          </>
        )}
      </>
    );
  };

  const handleSuggestionSelect = async (featureclassId, dgisId, index) => {
    setEntranceLoading(true);
    setSelectedSuggestion(index);
    if (featureclassId && dgisId) {
      let result = await GetSmartSearchResult(featureclassId, dgisId, token);
      let newMakaniCoords = result.makani.map((item) => {
        const [lng, lat] = item.SHAPE.split(",");

        return { ...item, lng: Number(lng), lat: Number(lat) };
      });

      setEntrances(newMakaniCoords);
    } else {
      setEntrances([]);
    }
    setEntranceLoading(false);
  };

  const formatLatLng = ({ lat, lng }) => `${lat},${lng}`;

  const handleClick = () => {
    const originParam = formatLatLng(locations[0].position);
    const destinationParam = formatLatLng(
      locations[locations?.length - 1].position
    );
    let waypoint = [...locations]
      .splice(1, locations?.length - 2)
      .map((item) => {
        return {
          location:
            item.position.lat && item.position.lng
              ? { lng: item.position.lng, lat: item.position.lat }
              : item.value,
          stopover: true,
        };
      });

    const waypointsParam =
      waypoint?.length > 0
        ? `&waypoints=${waypoints
            .map((wp) => encodeURIComponent(formatLatLng(wp.location)))
            .join("|")}`
        : "";

    const url = `https://www.google.com/maps/dir/?api=1&origin=${originParam}&destination=${destinationParam}${waypointsParam}&hl=${
      il8n.language
    }&travelmode=${directionMode.toLowerCase()}`;
    window.open(url, "_blank");
  };

  function highlightText(htmlContent, textToHighlight) {
    const regex = new RegExp(`(${textToHighlight})`, "gi");
    return htmlContent.replace(
      regex,
      `<span class="highlight-toll-text">$1</span>`
    );
  }

  const handleFocus = () => {
    inputRef2?.current?.select();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      inputRef2.current.value = "";
      e.preventDefault(); // Prevent the default delete behavior
    }
  };

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Event listener for window resize (triggers when resizing or changing orientation)
    window.addEventListener("resize", updatescreenHeight);

    // Optional: You can also add an event listener for 'orientationchange' if needed
    window.addEventListener("orientationchange", updatescreenHeight);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent swipe-line "
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <PageHeaderTitle title={t("direction")} />
      <div className="distanceInfoWarper">
        {(durationOnCar || durationOnFeet || distanceOnCar || distanceFeet) && (
          <>
            {" "}
            <div className="direction-outer">
              {distanceOnCar && durationOnCar && (
                <div
                  className={`distanceInfo ${
                    directionMode === "DRIVING"
                      ? "distanceInfOnCar"
                      : "distanceInfOnCarNotActive"
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateDirection(locations);
                    setDirectionMode("DRIVING");
                  }}
                >
                  <CarIcon />
                  <div className="dist-otr">
                    <p
                      className="distanceInfOnCarHour"
                      onClick={() => {
                        if (directionMode !== "DRIVING") {
                          updateDirection(locations);
                          setDirectionMode("DRIVING");
                        }
                      }}
                    >
                      {formatDuration(durationOnCar)}
                    </p>

                    <p
                      className="distanceInfOnCarDistance"
                      onClick={() => {
                        if (directionMode !== "DRIVING") {
                          updateDirection(locations);
                          setDirectionMode("DRIVING");
                        }
                      }}
                    >
                      {formatDistance(distanceOnCar)}
                    </p>
                  </div>
                </div>
              )}
              {distanceFeet && durationOnFeet && (
                <div
                  className={`distanceInfo ${
                    directionMode === "WALKING"
                      ? "distanceInfoOnFeetActive"
                      : "distanceInfoOnFeet"
                  } `}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="icon_distance"
                    onClick={() => {
                      if (directionMode !== "WALKING") {
                        updateDirection(locations);
                        setDirectionMode("WALKING");
                      }
                    }}
                  >
                    <OnFeet />
                  </div>
                  <div
                    className="dist-otr"
                    onClick={() => {
                      if (directionMode !== "WALKING") {
                        updateDirection(locations);
                        setDirectionMode("WALKING");
                      }
                    }}
                  >
                    <p className="distanceInfoOnFeetHour">
                      {formatDuration(durationOnFeet)}
                    </p>
                    <p
                      onClick={() => {
                        updateDirection(locations);
                        setDirectionMode("WALKING");
                      }}
                      className="distanceInfo
                  OnFeetDistance"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {formatDistance(distanceFeet)}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              className="directionSettingsWarper"
              onClick={() => setSettingRoute(!settingRoute)}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={directionSettings} alt="directionSettings" />
            </div>
          </>
        )}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="directionPointWarper">
          {settingRoute &&
            (durationOnCar ||
              durationOnFeet ||
              distanceOnCar ||
              distanceFeet) && (
              <div
                className="dropdown-sm-otr"
                style={{
                  left: il8n.language === "ar" && 0,
                  right: il8n.language === "ar" && "unset",
                }}
              >
                <DirectioFilter
                  handleRouteChange={handleRouteChange}
                  locations={locations}
                  currentAvoidToll={currentAvoidToll}
                  setSettingRoute={setSettingRoute}
                  selectedRoute={selectedRoute}
                  checked={checked}
                  setCurrentAvoidToll={setCurrentAvoidToll}
                />
              </div>
            )}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="inputs">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {locations
                    .filter(
                      (item) =>
                        item.position.lat != null && item.position.lng != null
                    )
                    .map((locationRoute, index) => (
                      <Draggable
                        key={index}
                        draggableId={String(index)}
                        index={index}
                      >
                        {(provided) => (
                          <>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="directionInputWarper start_point"
                              style={{
                                userSelect: "none",
                                padding: 16,
                                margin: "0 0 8px 0",
                                minHeight: "50px",
                                backgroundColor: "#fff",
                                color: "#333",
                                display: "flex",
                                alignItems: "center",
                                borderTopLeftRadius: "9.5px",
                                borderTopRightRadius: "9.5px",
                                ...{
                                  ...provided.draggableProps.style,
                                  top: provided.draggableProps.style.top / 2,
                                },
                              }}
                            >
                              <img
                                src={index === 0 ? direction : flagLocation}
                                alt="flagLocation"
                                style={{ marginRight: "" }}
                              />

                              <input
                                ref={inputRef2}
                                value={locationRoute.value}
                                placeholder={t("addWaypoint")}
                                className="locationInput"
                                onChange={(e) => handleLocationChange(index, e)}
                                style={{ flexGrow: 1, marginRight: "10px" }}
                                onFocus={handleFocus}
                                onKeyDown={handleKeyDown}
                              />
                              {/* 
                    { !(location.state && location.state.locations) && index !== 0&&  locationRoute.value && (
                      <img
                        src={loctionClose}
                        alt="menuDirection "
                        onClick={() => {  
                          if (locations?.length > 1) {
                            let newLocation=locations.filter((_, findex) => findex !== index);
                            updateDirection(newLocation);
                            setLocations(newLocation);
                          }
                        }}
                        style={{ cursor: 'pointer', marginRight: '0px' }}
                      />
                 
                    )} */}
                              {!isFavoriteRoute && locationRoute.value && (
                                <img
                                  src={loctionClose}
                                  alt="menuDirection "
                                  onClick={() => {
                                    if (locations?.length > 1) {
                                      let newLocation = locations.filter(
                                        (_, findex) => findex !== index
                                      );
                                      updateDirection(newLocation);
                                      setLocations(newLocation);
                                    } else {
                                      setLocations([
                                        {
                                          value: "",
                                          position: {
                                            lat: "",
                                            lng: "",
                                          },
                                          BuildingNameEn: "",
                                          BuildingNameA: "",
                                        },
                                      ]);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}

                              {/* <img
                      src={directionMenu}
                      alt="menuDirection"
                      style={{ cursor: 'not-allowed' }}
                    /> */}
                              <div className="Swipe_destination_desc">
                                <DragArrows />
                              </div>
                            </div>

                            {disaplyListSuggestions[index] && (
                              <div
                                className="SuggestionsDirection"
                                style={{
                                  display: disaplyListSuggestions[index]
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {disaplyListSuggestions[index] && (
                                  <SearchSuggestions
                                    suggestions={suggestions}
                                    setValue={setLocations}
                                    values={locations}
                                    loading={loading}
                                    setDisplaySuggestions={
                                      setDisplaySuggestions
                                    }
                                    isDirection={true}
                                    handleSuggestionSelect={
                                      handleEditSuggestionSelect
                                    }
                                    index={index}
                                    fromDirection={true}
                                    entrances={entrances}
                                    handleEntranceSelect={
                                      handleEditEntranceSelect
                                    }
                                    selectedSuggestion={selectedSuggestion}
                                    setSelectedSuggestion={
                                      setSelectedSuggestion
                                    }
                                    entranceLoading={entranceLoading}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {locations?.length < 5 && !isFavoriteRoute && (
            <>
              <div
                className="directionInputWarper Add_destination"
                style={{
                  borderBottomLeftRadius: "9.5px",
                  borderBottomRightRadius: "9.5px",
                }}
              >
                <img
                  src={plus}
                  //  style={{
                  //   cursor:"pointer",

                  //  }}
                  alt="flagLocation"
                  // onClick={()=>setDisplayRecentSearch(!displayRecentSearch)}
                />

                <input
                  ref={inputRef}
                  value={value}
                  placeholder={t("addWaypoint")}
                  className="locationInput"
                  onChange={(e) => {
                    setSelectedSuggestion(-1);
                    if (debounceTimeoutDirection.current) {
                      clearTimeout(debounceTimeoutDirection.current);
                    }
                    setValue(e.target.value);
                    if (e.target.value) {
                      setDisplaySuggestions(true);

                      if (isFirstDirectionSearch.current) {
                        isFirstDirectionSearch.current = false;
                        handleSmartSearch(e.target.value, true);
                      } else {
                        debounceTimeoutDirection.current = setTimeout(() => {
                          handleSmartSearch(e.target.value, true);
                        }, 1500);
                      }
                    }
                  }}
                />

                {isGeolocationEnabled && !clickedCurrentLocation && (
                  <span
                    style={{
                      cursor: "pointer",
                      padding: "6px",
                      borderRadius: "7.3px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                      color: "#d1d1d1",
                    }}
                    onClick={async () => {
                      const location = await getUserLocation();
                      const locationName = await getLocationName(
                        location.lat,
                        location.lng
                      );

                      let newLocation = [...locations];
                      newLocation.push({
                        value: t("yourLocation"),
                        BuildingNameA: locationName.englishName,
                        BuildingNameEn: locationName.arabicName,
                        position: {
                          lat: parseFloat(location.lat),
                          lng: parseFloat(location.lng),
                        },
                      });
                      setLocations(newLocation);
                      updateDirection(newLocation);
                    }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={t("currentLocation")}
                    data-tooltip-place="left"
                  >
                    <Tooltip id="my-tooltip" className="custom-tooltip" />
                    <ZoomIn />
                  </span>
                )}

                <span
                  style={{
                    cursor: "pointer",
                    padding: "6px",
                    borderRadius: "7.3px",
                    background: "#7A7F97",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setSelectedDirectionMap(true);
                  }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={t("select_waypoint_tooltip")}
                  data-tooltip-place="left"
                >
                  <Tooltip
                    id="my-tooltip"
                    className="custom-tooltip"
                    // content={t("select_waypoint_tooltip")}
                    style={{
                      background: "#333c4f",
                    }}
                  />
                  <MapIcon />
                </span>
              </div>

              {/* <div
                  className='recentSearchDirection'
                  style={{
                    display: displayRecentSearch? 'block' : 'none',
                  }}
                >
                  <RecentSearch />
                </div> */}
              {displaySuggestions && (
                <div
                  ref={suggestionsRef}
                  className="SuggestionsDirection"
                  style={{
                    display: displaySuggestions ? "block" : "none",
                  }}
                >
                  <SearchSuggestions
                    suggestions={suggestions}
                    setValue={setLocations}
                    values={locations}
                    loading={loading}
                    setDisplaySuggestions={setDisplaySuggestions}
                    isDirection={true}
                    handleSuggestionSelect={handleSuggestionSelect}
                    entrances={entrances}
                    selectedSuggestion={selectedSuggestion}
                    handleEntranceSelect={handleEntranceSelect}
                    setSelectedSuggestion={setSelectedSuggestion}
                    entranceLoading={entranceLoading}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="LoadingWraper">
          {directionLoading && (
            <ReactLoading type={"spinningBubbles"} color="#333c4f" />
          )}
        </div>
      </form>
      {addRouteFavorite && (
        <AddRouteModel
          setAddRouteFavroite={setAddRouteFavroite}
          locations={locations}
          distance={directionMode === "DRIVING" ? distanceOnCar : distanceFeet}
          setValue={setFavoriteValue}
          value={favoriteValue}
          setAddSuccessfult={setIsFavroiteRoute}
        />
      )}
      {deleteRouteToggle.isDeleteMakani && (
        <DeleteMakaniCard
          title={t("Delete_Route")}
          description={t("Delete_Route_Description")}
          setDeleteMakaniToggle={setDeleteRouteToggle}
          item={{
            FavName: deleteRouteToggle.item.FavName,
            FavType: deleteRouteToggle.item.FavType,
          }}
          FromRoute={true}
          onDelete={() => setIsFavroiteRoute(false)} // Pass a function
        />
      )}

      <div className="directionBtns">
        <div
          className="directionBtn Add_favourite_desc"
          onClick={() => {
            if (directions) {
              if (!sessionId || !userId) {
                setIsRequireLogin(true);
              } else {
                if (!isFavoriteRoute) {
                  setAddRouteFavroite(true);
                } else if (isFavoriteRoute) {
                  setDeleteRouteToggle({
                    isDeleteMakani: true,
                    item: {
                      FavName: favoriteValue,
                      FavType: "ROUTE_LOCATION",
                    },
                  });
                  // setIsFavroiteRoute(false);
                }
              }
            }
          }}
        >
          {isFavoriteRoute ? <Hearticon /> : <DarkHeart />}
        </div>

        <div
          style={{
            background: locations[0] && "#4e5972",
            color: "#FFFF",
          }}
          className="directionBtn directionBtnStart Start_navigation "
          onClick={() => {
            if (directions) {
              handleClick();
            }
          }}
        >
          <p>{t("start")}</p>
        </div>
      </div>
      {locations?.length >= 2 &&
        detailedDirections &&
        detailedDirections?.length > 0 && (
          <div className="route-directions-container">
            {detailedDirections?.length > 0 && (
              <div className="route-directions-list">
                {detailedDirections.map((direction, index) => (
                  <div key={index}>
                    <div className="route-direction-item">
                      <div className="route-direction-icon-container">
                        {directionMode === "WALKING" ? <OnFeet /> : <CarIcon />}
                      </div>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: highlightText(
                            direction.instructions,
                            "Toll road"
                          ),
                        }}
                        className="route-direction-html"
                      />
                      <div className="route-direction-distance-duration">
                        <p>{direction.distance.text}</p>
                        <p>{direction.duration.text}</p>
                      </div>
                    </div>
                    {index < detailedDirections?.length - 1 && (
                      <div className="route-direction-divider" />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};

export default withTour(Direction, "direction");

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import React from "react";
import Slider from "react-slick";
import "./style.css";
import {
  calculateDistanceBetweenCoordinates,
  updateRecentSearch,
} from "../../helper/index.js";
import isLocationEnabled from "../../helper/utils/userLocationStatus.js";
import { useMapControl } from "../../context/MapControllContext.js";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const SearchSuggestions = ({
  setDisplaySuggestions,
  suggestions = [],
  loading,
  isDirection,
  handleSuggestionSelect,
  index,
  fromDirection,
  entrances,
  selectedSuggestion,
  handleEntranceSelect,
  setSelectedSuggestion,
  entranceLoading,
  setSearch,
}) => {
  const [t, il8n] = useTranslation();
  const navigate = useNavigate();
  const { measurementUnit } = useMapControl();
  const handleSearch = (suggestion) => {
    suggestion.ln = il8n.language;
    updateRecentSearch(suggestion);
    setDisplaySuggestions(false);
    navigate("/searchResult", {
      state: {
        dgis: suggestion.dgis_id,
        featureclassId: suggestion.featureclass_id,
        searchValue: suggestion.text,
        from: "/",
        suggestion: suggestion,
        emirate: suggestion.emirate,
      },
    });
    setSearch("");
  };

  const [isLocation, setIsLocation] = useState(null);
  const { currentLocationDirection } = useMapControl();
  useEffect(() => {
    // NOTE: Distance on user location
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    if (isDirection) {
      setSelectedSuggestion(-1);
    }

    checkLocation();
  }, []);

  function convertToDecimal(numberString) {
    if (/^\.\d+$/.test(numberString)) {
      return "0" + numberString;
    }

    return numberString;
  }
  function reverseAndConcat(input) {
    return input.split(" ").reverse().join(" ");
  }

  return (
    <>
      <div className="searchSuggestions">
        <div className="recentSearchesWarper">
          <div className="recentSearchers">
            {loading && (
              <div className="loadingSkeletons">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className="recentSearch"
                    style={{ paddingTop: "23px" }}
                  >
                    <Skeleton height={10} width={`45%`} />
                    <Skeleton
                      height={10}
                      width={`40%`}
                      style={{ marginTop: "3px" }}
                    />
                    <div
                      className="pointOfInternetType"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      <Skeleton circle={true} height={10} width={10} />
                      <Skeleton
                        height={10}
                        width={90}
                        style={{ marginLeft: "3px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!loading &&
              suggestions?.length > 0 &&
              suggestions.map((suggestion, suggestionIndex) => {
                const distance = suggestion.location
                  ? calculateDistanceBetweenCoordinates(
                      {
                        lat: Number(currentLocationDirection.lat),
                        lng: Number(currentLocationDirection.lng),
                      },
                      {
                        lat: Number(suggestion?.location?.lat),
                        lng: Number(suggestion?.location?.lng),
                      },
                      measurementUnit === "kilometers" ? "km" : "mile"
                    )
                  : null;

                if (
                  isDirection &&
                  suggestionIndex === selectedSuggestion &&
                  !entranceLoading &&
                  entrances?.length === 0
                ) {
                  let position = {
                    lat: suggestion?.location?.lat,
                    lng: suggestion?.location?.lng,
                  };

                  handleEntranceSelect(
                    {
                      suggestionText: suggestion.text,
                      buildingNameEn: "",
                      buildingNameAr: "",
                    },
                    position,
                    index
                  );
                  setSelectedSuggestion(-1);
                }

                return (
                  <div
                    key={suggestion.id}
                    className="recentSearch"
                    style={{ paddingTop: "23px", cursor: "pointer" }}
                    onClick={() => {
                      if (!isDirection) {
                        handleSearch(suggestion);
                      } else {
                        handleSuggestionSelect(
                          suggestion.featureclass_id,
                          suggestion.dgis_id,
                          suggestionIndex
                        );
                      }
                    }}
                  >
                    <p className="recentSearchTitle">{suggestion.text}</p>
                    {/* NOTE: Removed ... */}
                    <div className="recentSearchLocation">
                      <p className="recentSearchAddress">
                        {suggestion.emirate}
                      </p>
                      {suggestion.distance && (
                        // NOTE: Distance on user location
                        <p className="recentSearchDestination">
                          {!isLocation
                            ? "--"
                            : convertToDecimal(
                                parseDistanceWithUnit(
                                  measurementUnit,
                                  suggestion.distance
                                )
                              )}{" "}
                          {measurementUnit == "kilometers"
                            ? t("km")
                            : t("miles")}
                        </p>
                      )}
                      {suggestion.location && distance && (
                        // NOTE: Distance on user location
                        <p className="recentSearchDestination">
                          {!isLocation
                            ? "--"
                            : convertToDecimal(
                                parseDistanceWithUnit(measurementUnit, distance)
                              )}{" "}
                          {measurementUnit == "kilometers"
                            ? t("km")
                            : t("miles")}
                        </p>
                      )}
                    </div>
                    <div className="pointOfInternetType">
                      <p className="pointOfInternetTypeTitle">
                        {suggestion.category.split("_").join(" ")}
                      </p>
                    </div>

                    <div className="entrances">
                      {suggestionIndex === selectedSuggestion &&
                        entranceLoading && (
                          <div className="horizontal-otr">
                            <div>
                              <Skeleton
                                height={30}
                                width={100}
                                style={{
                                  borderRaduis: "10px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      {/* {isDirection &&
                        suggestionIndex === selectedSuggestion &&
                        !entranceLoading &&
                        entrances?.length === 0 && (
                          <span className="NoEntrancesFound">
                            {" "}
                            {t("noEntranceFound")}
                          </span>
                        )} */}
                      {isDirection &&
                        suggestionIndex === selectedSuggestion &&
                        !entranceLoading &&
                        entrances?.length <= 2 &&
                        entrances?.map((entrance, i) => {
                          return (
                            <div className="horizontal-otr">
                              <div
                                key={i}
                                className="entrance"
                                onClick={() => {
                                  let position = {
                                    lat: entrance.lat,
                                    lng: entrance.lng,
                                  };
                                  handleEntranceSelect(
                                    {
                                      suggestionText: suggestion.text,
                                      buildingNameEn:
                                        entrance.BldgNameEn || suggestion.text,
                                      buildingNameAr:
                                        entrance.BldgNameAr || suggestion.text,
                                    },
                                    position,
                                    index
                                  );
                                  setSelectedSuggestion(-1);
                                }}
                              >
                                {t("entrance")} {i + 1}
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    {isDirection &&
                      suggestionIndex === selectedSuggestion &&
                      !entranceLoading &&
                      entrances?.length > 2 && (
                        <div className="slider-container horizontal-slide">
                          <Slider {...sliderSettings}>
                            {entrances?.map((entrance, i) => {
                              return (
                                <div className="horizontal-otr">
                                  <div
                                    key={i}
                                    className="entrance"
                                    onClick={() => {
                                      let position = {
                                        lat: entrance.lat,
                                        lng: entrance.lng,
                                      };
                                      handleEntranceSelect(
                                        {
                                          suggestionText: suggestion.text,
                                          buildingNameEn:
                                            entrance.BldgNameEn ||
                                            suggestion.text,
                                          buildingNameAr:
                                            entrance.BldgNameAr ||
                                            suggestion.text,
                                        },
                                        position,
                                        index
                                      );
                                    }}
                                  >
                                    Entrance {i + 1}
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      )}
                  </div>
                );
              })}

            {!loading && suggestions?.length === 0 && !fromDirection && (
              <p style={{ padding: "10px 0" }}>{t("noResult")}</p>
            )}
            {!loading && suggestions?.length > 3 && fromDirection && (
              <p style={{ padding: "10px 0" }}>{t("noResult")}</p>
            )}
            {!loading && suggestions?.length < 3 && fromDirection && (
              <p className="information-text" style={{ padding: "10px 0" }}>
                {t("TypeValidCharacter")}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchSuggestions;

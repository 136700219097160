import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PageHeaderTitle } from "../../component";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { UseRemoveMapShape } from "../../customHooks";
import {
  convertToEasternArabicNumerals,
  convertToWesternArabicNumerals,
} from "../../helper/index.js";
import { GetZoningRegulation } from "../../helper/api/requests.ts";
import { useAuth } from "../../context/auth.js";
import React from "react";

const SearchByLandNumber = () => {
  UseRemoveMapShape();
  const inputRef = useRef(null);
  const { token } = useAuth();

  const [t, i18n] = useTranslation();
  const [parcelNumber, setParcelNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState({
    showError: false,
    error: "",
  });

  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const navigate = useNavigate();
  const regex = /^\d+$/;

  const handleSearch = async (parcelNumber) => {
    setLoading(true);
    if (parcelNumber) {
      if (!regex.test(convertToWesternArabicNumerals(parcelNumber))) {
        setShowError({
          show: true,
          error: t("parcel_id_is_invalid"),
        });
        setLoading(false);
        return;
      }
      try {
        const landInfo = await GetZoningRegulation(
          t,
          convertToWesternArabicNumerals(parcelNumber),
          token
        );
        setLoading(false);

        if (landInfo.error) {
          setShowError({
            show: true,
            // error:t('parcel_id_is_invalid')
            error: landInfo.error,
          });
          setLoading(false);
        } else if (
          landInfo.Message &&
          landInfo.Message === "Parcel Id is invalid."
        ) {
          setShowError({
            show: true,
            error: t("parcel_id_is_invalid"),
          });
          setLoading(false);
        } else {
          navigate("/searchResult", {
            state: {
              dgis: convertToWesternArabicNumerals(parcelNumber),
              featureclassId: "5",
              from: "/searchByLandNumber",
              searchValue: convertToWesternArabicNumerals(parcelNumber),
            },
          });
        }
      } catch (error) {
        console.error("Error fetching land information:", error);
        setShowError({
          show: true,
          error: t("parcel_id_is_invalid"),
        });
      }
    } else {
      setShowError({
        show: true,
        error: t("parcel_number_required"),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleSearch(parcelNumber);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [parcelNumber]);

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent swipe-line "
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <div style={{ height: "87%" }}>
        <PageHeaderTitle title={t("findMyLandNumber")} />
        <div className="searchByLandWarper">
          <input
            value={parcelNumber}
            ref={inputRef}
            className="parcelInput"
            style={{
              height: "2.5rem",
            }}
            onChange={(e) => {
              setShowError({
                show: false,
                error: "",
              });
              const inputValue = e.target.value;
              if (i18n.language === "ar") {
                setParcelNumber(convertToEasternArabicNumerals(inputValue));
              } else {
                setParcelNumber(inputValue);
              }
            }}
          />
        </div>
        {loading && <div className="loader"> {t("loading")}</div>}
        {showError.show && (
          <p
            style={{
              fontSize: "14px",
              color: "red",
              margin: "10px 0",
            }}
          >
            {showError.error}
          </p>
        )}
        <div className="searchByLandBtnWarper">
          <div
            className={
              parcelNumber ? "searchByBuildingBtnActive" : "searchByBuildingBtn"
            }
            onClick={() => handleSearch(parcelNumber)}
          >
            {t("search")}
          </div>
        </div>
      </div>
      <div class="copy_right copyright-property">{t("Copyright")}</div>
    </div>
  );
};
export default SearchByLandNumber;

import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { MapContainer, Menu, LoginModel } from "../../index.js";
import ShareModel from "../../models/shareModel";
import AllowAccessModel from "../../models/allowAccess";
import MapLayer from "../../models/mapLayer";
import SettingsAlert from "../../models/settingsAlert";
import DownloadApp from "../../downloadApp";

import Slider from "react-slick";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { loadSvg } from "../../../helper";
import { GetFloorPlans } from "../../../helper/api/requests.ts";

import {
  RightArrow,
  LeftArrow,
  ManWalk,
  PlusIcon,
  MinusIcon,
  StartIconPopup,
  DestinationIconPopup,
} from "../../../assets/svg/svgIcons";
import threedImage from "../../../assets/images/threedImage.svg";

import { useAppearance, useIndoorNavigation, useAuth } from "../../../context";
import { useToggle } from "../../../context/ToggleModel";
import { usePOICategories } from "../../../context/POICategoriesContext";
import { useMapControl } from "../../../context/MapControllContext";
import { useGetFloorPlansFetching } from "../../../customHooks";

import "./style.css";
import withTour from "../../../withTour";
import { LogoMain, menuIconResponsive } from "../../../assets/index.js";

const ParentLayout = ({
  displaySuggestions,
  HighPOI,
  currentSlide,
  sliderRef,
  setCurrentSlide,
  share,
  setShare,
  sidebarRef,
  setSearch,
}) => {
  const location = useLocation();
  const [t, il8n] = useTranslation();

  // Context hooks
  const {
    makaniCoords,
    settingsAlert,
    shareFromMakaniCard,
    setShareFromMakaniCard,
  } = useMapControl();
  const { loading } = useGetFloorPlansFetching();
  const { fontSize, theme, showPopup } = useAppearance();
  const { POICategories, GetAllPOIsOfCategories } = usePOICategories();
  const { token, getToken, setToken } = useAuth();

  // State hooks
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [initialViewBox, setInitialViewBox] = useState([0, 0, 1000, 1000]);
  const [viewBox, setViewBox] = useState([0, 0, 1000, 1000]);
  const [perspective, setPerspective] = useState(false);
  const [isFloorEditing, setIsFloorEditing] = useState(false);
  const [isSvgCardOpen, setIsSvgCardOpen] = useState(false);
  const [clickedText, setClickedText] = useState(null);
  const [clickedTextPosition, setClickedTextPosition] = useState({
    x: null,
    y: null,
  });

  // Indoor navigation context hooks
  const {
    svgContainerRef,
    setSvgEelement,
    currentSvgIndex,
    setCurrentSvgIndex,
    isPathActive,
    isPathDrawing,
    setClickedNode,
    showSvg,
    setShowSvg,
    sourcePath,
    destinationPath,
    setIsPathLoading,
    setTotalSteps,
    setStepsCounter,
    setSourcePath,
    setDestinationPath,
    setIsPathDrawing,
    setIsPathActive,
    distanceAndFloor,
    setDistanceAndFloor,
    floors,
    buildingId,
    isPathLoading,
  } = useIndoorNavigation();

  const {
    mapLayerToggle,
    menuToggle,
    isRequireLogin,
    allowAccessLocation,
    handleMenuIcon,
  } = useToggle();

  // SVG and floor management effects
  useEffect(() => {
    setIsSvgCardOpen(false);
    setClickedText(null);
    location.pathname === "/indoorNavigation" &&
    location?.state?.buildingid != null
      ? setShowSvg(true)
      : setShowSvg(false);
  }, [location.pathname]);

  useEffect(() => {
    const svgContainerElement = svgContainerRef.current;

    if (svgContainerElement) {
      svgContainerElement.addEventListener("click", handleSvgClick);
    }

    return () => {
      if (svgContainerElement) {
        svgContainerElement.removeEventListener("click", handleSvgClick);
      }
    };
  }, [svgContainerRef.current]);

  const handleSvgClick = (event) => {
    const svgContainerElement = svgContainerRef.current;
    if (!svgContainerElement) return;

    const rect = svgContainerElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const svgElement = svgContainerElement.querySelector("svg");
    if (!svgElement) return;

    const element = document.elementFromPoint(event.clientX, event.clientY);
    if (element && element.closest("svg") === svgElement) {
      const textElement = element.closest("text");
      if (textElement) {
        const textContent = textElement.textContent;

        // Get the background color of the clicked text element
        const computedStyle = window.getComputedStyle(textElement);
        const backgroundColor =
          computedStyle.fill || computedStyle.backgroundColor;

        if (backgroundColor === "rgb(52, 52, 52)") {
          const textRect = textElement.getBoundingClientRect();
          // const textX = textRect.left - rect.left;
          // const textY = textRect.top - rect.top;
          const textX = event.clientX;
          const textY = event.clientY;

          setClickedText(textContent);
          setClickedTextPosition({ x: textX, y: textY });
          setIsSvgCardOpen(true);
        }
      }
    }
  };

  // Slider settings
  const settings = {
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // POI handling function
  const handleDisplayPoi = (categoryId, position, MapIcon, color) => {
    GetAllPOIsOfCategories(categoryId, position, MapIcon, color);
  };

  // Zoom controls
  const zoomIn = () => adjustZoom(0.9);
  const zoomOut = () => adjustZoom(1.1);

  const togglePerspective = () => {
    setPerspective(!perspective);
  };

  const adjustZoom = (scaleFactor) => {
    const [x, y, width, height] = viewBox;
    const newWidth = width * scaleFactor;
    const newHeight = height * scaleFactor;
    const newX = x - (newWidth - width) / 2;
    const newY = y - (newHeight - height) / 2;

    setViewBox([newX, newY, newWidth, newHeight]);
    setInitialViewBox([newX, newY, newWidth, newHeight]);
  };

  // Dragging functionality for SVG
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartPoint({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const dx = event.clientX - startPoint.x;
    const dy = event.clientY - startPoint.y;

    const [x, y, width, height] = initialViewBox;
    const scaleX = width / svgContainerRef.current.offsetWidth;
    const scaleY = height / svgContainerRef.current.offsetHeight;

    const newX = x - dx * scaleX;
    const newY = y - dy * scaleY;

    setViewBox([newX, newY, width, height]);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setInitialViewBox(viewBox);
  };

  const [isZooming, setIsZooming] = React.useState();
  const [initialPinchDistance, setInitialPinchDistance] = React.useState();

  // Touch event handlers
  const handleTouchStart = (event) => {
    if (event.touches.length === 2) {
      setIsZooming(true);
      const [touch1, touch2] = event.touches;
      const initialDistance = getDistance(touch1, touch2);
      setInitialPinchDistance(initialDistance);
    } else if (event.touches.length === 1) {
      // Handle drag start
      setIsDragging(true);
      setStartPoint({
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      });
    }
  };

  const handleTouchMove = (event) => {
    if (event.touches.length === 2 && isZooming) {
      const [touch1, touch2] = event.touches;
      const currentDistance = getDistance(touch1, touch2);
      let scaleFactor = initialPinchDistance / currentDistance; // Reverse the ratio

      adjustZoom(scaleFactor);
    } else if (event.touches.length === 1 && isDragging) {
      // Handle drag move
      const dx = event.touches[0].clientX - startPoint.x;
      const dy = event.touches[0].clientY - startPoint.y;

      const [x, y, width, height] = initialViewBox;
      const scaleX = width / svgContainerRef.current.offsetWidth;
      const scaleY = height / svgContainerRef.current.offsetHeight;

      const newX = x - dx * scaleX;
      const newY = y - dy * scaleY;

      setViewBox([newX, newY, width, height]);
    }
  };

  const handleTouchEnd = () => {
    setIsZooming(false);
    setIsDragging(false);
    setInitialViewBox(viewBox);
  };

  // Utility function to calculate the distance between two touches
  const getDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Prevent zoom gesture on mobile
  const preventTouchZoom = (event) => {
    if (event.scale !== 1) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("gesturestart", preventTouchZoom);
    window.addEventListener("gesturechange", preventTouchZoom);
    window.addEventListener("gestureend", preventTouchZoom);

    return () => {
      window.removeEventListener("gesturestart", preventTouchZoom);
      window.removeEventListener("gesturechange", preventTouchZoom);
      window.removeEventListener("gestureend", preventTouchZoom);
    };
  }, []);

  // Apply viewBox changes to SVG
  useEffect(() => {
    if (svgContainerRef.current) {
      const svgElement = svgContainerRef.current.querySelector("svg");
      if (svgElement) {
        svgElement.setAttribute(
          "viewBox",
          `${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`
        );
      }
    }
  }, [viewBox, svgContainerRef]);

  // Zoom on mouse wheel event
  const handleWheel = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
    setIsSvgCardOpen(false);
    const scaleFactor = event.deltaY > 0 ? 1.1 : 0.9;
    adjustZoom(scaleFactor);
  };

  const resetPathData = () => {
    setSourcePath(null);
    setDestinationPath(null);

    setIsPathDrawing(false);
    setIsPathActive(false);
    setIsPathLoading(false);

    setTotalSteps(0);
    setStepsCounter(1);
    setDistanceAndFloor(null);

    setClickedNode(null);
    setCurrentSvgIndex(0);
  };

  // Floor change handler
  const handleFloorChange = async (type) => {
    if (isPathActive) {
      resetPathData();
    }

    let newIndex = Number(currentSvgIndex);
    setIsPathLoading(true);

    if (type === "DOWN") {
      newIndex = Math.max(newIndex - 1, 0);
    } else if (type === "UP") {
      newIndex += 1;
    }

    setCurrentSvgIndex(newIndex);
    const currentFloor = newIndex === 0 ? "800" : String(newIndex);
    const floorRef = floors?.find(
      (floor) => floor?.floor === currentFloor
    ).floorRef;

    let result = await fetchFloorPlans(floorRef);

    if (!result?.floor) {
      handleFloorNotFound();
      return;
    }

    const floorData = result?.floor.find((item) => item.floor === currentFloor);
    const url = floorData?.svgUrl;

    if (url) {
      loadAndDisplaySvg(url);
    }

    setIsPathLoading(false);
  };

  const fetchFloorPlans = async (floorRef) => {
    let result = await GetFloorPlans(t, token, buildingId, floorRef);

    if (result.isExp) {
      const newToken = await refreshAuthToken();
      result = await GetFloorPlans(t, newToken, buildingId, floorRef);
    }

    return result;
  };

  const refreshAuthToken = async () => {
    const newToken = await getToken();
    localStorage.setItem("token", JSON.stringify(newToken));
    setToken(newToken);
    return newToken;
  };

  const loadAndDisplaySvg = async (url) => {
    const svgElement = await loadSvg(url);
    const container = svgContainerRef.current;
    container.innerHTML = "";
    container.appendChild(svgElement);
    svgElement.setAttribute("width", "100%");
    svgElement.setAttribute("height", "100%");
    svgElement.setAttribute("viewBox", "0 0 1000 1000");
    setSvgEelement(svgElement);
  };

  const handleFloorNotFound = () => {
    setCurrentSvgIndex(0);
    toast.error(t("floor_not_found"), {
      autoClose: 2000,
      position: il8n.language === "ar" ? "top-left" : "top-right",
      style: { textAlign: il8n.language === "ar" ? "right" : "left" },
    });
  };

  // Move "Others" POI to the end of the POI Categories array
  const moveOthersPOIToLast = (POICategories) => {
    const categoriesCopy = [...POICategories];
    const othersPOI = categoriesCopy.splice(18, 1)[0];
    categoriesCopy.push(othersPOI);
    return categoriesCopy;
  };

  const updatedPOICategories = moveOthersPOIToLast(POICategories);

  const NodeSelector = ({
    position,
    clickedText,
    setIsSvgCardOpen,
    setClickedNode,
    t,
  }) => {
    useEffect(() => {
      const handleMouseUp = (event) => {
        if (!event.target.closest(".clicked-text-parent")) {
          setIsSvgCardOpen(false);
        }
      };

      document.addEventListener("mouseup", handleMouseUp);

      return () => {
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [setIsSvgCardOpen]);

    if (!position) return null;

    const isMobile = window.innerWidth <= 768;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    let adjustedTop = isMobile ? position.y - 10 : position.y - 10;
    let adjustedLeft = isMobile ? position.x + 85 : position.x + 145;

    if (adjustedLeft + 140 > screenWidth) {
      adjustedLeft = screenWidth - 140 - 10;
    }

    if (position.y < 50) {
      adjustedTop = 200; // Adjust for the height of the card
    }

    if (adjustedLeft < 0) {
      adjustedLeft = 10;
    }

    return (
      <div
        className={`clicked-text-parent ${adjustedLeft <= 0 && "left-edge"} ${
          adjustedLeft + 140 >= screenWidth && "right-edge"
        }`}
        data-position={adjustedLeft + 180 > screenWidth ? "right" : "left"}
        style={{
          position: "absolute",
          top: `${adjustedTop}px`,
          left: `${adjustedLeft}px`,
          transform: "translate(-50%, -100%)",
          zIndex: 1000,
        }}
      >
        <div className="clicked-text">{clickedText}</div>
        <div
          className={`  ${
            adjustedLeft <= 0 && "clicked-text-button-div-left"
          } ${adjustedLeft + 140 && "clicked-text-button-div-right"}`}
        >
          <button
            onClick={() => {
              setClickedNode({ input: "origin", value: clickedText });
              setIsSvgCardOpen(false);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                justifyItems: "center",
                padding: "3px",
                gap: "8px",
              }}
            >
              <span>
                <StartIconPopup />
              </span>
              {t("start")}
            </div>
          </button>
          <button
            onClick={() => {
              setClickedNode({ input: "destination", value: clickedText });
              setIsSvgCardOpen(false);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                justifyItems: "center",
                gap: "8px",
                padding: "3px",
              }}
            >
              <span>
                <StartIconPopup />
              </span>
              {t("finish")}
            </div>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="rootContainer" data-size={fontSize} data-theme={theme}>
      {isSvgCardOpen && (
        <NodeSelector
          position={clickedTextPosition}
          clickedText={clickedText}
          setIsSvgCardOpen={setIsSvgCardOpen}
          setClickedNode={setClickedNode}
          t={t}
        />
      )}

      {/* Map overlay */}
      <div
        className="overlay"
        style={{
          opacity: menuToggle || allowAccessLocation ? 1 : 0,
          visibility: menuToggle || allowAccessLocation ? "visible" : "hidden",
        }}
      ></div>

      {/* Menu and outlet */}
      <Menu theme={theme} />
      <Outlet />

      <div className="mapContainer">
        {/* Map layel and access model modals */}
        {mapLayerToggle && <MapLayer />}
        {allowAccessLocation && <AllowAccessModel />}

        {showSvg ? (
          <div>
            {loading ? (
              <div className="loading-overlay">
                <ReactLoading type={"balls"} color="#009D91" />
              </div>
            ) : (
              <>
                <div className="indoor-navigation-container-div">
                  {isPathActive &&
                    isPathDrawing &&
                    sourcePath &&
                    destinationPath && (
                      <div className="indoor-direction-card">
                        <ManWalk />

                        <div className="text-container">
                          <div className="from-to">
                            {t("from")}{" "}
                            <span
                              style={{
                                fontWeight: "bolder",
                                marginLeft:
                                  il8n.language === "ar" ? "0px" : "1px",
                                marginRight:
                                  il8n.language === "ar" ? "1px" : "0px",
                              }}
                            >
                              {sourcePath?.length > 20
                                ? sourcePath.substring(0, 20) + "..."
                                : sourcePath}
                            </span>
                          </div>
                          <div className="destination">
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="to-text"
                            >
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "center",
                                  alignItems: "baseline",
                                }}
                              >
                                {t("to")}{" "}
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    marginLeft:
                                      il8n.language === "ar" ? "0px" : "18px",
                                    marginRight:
                                      il8n.language === "ar" ? "8px" : "0px",
                                  }}
                                >
                                  {destinationPath?.length > 20
                                    ? destinationPath.substring(0, 20) + "..."
                                    : destinationPath}
                                </span>
                              </span>
                              {distanceAndFloor?.distance && (
                                <span style={{ marginTop: "8px" }}>
                                  {t("distance")} :{" "}
                                  {Math.trunc(distanceAndFloor?.distance) +
                                    (il8n.language === "ar" ? " م" : " m")}
                                </span>
                              )}
                              {distanceAndFloor?.floor && (
                                <span style={{ marginTop: "8px" }}>
                                  {t("floor")} :{" "}
                                  {distanceAndFloor?.floor === "800"
                                    ? t("ground_floor")
                                    : distanceAndFloor?.floor}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  <div
                    ref={svgContainerRef}
                    onWheel={handleWheel}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    viewBox={`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`}
                    style={{
                      position:
                        window.innerWidth <= 600 ? "static" : "absolute",
                      left: "50px",
                      right: "50px",
                      width: "100%",
                      height: "100%",
                      transform: perspective
                        ? `perspective(1000px) rotateX(45deg) scale(1.1)`
                        : "none",
                      cursor: isDragging ? "grabbing" : "grab",
                    }}
                    className="svgBackground"
                  />
                  {isPathLoading && (
                    <div className="loading-overlay">
                      <ReactLoading type={"balls"} color="#009D91" />
                    </div>
                  )}
                  <div
                    className={
                      il8n.language === "ar"
                        ? "zoom-controls-svg-ar"
                        : "zoom-controls-svg"
                    }
                  >
                    <button className="zoom-btn-svg" onClick={zoomIn}>
                      <PlusIcon />
                    </button>
                    <button className="zoom-btn-svg" onClick={zoomOut}>
                      <MinusIcon />
                    </button>
                    <button
                      className="zoom-btn-svg"
                      onClick={togglePerspective}
                    >
                      <img
                        src={threedImage}
                        alt="Perspective"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </button>
                  </div>

                  {/* Floor selector start */}
                  {location.pathname === "/indoorNavigation" &&
                    !isPathDrawing && (
                      <div className="select-floor indoor-broup-btn">
                        <div className="navi-btn start-btn btn__grp">
                          <button
                            className="lft-navi control-btn"
                            style={{
                              color:
                                currentSvgIndex == 0 || isPathDrawing
                                  ? "#717a8e"
                                  : "inherit",
                            }}
                            onClick={async () => {
                              if (currentSvgIndex !== 0 && !isPathDrawing) {
                                handleFloorChange("DOWN");
                              }
                            }}
                          >
                            <LeftArrow />
                          </button>
                          {/* Editable field  start*/}
                          <div>
                            {isFloorEditing ? (
                              <input
                                className="floor-selector"
                                type="number"
                                value={currentSvgIndex}
                                onChange={(e) => {
                                  setCurrentSvgIndex(e.target.value);
                                }}
                                onBlur={() => {
                                  setIsFloorEditing(false);
                                  handleFloorChange("MANUAL");
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    setIsFloorEditing(false);
                                    handleFloorChange("MANUAL");
                                  }
                                }}
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  !isPathDrawing && setIsFloorEditing(true)
                                }
                              >
                                {currentSvgIndex == 0 ? (
                                  <>{t("ground_floor")}</>
                                ) : (
                                  <>
                                    {t("floor")}
                                    <strong>{currentSvgIndex}</strong>
                                  </>
                                )}
                              </span>
                            )}
                          </div>
                          {/* Editable field end*/}
                          <button
                            className="rgt-navi control-btn"
                            style={{
                              color:
                                currentSvgIndex == floors?.length - 1 ||
                                isPathDrawing
                                  ? "#717a8e"
                                  : "inherit",
                            }}
                            onClick={async () => {
                              if (
                                currentSvgIndex !== floors?.length - 1 &&
                                !isPathDrawing
                              ) {
                                handleFloorChange("UP");
                              }
                            }}
                          >
                            <RightArrow />{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  {/* Floor selector end */}
                </div>
              </>
            )}
          </div>
        ) : (
          <MapContainer
            currentSlide={currentSlide}
            sliderRef={sliderRef}
            setCurrentSlide={setCurrentSlide}
            setShare={setShare}
            sidebarRef={sidebarRef}
            setSearch={setSearch}
          />
        )}
      </div>

      <div className="headerMenuMobile">
        <div className="menubtn">
          <img
            width="25"
            height="19"
            style={{
              cursor: "pointer",
            }}
            src={menuIconResponsive}
            alt="menu"
            onClick={() => handleMenuIcon()}
          />
        </div>
        <div>
          <img
            className="head-logo"
            src={LogoMain}
            alt="logo"
            width="95"
            height="37"
          />
        </div>
      </div>

      {/* Share models */}
      {share.value && share.isShare ? (
        <ShareModel url={share.value} setShare={setShare} />
      ) : null}

      {shareFromMakaniCard.value && shareFromMakaniCard.isShare ? (
        <ShareModel
          url={shareFromMakaniCard.value}
          setShare={(value) => setShareFromMakaniCard(value)}
        />
      ) : null}

      {/* Download makani card popup */}
      {showPopup && <DownloadApp />}

      {/* Display suggessions */}
      {displaySuggestions ||
        (HighPOI.includes(location.pathname) && (
          <div className="POIMap">
            <Slider {...settings}>
              {updatedPOICategories.map((item) => {
                return (
                  <div
                    className="pointOfInternetItemSearchSuggestions"
                    onClick={() => {
                      if (makaniCoords[currentSlide]) {
                        let position = `${makaniCoords[currentSlide]?.lat},${makaniCoords[currentSlide]?.lng}`;
                        handleDisplayPoi(
                          item.CategoryId,
                          position,
                          item?.MapIcon,
                          item?.Color
                        );
                      } else {
                        toast.info("No POI found!", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          style: {
                            textAlign:
                              il8n.language === "ar" ? "right" : "left",
                          },
                        });
                      }
                    }}
                    style={{
                      paddingRight: il8n.language === "ar" && "0",
                      paddingLeft: il8n.language === "ar" && "14px",
                    }}
                  >
                    <div
                      className="pointOfInternetIcon"
                      style={{ background: item?.Color }}
                    >
                      <img
                        width="38"
                        height="38"
                        src={item?.MapIcon}
                        alt="atm"
                      />
                    </div>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {il8n.language === "ar"
                        ? item?.CategoryA
                        : item?.CategoryE}
                    </span>
                  </div>
                );
              })}
            </Slider>
          </div>
        ))}

      {/* Login Required model */}
      {isRequireLogin && <LoginModel />}

      {/* TODO : CHANGE THIS TO SETTINGS PAGE AFTER GETTING DESIGN */}
      {/* {settingsAlert.value && settingsAlert.message && (
        <SettingsAlert message={settingsAlert.message} />
      )} */}
    </div>
  );
};
export default withTour(ParentLayout, "map");

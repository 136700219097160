import {
  PageHeaderTitle,
  MakaniDetails as MakaniDetailsComponent,
  MakaniCard,
} from "../../component";
import "../../scss/common.scss";
import "../../scss/article.scss";
import "../../scss/sitemap.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { UseRemoveMapShape } from "../../customHooks";

import {
  languageicon,
  homeicon,
  multirouting,
  addressicon,
  happinessicon,
  informationicon,
  contacticon,
  disclaimericon,
  downloadicon,
  favouriteicon,
} from "../../assets/index";
import AccessibleButton from "../../component/Accessibility/accessibility";
import { SpeechSynthesisButton } from "../../component/index";

import React from "react";

const Sitemap = () => {
  UseRemoveMapShape();
  const [t] = useTranslation();
  const location = useLocation();
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const textToRead = [
    `${t("sitemap")}`,
    `${t("language")}: ${t("sitelanuagepoint")}`,
    `${t("home")}: ${t("sitehomepoint")}`,
    `${t("routing")}: ${t("siteroutepoint")}`,
    `${t("searchaddress")}: ${t("siteaddresspoint")}`,
    `${t("fav")}: ${t("sitefavpoint")}`,
    `${t("happinessmeter")}: ${t("sitehappypoint")}`,
    `${t("information")}: ${t("siteinfopoint")}`,
    `${t("contact")}: ${t("sitecontactpoint")}`,
    `${t("download")}: ${t("sitedownloadpoint")}`,
    `${t("disclaimer")}: ${t("sitedisclaimerpoint")}`,
  ].join(" ");

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent inner-content inner-article swipe-line"
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <div className="PageHeaderTitleWarper">
        <PageHeaderTitle
          title={t("sitemap")}
          fromMenu={location?.state?.fromMenu}
        />
        <SpeechSynthesisButton text={textToRead} />
      </div>

      <div className="content-cover">
        <ul className="sitemaplist">
          <li>
            <div className="bulletscircle">
              <img src={languageicon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("language")}
            >
              <div className="title_small">{t("language")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitelanuagepoint")}
              >
                {t("sitelanuagepoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={homeicon} alt="home"></img>
            </div>

            <AccessibleButton className="rightcontent" ariaLabel={t("home")}>
              <div className="title_small">{t("home")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitehomepoint")}
              >
                {t("sitehomepoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={multirouting} alt="multirouting"></img>
            </div>

            <AccessibleButton className="rightcontent" ariaLabel={t("routing")}>
              <div className="title_small">{t("routing")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("siteroutepoint")}
              >
                {t("siteroutepoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={addressicon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("searchaddress")}
            >
              <div className="title_small">{t("searchaddress")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("siteaddresspoint")}
              >
                {t("siteaddresspoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={favouriteicon} alt="language"></img>
            </div>

            <AccessibleButton className="rightcontent" ariaLabel={t("fav")}>
              <div className="title_small">{t("fav")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitefavpoint")}
              >
                {t("sitefavpoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={happinessicon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("happinessmeter")}
            >
              <div className="title_small">{t("happinessmeter")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitehappypoint")}
              >
                {t("sitehappypoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={informationicon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("information")}
            >
              <div className="title_small">{t("information")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("siteinfopoint")}
              >
                {t("siteinfopoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={contacticon} alt="language"></img>
            </div>

            <AccessibleButton className="rightcontent" ariaLabel={t("contact")}>
              <div className="title_small">{t("contact")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitecontactpoint")}
              >
                {t("sitecontactpoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={downloadicon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("download")}
            >
              <div className="title_small">{t("download")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitedownloadpoint")}
              >
                {t("sitedownloadpoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
          <li>
            <div className="bulletscircle">
              <img src={disclaimericon} alt="language"></img>
            </div>

            <AccessibleButton
              className="rightcontent"
              ariaLabel={t("disclaimer")}
            >
              <div className="title_small">{t("disclaimer")}</div>
              <AccessibleButton
                className="para_md"
                ariaLabel={t("sitedisclaimerpoint")}
              >
                {t("sitedisclaimerpoint")}
              </AccessibleButton>
            </AccessibleButton>
          </li>
        </ul>
      </div>

      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};

export default Sitemap;
//15:02

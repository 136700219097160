import { useMapControl ,useTour} from '../../context/index.js';
import  { useEffect } from 'react';
import withTour from '../../withTour';
import tourSteps from '../../tourSteps'; 
import { useTranslation } from 'react-i18next';

const DirectioFilter=({handleRouteChange,locations,setSettingRoute,selectedRoute,checked})=>{
  const [t] = useTranslation();
    const {
        updateDirection,
        currentAvoidToll,
        setCurrentAvoidToll,
      } = useMapControl();
    return  (
        <div class="sortDropDown">
        <ul>
            <li className="Avoid_toll_desc">
            <label class="form-control">
  <input type="checkbox" name="checkbox"  checked={currentAvoidToll} 
  onClick={()=>{
    setCurrentAvoidToll(!currentAvoidToll)
          setSettingRoute(false)
       updateDirection(locations)}} />
  <p>
  {t('aviodTool')}
  </p> 
</label>
    
        
            </li>
            <li className="Fastest_Route_desc">
              <input
                type="radio"
                id="fastest"
                name="routeType"
                value="fastest"
                checked={selectedRoute === 'fastest'}
                onChange={handleRouteChange}
              />
              <label htmlFor="fastest"><p>{t('fastest')}</p></label>
            </li>
            <li className="Shortest_Route_desc">
              <input
                type="radio"
                id="shortest"
                name="routeType"
                value="shortest"
                checked={selectedRoute === 'shortest'}
                onChange={handleRouteChange}
              />
              <label htmlFor="shortest"><p>{t('shortest')}</p></label>
            </li>
           
        </ul>
    </div>
    )
}
export default  DirectioFilter;
import { useTranslation } from "react-i18next";
import { Toggle, SettingToggle } from "../../component/index";
import { useMapControl } from "../../context/MapControllContext";
import { useEffect, useState } from "react";
import AccessibleButton from "../Accessibility/accessibility";
import SettingsAlert from "../models/settingsAlert";
const MapAndNavigationSettings = () => {
  const [t, il8n] = useTranslation();

  const {
    setAvoidTols,
    avoidToll,
    setMapType,
    mapType,
    setZoomInBtn,
    zoomInBtn,
    setScaleBar,
    scaleBar,
    setRouteType,
    routeType,
    traffic,
    setTraffic,
    measurementUnit,
    setMeasurementUnit,
  } = useMapControl();

  const handleRouteTypeChange = (event) => {
    setRouteType(event.target.value);
  };

  return (
    <div
      className="collapsibleMenuItems"
      style={{
        padding: "10px 0 25px 0",
      }}
    >
      <div className="collapsibleMenuItemWrapper MenuItemRow ">
        <AccessibleButton ariaLabel={t("Click here for avoid toll")}>
          <p>{t("aviodTool")}</p>
        </AccessibleButton>
        <Toggle setChecked={setAvoidTols} checked={avoidToll} />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemCol"
        style={{
          height: "auto",
        }}
      >
        <div className="MenuItemRow">
          <AccessibleButton ariaLabel={t("Click here for fastest route")}>
            <label htmlFor="fastest">{t("fastestRoute")}</label>
          </AccessibleButton>
          <input
            onChange={handleRouteTypeChange}
            type="radio"
            id="fastest"
            value={"fastest"}
            checked={routeType === "fastest"}
            name="routeType"
            style={{
              accentColor: " #009D91",
            }}
          />
        </div>
        <div className="MenuItemRow">
          <AccessibleButton ariaLabel={t("Click here for shortest route")}>
            <label htmlFor="shortest">{t("shortestRoute")}</label>
          </AccessibleButton>
          <input
            checked={routeType === "shortest"}
            onChange={handleRouteTypeChange}
            type="radio"
            light
            value={"shortest"}
            id="shortest"
            name="routeType"
            style={{
              accentColor: " #009D91",
            }}
          />
        </div>
      </div>

      <div
        className="collapsibleMenuItemWrapper MenuItemCol"
        style={{
          height: "auto",
          padding: "25px 15px",
        }}
      >
        <div className="MenuItemRow">
          <AccessibleButton ariaLabel={t("Click here for map type")}>
            <p>{t("mapType")}</p>
          </AccessibleButton>
          <SettingToggle
            values={{
              default: t("default"),
              satellite: t("satellite"),
            }}
            value={mapType}
            setState={setMapType}
          />
        </div>
        <div className="MenuItemRow">
          <AccessibleButton
            ariaLabel={t("Click here for to know about traffic")}
          >
            <p>{t("traffic")}</p>
          </AccessibleButton>
          <Toggle setChecked={setTraffic} checked={traffic} />
        </div>
      </div>
      <div className="collapsibleMenuItemWrapper MenuItemRow ">
        <AccessibleButton ariaLabel={t("Click here for measurement unit")}>
          <p>{t("MeasurementUnit")}</p>
        </AccessibleButton>
        <SettingToggle
          values={{
            kilometers: t("kilometers"),
            miles: t("miles"),
          }}
          value={measurementUnit}
          setState={setMeasurementUnit}
        />
      </div>
      {/* <div className="collapsibleMenuItemWrapper MenuItemRow ">
        <AccessibleButton ariaLabel={t("Click here for zoom in button")}>
          <p>{t("ZoomInButton")}</p>
        </AccessibleButton>
        <Toggle setChecked={setZoomInBtn} checked={zoomInBtn} />
      </div> */}

      <div className="collapsibleMenuItemWrapper MenuItemRow ">
        <AccessibleButton ariaLabel={t("Click here for scale bar")}>
          <p>{t("scaleBar")}</p>
        </AccessibleButton>
        <Toggle setChecked={setScaleBar} checked={scaleBar} />
      </div>
    </div>
  );
};

export default MapAndNavigationSettings;

import { useState, useEffect } from "react";
import { GetFloorPlans } from "../helper/api/requests.ts";
import { useAuth, useIndoorNavigation } from "../context/index.js";
import { loadSvg } from "../helper/index.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const useGetFloorPlansFetching = () => {
  const { token, getToken, setToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [t, il8n] = useTranslation();
  const {
    setSvgEelement,
    svgContainerRef,
    showSvg,
    svgElement,
    buildingId,
    floors,
  } = useIndoorNavigation();
  const location = useLocation();

  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController
    const { signal } = controller;

    const FetchFloorPlans = async () => {
      try {
        setLoading(true);
        const floorRef = floors?.find(
          (floor) => floor.floor === "800"
        )?.floorRef;

        let result = await GetFloorPlans(t, token, buildingId, floorRef, {
          signal,
        });

        // If the fetch is aborted, stop further execution
        if (signal.aborted) return;

        if (result.isExp) {
          let newToken = await getToken();
          localStorage.setItem("token", JSON.stringify(newToken));
          setToken(newToken);
          result = await GetFloorPlans(t, newToken, buildingId, floorRef, {
            signal,
          });
        }

        if (signal.aborted) return; // Check if the request was aborted

        setLoading(false);

        if (result.error) {
          toast.error(result.error, {
            type: "error",
            isLoading: false,
            autoClose: 3000,
            position: il8n.language === "ar" ? "top-left" : "top-right",
            style: {
              textAlign: il8n.language === "ar" ? "right" : "left",
            },
          });
          return;
        }

        const svgElement = await loadSvg(
          result?.floor?.find((item) => item?.floor == "800")?.svgUrl || ""
        );

        if (signal.aborted) return;

        let container = svgContainerRef.current;

        if (!container) {
          container = document.createElement("div");
          container.style.width = "100%";
          container.style.height = "100%";
          svgContainerRef.current = container;
          document.body.appendChild(container);
        }

        container.innerHTML = "";
        container.appendChild(svgElement);
        svgElement.setAttribute("width", "100%");
        svgElement.setAttribute("height", "100%");
        svgElement.setAttribute("viewBox", "0 0 1000 1000");
        setSvgEelement(svgElement);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    // Start fetching only if showSvg is true and other conditions are met
    if (showSvg && token && floors && buildingId) FetchFloorPlans();

    // Clean up: Abort fetch if showSvg becomes false or on component unmount
    return () => {
      controller.abort();
    };
  }, [showSvg, svgElement, buildingId, floors, token]);

  return {
    loading,
  };
};

export default useGetFloorPlansFetching;

import { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import {
  Home,
  SignUp,
  SearchResult,
  Settings,
  SearchByBuildingAddress,
  Favorites,
  Direction,
  BuildingAddress,
  Makani,
  SearchByLandNumber,
  UploadQR,
} from "./pages/index";
// Import components
import {
  ParentLayout,
  ProfileLayout,
  MyMakaniCards,
  ChildrenLayout,
  MyMakaniCardsList,
  PageHeaderTitle,
} from "./component/index";

// Import layouts
// Can  be moved to pages/index
import About from "./pages/about/index";
import Legend from "./pages/legend/index";
import Faq from "./pages/faq/index";
import Contact from "./pages/contact/index";
import Disclaimer from "./pages/disclaimer/index";
import Sitemap from "./pages/sitemap/index";
import IndoorNavigation from "./pages/IndoorNavigation/index.jsx";
import MakaniPlateDamage from "./pages/ReportMakaniPlateDamage/index.jsx";
import Callback from "./pages/Callback.js";

import { useAppearance } from "./context/AppearanceContext";
import { makaniCardResponsive, makaniCardResponsiveDark } from "./assets/index";
import { useTranslation } from "react-i18next";

// Assets and analytics
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { initGA, logEvent } from "./analytics";
import ErrorBoundary from "./context/ErrorBoundary.js";

function App() {
  const { theme } = useAppearance();
  const [t, i18n] = useTranslation();

  const sidebarRef = useRef(null);
  const sliderRef = useRef(null);
  const cardRef = useState(null);

  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const [topValue, setTopValue] = useState("calc(100% - 250px)");
  const [startY, setStartY] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [FavName, setFavName] = useState("");
  const [search, setSearch] = useState("");
  const [share, setShare] = useState({
    value: "",
    isShare: false,
  });

  // Google analytics
  useEffect(() => {
    const analyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

    if (analyticsKey) {
      initGA(analyticsKey);
      logEvent("Website", "Initialized", "App Load");
    }

    logEvent("Language", "Change", `Language: ${i18n.language}`);
  }, [i18n.language]);

  // Window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle scroll
  const handleTouchMove = (e) => {
    if (startY === null) return;
    const deltaY = e.touches[0].clientY - startY;
    const newValue = deltaY > 0 ? "100px" : `${50 + deltaY}px`;
    setTopValue("calc(100% - 550px)");
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  return (
    <ErrorBoundary>
      <Routes>
        <Route
          path="/"
          element={
            <ParentLayout
              displaySuggestions={displaySuggestions}
              HighPOI={["/searchResult"]}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              sliderRef={sliderRef}
              Requests
              the
              geolocation
              permission
              on
              page
              load
              setShare={setShare}
              share={share}
              sidebarRef={sidebarRef}
              search={search}
              setSearch={setSearch}
            />
          }
        >
          <Route
            path="/"
            element={
              <ChildrenLayout
                topValue={topValue}
                displaySuggestions={displaySuggestions}
                HighPOI={["/searchResult"]}
                HighMarginValue={[
                  "/signup",
                  "/profile",
                  "/settings",
                  "/favorites",
                ]}
                sidebarRef={sidebarRef}
              />
            }
          >
            <Route
              path="/"
              element={
                <Home
                  handleTouchMove={handleTouchMove}
                  handleTouchStart={handleTouchStart}
                  setDisplaySuggestions={setDisplaySuggestions}
                  displaySuggestions={displaySuggestions}
                  setShare={setShare}
                  search={search}
                  setSearch={setSearch}
                />
              }
            />
            <Route
              path="/map"
              element={
                <Home
                  handleTouchMove={handleTouchMove}
                  handleTouchStart={handleTouchStart}
                  setDisplaySuggestions={setDisplaySuggestions}
                  displaySuggestions={displaySuggestions}
                  setShare={setShare}
                />
              }
            />
            <Route path="/login" element={<SignUp />} />
            <Route
              path="/searchResult"
              element={
                <SearchResult
                  setCurrentSlide={setCurrentSlide}
                  sliderRef={sliderRef}
                  currentSlide={currentSlide}
                />
              }
            />
            <Route path="/settings" element={<Settings />} />
            <Route
              path="/searchByBuildingAddress"
              element={<SearchByBuildingAddress />}
            />
            <Route
              path="/searchByLandNumber"
              element={<SearchByLandNumber />}
            />
            <Route path="/uploadQR" element={<UploadQR />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route
              path="/makani"
              element={<Makani setShare={setShare} setSearch={setSearch} />}
            />
            <Route path="/building-address" element={<BuildingAddress />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legend" element={<Legend />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/direction" element={<Direction />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/indoorNavigation" element={<IndoorNavigation />} />
            <Route
              path="/report-makani-plate-damage"
              element={<MakaniPlateDamage />}
            />
          </Route>
          <Route
            path="/profile"
            element={
              <ProfileLayout
                sidebarRef={sidebarRef}
                path={["/profile/myMakaniCards"]}
              />
            }
          >
            <Route
              path="myMakaniCards"
              element={
                isMobileView ? (
                  <div
                    className="sideBarContent swipe-line top-spacing "
                    style={{
                      padding: "",
                    }}
                  >
                    <PageHeaderTitle
                      responsiveIcon={
                        theme === "dark"
                          ? makaniCardResponsiveDark
                          : makaniCardResponsive
                      }
                      parent="/profile"
                      title={t("myMakaniCards")}
                    />

                    <MyMakaniCardsList
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      setFavName={setFavName}
                    />
                  </div>
                ) : (
                  <MyMakaniCards
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    setFavName={setFavName}
                    FavName={FavName}
                    cardRef={cardRef}
                  />
                )
              }
            />
          </Route>
        </Route>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;

//18:04

function processShapes(shapes, isParcel, isTap) {
  let array = [];
  let newShapes = [];

  newShapes = shapes?.split(",").map((coord) => parseFloat(coord.trim()));
  if (isTap) {
    newShapes = shapes?.split(/[,;]/).map((coord) => parseFloat(coord.trim()));

    newShapes.pop();
  }
  for (let i = 0; i < newShapes?.length; i += 2) {
    let lng;
    let lat;
    // if (isParcel) {
    //   lng = newShapes[i];
    //   lat = newShapes[i + 1];
    // } else
    if (isTap) {
      lng = newShapes[i + 1];
      lat = newShapes[i];
    } else {
      lat = newShapes[i + 1];
      lng = newShapes[i];
    }

    array.push({ lat, lng });
  }

  return array;
}

export default processShapes;

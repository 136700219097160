import { useEffect, useState } from "react";
import { useFavorites } from "../../context/FavouritesContext.js";
import { makaniCloseIcon } from "../../assets/index.js";
import { useTranslation } from "react-i18next";

const DeleteMakaniCard = ({
  description,
  title,
  item,
  setDeleteMakaniToggle,
  setEditIndexs,
  FromRoute,
  onDelete,
}) => {
  const [t, il8n] = useTranslation();
  const { fetchSyncFavorites } = useFavorites();

  const handleDeleteMakaniCard = async (item) => {
    await fetchSyncFavorites(item, {
      action: "delete",
      FromRoute: FromRoute,
    });

    // Now call onDelete after deletion
    if (onDelete !== undefined) {
      onDelete();
    }

    setDeleteMakaniToggle({
      item: {},
      isDeleteMakani: false,
      index: -1,
    });

    if (setEditIndexs) {
      setEditIndexs([]);
    }
  };

  return (
    <div className="delete-m-card">
      <div className="d-head">
        <span
          style={{
            marginRight: il8n.language === "ar" && "auto",
            marginLeft: il8n.language === "ar" && "unset",
          }}
        >
          <strong>{title}</strong>
        </span>
        <button
          style={{
            marginRight: il8n.language === "ar" && "auto",
            marginLeft: il8n.language === "ar" && "unset",
          }}
          className="close_btn"
          onClick={() => {
            setDeleteMakaniToggle({
              item: {},
              isDeleteMakani: false,
              index: -1,
            });
          }}
        >
          <img src={makaniCloseIcon} alt="close button" />
        </button>
      </div>
      <div className="body-content">
        <p>{description}</p>
        <div className="btn__brp" style={{ direction: "ltr" }}>
          <button
            className="btn-m btn-cancel"
            onClick={() => {
              setDeleteMakaniToggle({
                item: {},
                isDeleteMakani: false,
                index: -1,
              });
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="btn-m btn-delete"
            onClick={() => handleDeleteMakaniCard(item)}
          >
            {t("delete")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMakaniCard;

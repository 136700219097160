import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  GetFavorites,
  SyncFavorites,
  GetSmartSearchResult,
} from "../helper/api/requests.ts";
import { toast } from "react-toastify";
import { useMapControl } from "./MapControllContext.js";
import { useAuth } from "./auth.js";
import { useTranslation } from "react-i18next";
import { calculateDistanceBetweenCoordinates } from "../helper/index.js";

const FavoritesContext = createContext();
export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
  const { measurementUnit } = useMapControl();
  const [loading, setLoading] = useState(true);
  const [myMakani, setMyMakani] = useState([]);
  const [locations, setLocations] = useState([]);
  const [routeLocations, setRouteLocations] = useState([]);
  const [favoritesToggle, setFavoritesToggle] = useState({
    item: {},
    isFavoritesToggle: false,
    index: -1,
  });
  const [t, il8n] = useTranslation();
  const { currentLocation, currentLocationDirection } = useMapControl();
  const { token, userId, sessionId, initialToken, setToken, getToken } =
    useAuth();
  const [favoritesMarker, setFavoritesMarker] = useState([]);
  const [MakaniMarker, setMakaniMarker] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [editFavoritesToggle, setEditFavoritesToggle] = useState({
    item: {},
    isEditFavoritesToggle: false,
    index: "",
  });
  const [editLocationToggle, setEditLocationToggle] = useState({
    item: {},
    isEditLocationToggle: false,
    index: "",
  });
  const [ShareLocationToggle, setShareLocationToggle] = useState({
    isShareLocationToggle: false,
    index: -1,
    makani: "",
  });
  const [displayMarker, setDisplayMarker] = useState(false);
  const [disaplayMakaniMarker, setDisaplayMakaniMarker] = useState(false);
  const [selectedFavoriteMarker, setSelectedFavoriteMarker] = useState(-1);
  const [selectedMakaniMarker, setSelectedMakaniMarker] = useState(-1);
  const [selectedFavoriteLoading, setSelectedFavoriteLoading] = useState(false);
  const [favoritePlaceInfo, setFavoritePalceInfo] = useState({
    BldgNameEn: "",
    BldgNameAr: "",
    EntNameA: "",
    EntNameE: "",
    CommEn: "",
    CommAr: "",
    EmirateA: "",
    EmirateE: "",
    street: "",
    lat: "",
    lng: "",
  });
  const [sharedURL, setSharedURL] = useState({
    value: "",
    inSlider: false,
  });

  function transformData(data) {
    const result = [];

    for (const key in data) {
      const routeName = key.replace("_route", "");
      const valuesArray = data[key].map((item) => ({
        ...item,
        FavName: item.FavName,
      }));
      result.push({
        name: routeName,
        values: valuesArray,
      });
    }

    return result;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let result = await GetFavorites(userId, sessionId, token);

        if (result.isExp) {
          let newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          result = await GetFavorites(userId, sessionId, newToken);
        }

        if (result.error) {
          toast.error({
            render: t("Error_while_get_favorites"),
            type: "error",
            isLoading: false,
            autoClose: 3000,
            position: il8n.language === "ar" ? "top-left" : "top-right",
            style: {
              textAlign: il8n.language === "ar" ? "right" : "left",
            },
          });
          return;
        }
        let FavoriteWithDistance = result.map((item) => {
          return {
            ...item,
            distance: calculateDistanceBetweenCoordinates(
              {
                lat: Number(currentLocationDirection.lat),
                lng: Number(currentLocationDirection.lng),
              },
              {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              },
              measurementUnit === "kilometers" ? "km" : "mile"
            ),
          };
        });

        const myMakaniItems = FavoriteWithDistance.filter(
          (item) => item.IsMyMakani
        ).map((item) => {
          const isFavorite =
            FavoriteWithDistance.findIndex(
              (favorite) =>
                favorite.FavType === "LOCATION" &&
                favorite.FavValue === item.FavValue
            ) > -1;

          return {
            ...item,
            isFavorite,
          };
        });

        setMyMakani(
          myMakaniItems.sort((a, b) => b.LastUpdated - a.LastUpdated)
        );

        setLocations(
          FavoriteWithDistance.filter(
            (item) => item.FavType === "LOCATION"
          ).sort((a, b) => b.LastUpdated - a.LastUpdated)
        );

        const groupedFavorites = groupByFavName(
          result.filter((item) => item.FavType === "ROUTE_LOCATION")
        );

        setRouteLocations(
          transformData(groupedFavorites).sort(
            (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
          )
        );

        let favoritesMarkerResult = result
          .filter((item) => item.FavType === "LOCATION")
          .sort((a, b) => b.LastUpdated - a.LastUpdated)
          .map((item) => {
            return {
              lat: Number(item.Location.split(",")[0]),
              lng: Number(item.Location.split(",")[1]),
              makani: item.FavValue.replace(/\s+/g, ""),
            };
          });

        let makaniMarkerResult = result
          .filter((item) => item.IsMyMakani)
          .sort((a, b) => b.LastUpdated - a.LastUpdated)
          .map((item) => {
            return {
              lat: Number(item.Location.split(",")[0]),
              lng: Number(item.Location.split(",")[1]),
            };
          });

        setMakaniMarker(makaniMarkerResult);

        setFavoritesMarker(favoritesMarkerResult);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching favorites");
      }
    };
    if (
      token &&
      sessionId &&
      userId &&
      currentLocationDirection.lat &&
      currentLocationDirection.lng
    ) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [initialToken, userId, sessionId, currentLocationDirection, refetch]);

  const groupByFavName = (favorites) => {
    return favorites.reduce((acc, item) => {
      const favNamePrefix = item.FavName.substring(
        0,
        item.FavName.lastIndexOf("_")
      );

      if (!acc[favNamePrefix]) {
        acc[favNamePrefix] = [];
      }
      if (item.FavType === "ROUTE_LOCATION") {
        acc[favNamePrefix].push(item);
      }

      return acc;
    }, {});
  };

  const fetchSyncFavorites = async (item, actions) => {
    const toastId = toast.loading(t("loading"), {
      position: il8n.language === "ar" ? "top-left" : "top-right",
      style: {
        textAlign: il8n.language === "ar" ? "right" : "left",
      },
    });

    try {
      let favorites = await GetFavorites(userId, sessionId, token);
      if (favorites.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        favorites = await GetFavorites(userId, sessionId, newToken);
      }
      if (actions.action === "add") {
        if (actions.routeLocation) {
          let result = await SyncFavorites(
            [...favorites, ...item],
            token,
            userId,
            sessionId
          );
          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(
              [...favorites, ...item],
              newToken,
              userId,
              sessionId
            );
          }

          const groupedFavorites = groupByFavName(
            result.filter((item) => item.FavType === "ROUTE_LOCATION")
          );
          setRouteLocations(
            transformData(groupedFavorites).sort(
              (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
            )
          );
        } else {
          let currentTimeMillis = new Date().getTime();
          item["LastUpdated"] = currentTimeMillis;
          let result = await SyncFavorites(
            [...favorites, item],
            token,
            userId,
            sessionId
          );
          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(
              [...favorites, item],
              newToken,
              userId,
              sessionId
            );
          }

          let FavoriteWithDistance = result.map((item) => {
            return {
              ...item,
              distance: calculateDistanceBetweenCoordinates(
                {
                  lat: Number(currentLocationDirection.lat),
                  lng: Number(currentLocationDirection.lng),
                },
                {
                  lat: Number(item.Location.split(",")[0]),
                  lng: Number(item.Location.split(",")[1]),
                },
                measurementUnit === "kilometers" ? "km" : "mile"
              ),
            };
          });

          const myMakaniItems = FavoriteWithDistance.filter(
            (item) => item.IsMyMakani
          ).map((item) => {
            const isFavorite =
              FavoriteWithDistance.findIndex(
                (favorite) =>
                  favorite.FavType === "LOCATION" &&
                  favorite.FavValue === item.FavValue
              ) > -1;

            return {
              ...item,
              isFavorite,
            };
          });

          setMyMakani(
            myMakaniItems.sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          setLocations(
            FavoriteWithDistance.filter(
              (item) => item.FavType === "LOCATION"
            ).sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          let favoritesMarkerResult = result
            .filter((item) => item.FavType === "LOCATION")
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });

          setFavoritesMarker(favoritesMarkerResult);
          let makaniMarkerResult = result
            .filter((item) => item.IsMyMakani)
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });

          setMakaniMarker(makaniMarkerResult);
        }

        toast.update(toastId, {
          render: t("add_successful"),
          type: "success",
          isLoading: false,
          autoClose: 1000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      } else if (actions.action === "delete") {
        if (actions.FromRoute) {
          const res = favorites.filter((fav) => {
            const regex = new RegExp(`^${item.FavName}_route_\\d+$`);
            return !(fav.FavType === item.FavType && regex.test(fav.FavName));
          });

          let result = await SyncFavorites(res, token, userId, sessionId);

          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(res, newToken, userId, sessionId);
          }
          const groupedFavorites = groupByFavName(
            result.filter((item) => item.FavType === "ROUTE_LOCATION")
          );
          setRouteLocations(
            transformData(groupedFavorites).sort(
              (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
            )
          );
        } else {
          const res = favorites.filter((fav) => {
            return !(
              fav.FavValue === item.FavValue &&
              fav.FavType === item.FavType &&
              fav.FavName === item.FavName
            );
          });

          let result = await SyncFavorites(res, token, userId, sessionId);
          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(res, newToken, userId, sessionId);
          }

          let FavoriteWithDistance = result.map((item) => {
            return {
              ...item,
              distance: calculateDistanceBetweenCoordinates(
                {
                  lat: Number(currentLocationDirection.lat),
                  lng: Number(currentLocationDirection.lng),
                },
                {
                  lat: Number(item.Location.split(",")[0]),
                  lng: Number(item.Location.split(",")[1]),
                },
                measurementUnit === "kilometers" ? "km" : "mile"
              ),
            };
          });

          const myMakaniItems = FavoriteWithDistance.filter(
            (item) => item.IsMyMakani
          ).map((item) => {
            const isFavorite =
              FavoriteWithDistance.findIndex(
                (favorite) =>
                  favorite.FavType === "LOCATION" &&
                  favorite.FavValue === item.FavValue
              ) > -1;

            return {
              ...item,
              isFavorite,
            };
          });
          setMyMakani(
            myMakaniItems.sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          setLocations(
            FavoriteWithDistance.filter(
              (item) => item.FavType === "LOCATION"
            ).sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          let favoritesMarkerResult = result
            .filter((item) => item.FavType === "LOCATION")
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });

          setFavoritesMarker(favoritesMarkerResult);

          let makaniMarkerResult = result
            .filter((item) => item.IsMyMakani)
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });
          setMakaniMarker(makaniMarkerResult);
        }

        toast.update(toastId, {
          render: t("deleted_successful"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      } else if (actions.action === "edit") {
        if (actions.FromRoute) {
          let result = await SyncFavorites(
            favorites.map((fav) => {
              let favNameBase = fav.FavName.substring(
                0,
                fav.FavName.lastIndexOf("_")
              );

              if (
                fav.FavType === item.FavType &&
                favNameBase === `${item.FavName}_route`
              ) {
                fav.FavName = `${actions.value}_route_${
                  fav.FavName[fav.FavName.lastIndexOf("_") + 1]
                }`;
                fav.LastUpdated = actions.lastUpdate;
              }
              return fav;
            }),
            token,
            userId,
            sessionId
          );

          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(
              favorites.map((fav) => {
                let favNameBase = fav.FavName.substring(
                  0,
                  fav.FavName.lastIndexOf("_")
                );

                if (
                  fav.FavType === item.FavType &&
                  favNameBase === `${item.FavName}_route`
                ) {
                  fav.FavName = `${actions.value}_route_${
                    fav.FavName[fav.FavName.lastIndexOf("_") + 1]
                  }`;
                  fav.LastUpdated = actions.lastUpdate;
                }
                return fav;
              }),
              newToken,
              userId,
              sessionId
            );
          }
          const groupedFavorites = groupByFavName(
            result.filter((item) => item.FavType === "ROUTE_LOCATION")
          );
          setRouteLocations(
            transformData(groupedFavorites).sort(
              (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
            )
          );
        } else {
          let result = await SyncFavorites(
            favorites.map((fav) => {
              if (
                fav.FavValue === item.FavValue &&
                fav.FavType === item.FavType &&
                fav.FavName === item.FavName
              ) {
                fav["FavName"] = actions.value;
                fav["LastUpdated"] = actions.lastUpdate;
                return fav;
              } else {
                return fav;
              }
            }),
            token,
            userId,
            sessionId
          );

          if (result.isExp) {
            let newToken = await getToken();
            setToken(newToken);
            localStorage.setItem("token", JSON.stringify(newToken));
            result = await SyncFavorites(
              favorites.map((fav) => {
                if (
                  fav.FavValue === item.FavValue &&
                  fav.FavType === item.FavType &&
                  fav.FavName === item.FavName
                ) {
                  fav["FavName"] = actions.value;
                  fav["LastUpdated"] = actions.lastUpdate;
                  return fav;
                } else {
                  return fav;
                }
              }),
              newToken,
              userId,
              sessionId
            );
          }

          setEditFavoritesToggle({
            item: {},
            isEditFavoritesToggle: false,
          });
          let FavoriteWithDistance = result.map((item) => {
            return {
              ...item,
              distance: calculateDistanceBetweenCoordinates(
                {
                  lat: Number(currentLocationDirection.lat),
                  lng: Number(currentLocationDirection.lng),
                },
                {
                  lat: Number(item.Location.split(",")[0]),
                  lng: Number(item.Location.split(",")[1]),
                },
                measurementUnit === "kilometers" ? "km" : "mile"
              ),
            };
          });
          const myMakaniItems = FavoriteWithDistance.filter(
            (item) => item.IsMyMakani
          ).map((item) => {
            const isFavorite =
              FavoriteWithDistance.findIndex(
                (favorite) =>
                  favorite.FavType === "LOCATION" &&
                  favorite.FavValue === item.FavValue
              ) > -1;

            return {
              ...item,
              isFavorite,
            };
          });

          setMyMakani(
            myMakaniItems.sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          setLocations(
            FavoriteWithDistance.filter(
              (item) => item.FavType === "LOCATION"
            ).sort((a, b) => b.LastUpdated - a.LastUpdated)
          );

          let favoritesMarkerResult = result
            .filter((item) => item.FavType === "LOCATION")
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });

          setFavoritesMarker(favoritesMarkerResult);
          let makaniMarkerResult = result
            .filter((item) => item.IsMyMakani)
            .map((item) => {
              return {
                lat: Number(item.Location.split(",")[0]),
                lng: Number(item.Location.split(",")[1]),
              };
            });

          setMakaniMarker(makaniMarkerResult);
        }

        // setCenter(favoritesMarkerResult[0]);
        toast.update(toastId, {
          render: t("updated_successful"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
      }
    } catch (error) {
      toast.update(toastId, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: il8n.language === "ar" ? "top-left" : "top-right",
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
    }
  };

  const fetchSingleFavoritePlace = async (featureclassId, dgis) => {
    try {
      setSelectedFavoriteLoading(true);
      let result = await GetSmartSearchResult(featureclassId, dgis, token);

      if (result.IsExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result = await GetSmartSearchResult(featureclassId, dgis, newToken);
      }
      setFavoritePalceInfo({
        BldgNameEn: result.buildings[0].BldgNameEn,
        BldgNameAr: result.buildings[0].BldgNameAr,
        EntNameE: result.makani[0].EntNameE,
        EntNameA: result.makani[0].EntNameA,
        CommEn: result.makani[0].CommEn,
        CommAr: result.makani[0].CommAr,
        EmirateA: result.makani[0].EmirateA,
        EmirateE: result.makani[0].EmirateE,
        AddressE: result.makani[0].AddressE,
        AddressA: result.makani[0].AddressA,
        street: result.makani[0].AddressE.split("-")[0],
        lat: Number(result.makani[0].SHAPE.split(",")[1]),
        lng: Number(result.makani[0].SHAPE.split(",")[0]),
        distance: calculateDistanceBetweenCoordinates(
          {
            lat: Number(currentLocationDirection.lat),
            lng: Number(currentLocationDirection.lng),
          },
          {
            lat: Number(result.makani[0].SHAPE.split(",")[1]),
            lng: Number(result.makani[0].SHAPE.split(",")[0]),
          },
          measurementUnit === "kilometers" ? "km" : "mile"
        ),
      });
      setSelectedFavoriteLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FavoritesContext.Provider
      value={{
        myMakani,
        locations,
        loading,
        routeLocations,
        fetchSyncFavorites,
        setFavoritesToggle,
        favoritesToggle,
        editFavoritesToggle,
        setEditFavoritesToggle,
        favoritesMarker,
        setFavoritesMarker,
        setDisplayMarker,
        displayMarker,
        setSelectedFavoriteMarker,
        selectedFavoriteMarker,
        setEditLocationToggle,
        editLocationToggle,
        MakaniMarker,
        setSelectedMakaniMarker,
        selectedMakaniMarker,
        disaplayMakaniMarker,
        setDisaplayMakaniMarker,
        myMakani,
        setLocations,
        setShareLocationToggle,
        ShareLocationToggle,
        setFavoritePalceInfo,
        favoritePlaceInfo,
        setSelectedFavoriteLoading,
        selectedFavoriteLoading,
        fetchSingleFavoritePlace,
        setSharedURL,
        sharedURL,
        setRouteLocations,
        refetch,
        setRefetch,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
export default FavoritesProvider;

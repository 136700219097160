import { useState, useEffect } from 'react';
import { GetAllCommunities } from '../helper/api/requests.ts';
import { useAuth } from '../context/auth.js';

const useGetCommunitiesFetching = () => {
  const [community, setCommunity] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token,getToken,setToken,initialToken } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await GetAllCommunities(token);
        if(result.isExp){
          let newToken= await getToken();
          localStorage.setItem("token", JSON.stringify(newToken));
          setToken(newToken);
          result = await GetAllCommunities(newToken);
        }    
        setLoading(false);
        setCommunity(result);
      } catch (error) {
        console.error(error);
      }
    };

    if(token)fetchData();
  }, [initialToken]);
  return {
    community,
    loading,
  };
};

export default useGetCommunitiesFetching;

import React, { createContext, useState, useContext, useEffect } from 'react';
import { GetNearestMakani,getToken } from '../helper/api/requests.ts'
import {LOGIN_REQUEST_URL} from '../helper/api/constants.ts'

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const loadSetting = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return (storedValue !== null &&  storedValue !== "undefined" && storedValue !== undefined) ? JSON.parse(storedValue) : defaultValue;
  };
  const [initialToken,setInitialToken]= useState(loadSetting("token",""));
  const [token, setToken] = useState(loadSetting("token",""));


  const [sessionId, setSessionId] = useState(loadSetting('sessionId', ''));
  const [userId, setUserId] = useState(loadSetting('UserId', ''));
  const [user,setUser]=useState(loadSetting('user',{}))
  const [url,setUrl]=useState()


const authUrl =LOGIN_REQUEST_URL;
  useEffect(() => {
    const getData = async () => {
    if(!token && !initialToken ){
      let newToken=  await getToken();
      if(newToken){
        setToken(newToken);
        setInitialToken(newToken)
        localStorage.setItem("token", JSON.stringify(newToken));
      }else{
        let newToken=  await getToken();
        setToken(newToken);
        setInitialToken(newToken)
        localStorage.setItem("token", JSON.stringify(newToken));

      }
    }
  }
  getData()
  }, []);

  
  return (
    <AuthContext.Provider value={{ token,sessionId,sessionId,setSessionId ,userId,setUserId,getToken,setToken,setUrl,url,setUser,user,authUrl,initialToken}}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useEffect, useState ,useRef} from 'react';
import './style.css'; // Import the CSS file
import { useFavorites } from '../../../context/FavouritesContext';
import { modelClose } from '../../../assets/index';
import { useTranslation } from "react-i18next";

const EditFavoritesModel = () => {
  const [value, setValue] = useState('');
  const inputRef = useRef(null)
  const [t, il8n] = useTranslation();
  const { editFavoritesToggle, fetchSyncFavorites, setEditFavoritesToggle } =
    useFavorites();
  useEffect(() => {
    setValue(editFavoritesToggle.item.FavName);
  }, []);
  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handelEdit=()=>{

      let currentTimeMillis = new Date().getTime();

      fetchSyncFavorites(
        editFavoritesToggle.item,
        {
          action: 'edit',
          value: value,
          lastUpdate:currentTimeMillis
        }
      );
    
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handelEdit();
    }
  };

  return (
    <div className='updateModel'>
      <div className='updateModelHeader'>
        <p>{t('cardName')}</p>
        <div
          className='closeWarper'
          onClick={() =>
            setEditFavoritesToggle({
              item: {},
              isEditFavoritesToggle: false,
            })
          }
        >
          <img src={modelClose} alt='close' />
        </div>
      </div>
      <div className='updateModelContent'>
        <div className='inputWarper'>
          <input
                    ref={inputRef}
            className='editInput'
            placeholder={t('cardName')}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className='saveBtn' style={{
          backgroundColor: "#009D91"
        }}
          
            onClick={()=>handelEdit()}
          >
          <p>
            {t('save')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditFavoritesModel;

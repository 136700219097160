const SettingToggle = ({ values, value, setState }) => {
  const widthCalc = Object.keys(values)?.length * 73;
  return (
    <div
      className="setting-toggle-container"
      // style={{
      //   width: `${widthCalc}px`,
      // }}
    >
      {Object.keys(values).map((val) => {
        return (
          <div
            className={`setting-toggle-button ${
              val?.toLocaleLowerCase() === value?.toLocaleLowerCase() &&
              "active"
            }`}
            onClick={() => {
              setState(val);
            }}
          >
            {values[val]}
          </div>
        );
      })}
    </div>
  );
};

export default SettingToggle;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import {
  useSmartSearchResultFetching,
  UseRemoveMapShape,
} from "../../customHooks/index";
import { usePOICategories } from "../../context/POICategoriesContext";
import {
  PageHeaderTitle,
  CollapsibleMenu,
  MakaniDetails as MakaniDetailsComponent,
  MakaniCard,
} from "../../component";
import { toast } from "react-toastify";

import { IndoorDirection } from "../../assets/svg/svgIcons.jsx";
import "../../scss/common.scss";
import Slider from "react-slick";
import "../../scss/slick-makani-card.scss";
import { useAppearance } from "../../context/AppearanceContext";
import {
  useAuth,
  useIndoorNavigation,
  useMapControl,
  useTour,
} from "../../context/index.js";
import { useSearchParams, useNavigate } from "react-router-dom";
import { NoDataImg, navigateIcon2 } from "../../assets/index.js";
import withTour from "../../withTour";
import { GetFloors, GetInitialParams } from "../../helper/api/requests.ts";
import React from "react";

//  const cardRef = useRef(null);

// const MakaniCardWithTour = withTour(MakaniCard, "MakaniDetails");

const MakaniDetails = ({ setCurrentSlide, currentSlide, sliderRef }) => {
  const { theme } = useAppearance();
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const [t, il8n] = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { walkThrough } = useAppearance();

  const m = searchParams.get("m");
  const [indooravailable, setIndooravailable] = useState(false);
  const [buildingid, setBuildingid] = useState("");
  const { setFloors } = useIndoorNavigation();
  UseRemoveMapShape();
  const { startTour } = useTour();
  const { token, getToken, setToken } = useAuth();
  const { setStartDirection } = useMapControl();
  const location = useLocation();

  const {
    makani,
    buildings,
    poi,
    loading,
    parcelInfo,
    coummity,
    indoorenabled,
    googleResult,
    geoLocationError,
    allowAddMakani,
    setIndoorenabled,
  } = useSmartSearchResultFetching(
    location.state?.featureclassId,
    location.state?.dgis,
    location.state?.isParcel,
    m,
    { lat: location?.state?.lat, lng: location?.state?.lng },
    location?.state?.searchValue,
    location?.state?.suggestion
  );

  const isArabic = true;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerPadding: isArabic ? "30px" : "50px",
    slidesToShow: 1,
    speed: 500,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    responsive: [
      {
        breakpoint: 1679,
        settings: {
          centerPadding: isArabic ? "20px" : "20px",
        },
      },
      {
        breakpoint: 1379,
        settings: {
          centerPadding: isArabic ? "20px" : "20px",
        },
      },
      {
        breakpoint: 1281,
        settings: {
          centerPadding: isArabic ? "35px" : "20px", // Adjust centerPadding for Arabic
        },
      },
      {
        breakpoint: 1199,
        settings: {
          centerPadding: isArabic ? "13px" : "10px", // Adjust centerPadding for Arabic
        },
      },
      {
        breakpoint: 1023,
        settings: {
          centerPadding: isArabic ? "10px" : "10px", // Adjust centerPadding for Arabic
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: isArabic ? "10px" : "10px", // Adjust centerPadding for Arabic
        },
      },
    ],
  };
  //   // Rahul change to update number of dots shown
  // const [dotsStartIndex, setDotsStartIndex] = useState(0);
  // const maxDotsToShow = 5; // Change this as per your design

  // // Function to update visible dots
  // const updateDots = (newIndex) => {
  //   const totalSlides = makani?.length;
  //   const midPoint = Math.floor(maxDotsToShow / 2);

  //   let start = newIndex - midPoint;
  //   if (start < 0) start = 0;
  //   else if (start + maxDotsToShow > totalSlides) start = totalSlides - maxDotsToShow;

  //   setDotsStartIndex(start);
  // };

  //
  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const nextSlide = () => {
    if (il8n.language === "ar") {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (il8n.language === "ar") {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };

  const { loading: poiLoading, POICategories } = usePOICategories();

  let newPOICategories = POICategories.map((item) => {
    return {
      img: item["Symbol"],
      title: item?.CategoryE,
      key: item.CategoryId,
    };
  });

  useEffect(() => {
    const checkIndoorEnabled = async () => {
      try {
        const initialparams_result = await GetInitialParams(t, token);
        if (initialparams_result.isExp) {
          let newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          initialparams_result = await GetInitialParams(t, newToken);
        }
        if (initialparams_result.error) {
          toast.error({
            render: initialparams_result.error,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            position: il8n.language === "ar" ? "top-left" : "top-right",
            style: {
              textAlign: il8n.language === "ar" ? "right" : "left",
            },
          });
          setIndoorenabled(false);
        } else if (initialparams_result.Data.InitialParms) {
          if (
            initialparams_result.Data.InitialParms.indoor_navigation_enabled
          ) {
            setIndoorenabled(true);
          } else {
            setIndoorenabled(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAndCheckFloors = async (makani) => {
      try {
        setIndooravailable(false);
        const floors_result = await GetFloors(t, token, makani);

        if (floors_result.IsExp) {
          let newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          floors_result = await GetFloors(t, newToken, makani);
        }
        if (floors_result.error) {
          if (location.pathname == "indoorNavigation") {
            toast.error(floors_result.error, {
              type: "error",
              isLoading: false,
              autoClose: 3000,
              position: il8n.language === "ar" ? "top-left" : "top-right",
            });
          }
          setIndooravailable(false);
        } else if (floors_result) {
          setBuildingid(floors_result?.data.Data[0]?.BuildingId);
          setIndooravailable(true);
          setFloors(floors_result?.data?.Data);
        } else {
          setIndooravailable(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (makani?.length > 0) {
      const makaniData = sliderRef?.current?.innerSlider?.state?.currentSlide
        ? makani[sliderRef?.current?.innerSlider?.state?.currentSlide].Makani
        : makani[0].Makani;

      fetchAndCheckFloors(makaniData);
      checkIndoorEnabled();
    } else {
      setIndooravailable(false);
    }
  }, [sliderRef?.current?.innerSlider?.state?.currentSlide, makani]);

  useEffect(() => {
    if (!loading && walkThrough) {
      startTour();
    }
  }, [loading]);

  function reverseAndConcat(input) {
    return input.replace(/ /g, "\u00A0");
  }

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent  inner-content swipe-line slider-cover-main"
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <div className="PageHeaderTitleWarper">
        <PageHeaderTitle
          title={
            <>
              {location?.state?.searchValue && (
                <p className="searchTitle ">
                  {location?.state?.searchValue ||
                    (il8n.language === "ar"
                      ? reverseAndConcat(makani[0].Makani)
                      : makani[0].Makani)}
                </p>
              )}
              {makani[0]?.Makani && !location?.state?.searchValue && (
                <p className="searchTitle ">
                  {il8n.language === "ar"
                    ? reverseAndConcat(makani[0].Makani)
                    : makani[0].Makani}
                </p>
              )}
            </>
          }
          parent={location?.state?.from || "/"}
        />
      </div>

      {geoLocationError && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          {t("no_data_found")}
        </div>
      )}
      {loading ? (
        <>
          <div className="searchTitleWarper">
            <p className="searchTitle">
              <Skeleton width={250} />
            </p>
            <p class="searchAddress">
              <Skeleton width={200} />
            </p>
          </div>
          <div className="DeatilsMakaniCards slick-cards-cover">
            <div className="slider-container">
              <div
                className={`deatilsMakaniCardActive singleMakaniCard deatilsMakaniCard`}
              >
                <div className="MakaniCardHeader">
                  <div>
                    <Skeleton
                      width={100}
                      height={50}
                      className="logo-in-card"
                    />
                  </div>
                  <div className="copyWrapper">
                    <p className="makaniCardNumber">
                      <Skeleton width={100} className="copy__btn" />
                    </p>
                  </div>
                  <div className="d-flex-cstm">
                    <Skeleton width={50} height={50} className="love" />
                  </div>
                </div>
                <div className="MakaniCardContent">
                  <div
                    className="MakaniCardContentText"
                    style={{
                      padding: il8n.language === "ar" && "5px 15px",
                    }}
                  >
                    <Skeleton width={100} />
                    <Skeleton
                      width={40}
                      className="MakaniCardContentDesinence"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="searchTitleWarper">
            {location?.state?.searchValue && (
              <p className="searchTitle ">
                {il8n.language === "ar"
                  ? makani?.[0]?.BldgNameAr
                  : makani?.[0]?.BldgNameEn}
              </p>
            )}
            {!location?.state?.searchValue &&
              (il8n.language === "ar"
                ? makani?.[0]?.BldgNameAr
                : makani?.[0]?.BldgNameEn) && (
                <p className="searchTitle ">
                  {il8n.language === "ar"
                    ? makani?.[0]?.BldgNameAr
                    : makani?.[0]?.BldgNameEn}
                </p>
              )}
            {/* TODO : CHECK THIS */}
            {/* {location?.state?.emirate && (
              <p class="searchAddress">{location?.state?.emirate}</p>
            )} */}
            {/* Google Result */}
            {googleResult?.length > 0 && (
              <div>
                {/* <p className="searchTitle">{googleResult[0].building}</p> */}
                <div
                  className="IndoorNavigation indoor-navigation_desc "
                  onClick={() => {
                    setStartDirection(true);
                    navigate("/direction", {
                      state: {
                        BuildingNameE:
                          googleResult[0].building.trim() ||
                          location?.state?.searchValue,
                        BuildingNameA:
                          googleResult[0].building.trim() ||
                          location?.state?.searchValue,
                        position: {
                          lat: googleResult[0]?.location?.lat,
                          lng: googleResult[0]?.location?.lng,
                        },
                      },
                    });
                  }}
                  style={{ marginTop: "20px" }}
                >
                  <img src={navigateIcon2} alt="navigate" />
                  <p className="navigateTitle">{t("navigate")}</p>
                </div>
              </div>
            )}

            {/* {coummity?.length > 0 &&
              (il8n.language === "ar"
                ? coummity?.[0]?.CommunityA
                : coummity?.[0]?.CommunityE) && (
                <p className="searchTitle ">
                  {il8n.language === "ar"
                    ? coummity?.[0]?.CommunityA
                    : coummity?.[0]?.CommunityE}
                </p>
              )} */}
          </div>

          <div className="DeatilsMakaniCards slick-cards-cover">
            <div className="slider-container">
              {makani?.length > 1 && (
                <Slider {...settings} ref={sliderRef}>
                  {makani?.map((item, index) => {
                    {
                      /* const MakaniCardComponent =
                      index === 0 && il8n.language === "en"
                        ? MakaniCardWithTour
                        : index === makani?.length - 1 && il8n.language === "ar"
                        ? MakaniCardWithTour
                        : MakaniCard; */
                    }
                    return (
                      <div>
                        <MakaniCard
                          index={index}
                          theme={theme}
                          item={item}
                          allowAddMakani={allowAddMakani}
                        />
                      </div>
                    );
                  })}
                </Slider>
              )}

              {makani?.length === 1 &&
                makani?.map((item, index) => {
                  {
                    /* const MakaniCardComponent =
                    index === 0 ? MakaniCardWithTour : MakaniCard; */
                  }

                  return (
                    <div className="no-slider">
                      <MakaniCard
                        index={index}
                        theme={theme}
                        item={item}
                        allowAddMakani={allowAddMakani}
                      />
                    </div>
                  );
                })}

              {makani?.length > 1 && (
                <div className="slick-controls">
                  <div className="custom-dots">
                    {makani?.map((item, index) => {
                      return (
                        <li>
                          <button
                            key={index}
                            className={index === currentSlide ? "active" : ""}
                            onClick={() => goToSlide(index)}
                          ></button>
                        </li>
                      );
                    })}
                  </div>

                  <div className="slider-nav">
                    <span className="slick_prev" onClick={prevSlide}></span>
                    <span className="slick_next" onClick={nextSlide}></span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {indooravailable && indoorenabled && (
            <div
              className="IndoorNavigation indoor-navigation_desc "
              onClick={() => {
                navigate("/indoorNavigation", {
                  state: {
                    makani: makani,
                    poi: poi,
                    buildingid,
                    index: sliderRef?.current?.innerSlider?.state?.currentSlide,
                    isShowMakaniInput: makani.length === 1,
                  },
                });
              }}
            >
              <IndoorDirection />
              {t("indoorNavigation")}
            </div>
          )}

          {makani?.length > 0 && (
            <MakaniDetailsComponent
              makani={makani}
              poi={poi}
              parcelInfo={parcelInfo}
              featureclassId={location.state?.featureclassId}
              searchValue={location?.state?.searchValue}
            />
          )}
          {makani?.length === 0 && location.state?.featureclassId === "5" && (
            <MakaniDetailsComponent
              makani={makani}
              poi={poi}
              parcelInfo={parcelInfo}
              featureclassId={location.state?.featureclassId}
              searchValue={location?.state?.searchValue}
            />
          )}

          {makani?.length === 0 &&
            location.state?.featureclassId === "6" &&
            coummity?.length === 0 && (
              <MakaniDetailsComponent
                makani={makani}
                poi={poi}
                parcelInfo={parcelInfo}
                featureclassId={location.state?.featureclassId}
                searchValue={location?.state?.searchValue}
              />
            )}

          {/* {makani?.length > 0  && <div className='poiMenu'>
            {poiLoading ? (
              <p>loading ...</p>
            ) : (
              <CollapsibleMenu
                items={newPOICategories}
                title={t('PointOfIntrest')}
                hasLogo={true}
                setActiveItems={setActiveItems}
                activeItems={activeItems}
                children={[]}
              />
            )}
          </div>} */}

          {makani?.length === 0 &&
            location.state?.featureclassId !== "5" &&
            !location.state?.featureclassId === "6" &&
            !coummity?.length > 0 && (
              <div class="noResultWraper">
                <div className="no-data-img">
                  <img src={NoDataImg} alt="No data" />
                </div>
                <p style={{ margin: "0 35px" }}>{t("NoResultFound")}</p>
              </div>
            )}
          {makani?.length === 0 &&
            location.state?.lat &&
            location.state?.lng && (
              <div class="noResultWraper">
                <div className="no-data-img">
                  <img src={NoDataImg} alt="No data" />
                </div>
                <p style={{ margin: "0 35px" }}>{t("NoResultFound")}</p>
              </div>
            )}
        </>
      )}
      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};

export default withTour(MakaniDetails, "searchResultPage");
//15:02

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import CustomBackend from "./customBackend";
// import Backend from 'i18next-http-backend';

const lang = localStorage.getItem("language");

const langSwiter = lang === "en" ? "ar" : lang === "ar" ? "en" : "en";

i18n
  .use(CustomBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: langSwiter,
    debug: true,
    requestOptions: {
      cache: "no-store",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
